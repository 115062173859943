import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import { setNotification } from "../notification";
import formatDataModal from "../../../utils/formatDataModal";
import dayjs from "dayjs";

const initialState = {
  teamTypes: [],
  version: [],
  teams: [],
  teamsBodega: [],
  articles: [],
  brands: [],
  brandsSelect: [],
  providersSelect: [],
  providers: [],
  itemType: [],
  modelsSelect: [],
  teamTypesSelect: [],
  versionSelect: [],
  statusSelect: [],
  repairParts: [],
  repairPartsMold: [],
  repairPartsBodega: [],
  repairPartsSelect: [],
  teamsSelect: [],
  models: [],
  typeOfDocument: [],
  itemTypeSelect: [],
  piezas: [],
  piezasId: [],
  elements: [],
  incorporation: [],
  articlesMold: [],
  bodegas: [],
  success: false,
};

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setTeamTypes: (state, action) => {
      state.teamTypes = action.payload;
      state.success = true;
    },
    addTeamType: (state, action) => {
      state.teamTypes = [...state.teamTypes, action.payload];
      state.success = true;
    },
    setUpdateTeamType: (state, action) => {
      const updated = state.teamTypes.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.teamTypes = [...updated, action.payload];
      state.success = true;
    },
    setRemoveTeamType: (state, action) => {
      const updated = state.teamTypes.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.teamTypes = updated;
      state.success = true;
    },
    setVersions: (state, action) => {
      state.version = action.payload;
      state.success = true;
    },
    addVersion: (state, action) => {
      state.version = [...state.version, action.payload];
      state.success = true;
    },
    setUpdateVersion: (state, action) => {
      const updated = state.version.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.version = [...updated, action.payload];
      state.success = true;
    },
    setRemoveVersion: (state, action) => {
      const updated = state.version.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.version = updated;
      state.success = true;
    },
    setTeams: (state, action) => {
      state.teams = action.payload;
      state.success = true;
    },
    setTeamsBodega: (state, action) => {
      state.teamsBodega = action.payload;
      state.success = true;
    },
    addTeam: (state, action) => {
      state.teams = [...state.teams, action.payload];
      state.success = true;
    },
    setRemoveTeam: (state, action) => {
      const updated = state.teams.filter((team) => {
        return team.id !== action.payload.id;
      });
      state.teams = updated;
      state.success = true;
    },
    setBrandsSelect: (state, action) => {
      state.brandsSelect = action.payload;
    },
    setBrands: (state, action) => {
      state.brands = action.payload;
      state.success = true;
    },
    setProviderSelect: (state, action) => {
      state.providersSelect = action.payload;
    },
    setTeamTypesSelect: (state, action) => {
      state.teamTypesSelect = action.payload;
    },
    setTeamsSelect: (state, action) => {
      state.teamsSelect = action.payload;
    },
    setModelSelect: (state, action) => {
      state.modelsSelect = action.payload;
    },
    setVersionsSelect: (state, action) => {
      state.versionSelect = action.payload;
    },
    setUpdateTeam: (state, action) => {
      const updated = state.teams.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.teams = [...updated, action.payload];
      state.success = true;
    },
    setDeleteTeam: (state, action) => {
      const updated = state.teams.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.teams = updated;
      state.success = true;
    },
    setStatusSelect: (state, action) => {
      state.statusSelect = action.payload;
    },
    addRepairPart: (state, action) => {
      state.repairParts = [...state.repairParts, action.payload];
      state.success = true;
    },
    setRepairParts: (state, action) => {
      state.repairParts = action.payload;
      state.success = true;
    },
    setRepairPartsBodega: (state, action) => {
      state.repairPartsBodega = action.payload;
      state.success = true;
    },
    setRepairPartsSelect: (state, action) => {
      state.repairPartsSelect = action.payload;
      state.success = true;
    },
    setItemTypeSelect: (state, action) => {
      state.itemTypeSelect = action.payload;
      state.success = true;
    },
    setUpdateRepairPart: (state, action) => {
      const updated = state.repairParts.filter((part) => {
        return part.id !== action.payload.id;
      });
      state.repairParts = [...updated, action.payload];
      state.success = true;
    },
    setRemoveRepairPart: (state, action) => {
      const updated = state.repairParts.filter((part) => {
        return part.id !== action.payload.id;
      });
      state.repairParts = updated;
      state.success = true;
    },
    addBrand: (state, action) => {
      state.brands = [...state.brands, action.payload];
      state.success = true;
    },
    setUpdateBrand: (state, action) => {
      const updated = state.brands.filter((brand) => {
        return brand.id !== action.payload.id;
      });
      state.brands = [...updated, action.payload];
      state.success = true;
    },
    setRemoveBrand: (state, action) => {
      const updated = state.brands.filter((brand) => {
        return brand.id !== action.payload.id;
      });
      state.brands = updated;
      state.success = true;
    },
    setModel: (state, action) => {
      state.models = action.payload;
      state.success = true;
    },
    setModelSelect: (state, action) => {
      state.modelsSelect = action.payload;
    },
    addModel: (state, action) => {
      state.models = [...state.models, action.payload];
      state.success = true;
    },
    setUpdateModel: (state, action) => {
      const updated = state.models.filter((models) => {
        return models.id !== action.payload.id;
      });
      state.models = [...updated, action.payload];
      state.success = true;
    },
    setRemoveModel: (state, action) => {
      const updated = state.models.filter((models) => {
        return models.id !== action.payload.id;
      });
      state.models = updated;
      state.success = true;
    },
    setTypeOfDocument: (state, action) => {
      state.typeOfDocument = action.payload;
      state.success = true;
    },
    addTypeOfDocument: (state, action) => {
      state.typeOfDocument = [...state.typeOfDocument, action.payload];
      state.success = true;
    },
    setUpdateTypeOfDocument: (state, action) => {
      const updated = state.typeOfDocument.filter((typeOfDocument) => {
        return typeOfDocument.id !== action.payload.id;
      });
      state.typeOfDocument = [...updated, action.payload];
      state.success = true;
    },
    setRemoveTypeOfDocument: (state, action) => {
      const updated = state.typeOfDocument.filter((typeOfDocument) => {
        return typeOfDocument.id !== action.payload.id;
      });
      state.typeOfDocument = updated;
      state.success = true;
    },
    setPieza: (state, action) => {
      state.piezas = action.payload;
      state.success = true;
    },
    setPiezaById: (state, action) => {
      state.piezasId = action.payload;
      state.success = true;
    },
    addPieza: (state, action) => {
      state.piezas = [...state.piezas, action.payload];
      state.success = true;
    },
    setUpdatePieza: (state, action) => {
      const updated = state.piezas.filter((piezas) => {
        return piezas.id !== action.payload.id;
      });
      state.piezas = [...updated, action.payload];
      state.success = true;
    },
    setRemovePieza: (state, action) => {
      const updated = state.piezas.filter((piezas) => {
        return piezas.id !== action.payload.id;
      });
      state.piezas = updated;
      state.success = true;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setArticles: (state, action) => {
      state.articles = action.payload;
      state.success = true;
    },
    addArticle: (state, action) => {
      state.articles = [...state.articles, action.payload];
      state.success = true;
    },
    setUpdateArticle: (state, action) => {
      const updated = state.articles.filter((article) => {
        return article.id !== action.payload.id;
      });
      state.articles = [...updated, action.payload];
      state.success = true;
    },
    setRemoveArticle: (state, action) => {
      const updated = state.articles.filter((article) => {
        return article.id !== action.payload.id;
      });
      state.articles = updated;
      state.success = true;
    },
    setItemType: (state, action) => {
      state.itemType = action.payload;
      state.success = true;
    },
    addItemType: (state, action) => {
      state.itemType = [...state.itemType, action.payload];
      state.success = true;
    },
    setUpdateItemType: (state, action) => {
      const updated = state.itemType.filter((article) => {
        return article.id !== action.payload.id;
      });
      state.itemType = [...updated, action.payload];
      state.success = true;
    },
    setRemoveItemType: (state, action) => {
      const updated = state.itemType.filter((article) => {
        return article.id !== action.payload.id;
      });
      state.itemType = updated;
      state.success = true;
    },
    setProvider: (state, action) => {
      state.providers = action.payload;
      state.success = true;
    },
    addProvider: (state, action) => {
      state.providers = [...state.providers, action.payload];
      state.success = true;
    },
    setUpdateProvider: (state, action) => {
      const updated = state.providers.filter((provider) => {
        return provider.id !== action.payload.id;
      });
      state.providers = [...updated, action.payload];
      state.success = true;
    },
    setRemoveProvider: (state, action) => {
      const updated = state.providers.filter((provider) => {
        return provider.id !== action.payload.id;
      });
      state.providers = updated;
      state.success = true;
    },
    setElements: (state, action) => {
      state.elements = action.payload;
      state.success = true;
    },
    setIncorporation: (state, action) => {
      state.incorporation = action.payload;
      state.success = true;
    },
    setRepairPartMold: (state, action) => {
      state.repairPartsMold = action.payload;
      state.success = true;
    },
    addRepairPartMold: (state, action) => {
      state.repairPartsMold = [...state.repairPartsMold, action.payload];
      state.success = true;
    },
    setUpdateRepairPartMold: (state, action) => {
      const updated = state.repairPartsMold.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.repairPartsMold = [...updated, action.payload];
      state.success = true;
    },
    setRemoveRepairPartMold: (state, action) => {
      const updated = state.repairPartsMold.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.repairPartsMold = updated;
      state.success = true;
    },
    setArticlesMold: (state, action) => {
      state.articlesMold = action.payload;
      state.success = true;
    },
    addArticlesMold: (state, action) => {
      state.articlesMold = [...state.articlesMold, action.payload];
      state.success = true;
    },
    setUpdateArticlesMold: (state, action) => {
      const updated = state.articlesMold.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.articlesMold = [...updated, action.payload];
      state.success = true;
    },
    setRemoveArticlesMold: (state, action) => {
      const updated = state.articlesMold.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.articlesMold = updated;
      state.success = true;
    },
    set: (state, { payload: { data, name } }) => {
      state[name] = data;
      state.success = true;
    },
    add: (state, { payload: { data, name } }) => {
      state[name].push(data);
      state.success = true;
    },
    update: (state, { payload: { data, name } }) => {
      state[name] = [...state[name].filter((worker) => worker.id !== data.id), data];
      state.success = true;
    },
    remove: (state, { payload: { name, data } }) => {
      const updated = state[name].filter((el) => el.id !== data);
      state[name] = updated;
    },
  },
});

export const getAllTeamTypes = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/team-types");
    dispatch(setTeamTypes(res.data));
  } catch (error) {
    dispatch(setSuccess(true));
  }
};

export const getAllElements = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/elements");
    const newData = res.data.reduce((accumulator, data) => {
      const existingItem = accumulator.find((item) => item.elemento === data.inv_pieza_equipos.inv_tipo_item_equipo.nombre);

      if (existingItem) {
        existingItem.cantidad += data.cantidad_actual;
      } else {
        accumulator.push({
          ...data,
          estado: data.inv_equipo.inv_estado.descripcion,
          elemento: data.inv_pieza_equipos.inv_tipo_item_equipo.nombre,
          cantidad: data.cantidad_actual,
        });
      }

      return accumulator;
    }, []);
    dispatch(setElements(newData));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const createTeamType = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/inventory/team-types", values);
    dispatch(addTeamType(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Tipo de equipo creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear Tipo de equipo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const updateTeamType = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/inventory/team-types", values);
    dispatch(setUpdateTeamType(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Tipo de equipo actualizado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar Tipo de equipo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const deleteTeamType = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.delete(`/inventory/team-types?id=${id}`);
    dispatch(setRemoveTeamType(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Tipo de equipo eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar Tipo de equipo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getAllVersions = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/version");
    const newValues = res.data.map((res) => ({
      ...res,
      modelo_nombre: res.inv_modelo.nombre,
      equipo_nombre: res.inv_modelo.inv_tipo_equipo.descripcion,
    }));
    dispatch(setVersions(newValues));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const createVersion = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/inventory/version", values);
    const newData = {
      ...res.data,
      modelo_nombre: res.data.inv_modelo.nombre,
      equipo_nombre: res.data.inv_modelo.inv_tipo_equipo.descripcion,
    };
    dispatch(addVersion(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Version creada con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear version",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const updateVersion = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/inventory/version", values);
    const newData = {
      ...res.data,
      modelo_nombre: res.data.inv_modelo.nombre,
      equipo_nombre: res.data.inv_modelo.inv_tipo_equipo.descripcion,
    };
    dispatch(setUpdateVersion(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Version actualizada con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar version",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const deleteVersion = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.delete(`/inventory/version?id=${id}`);
    dispatch(setRemoveVersion(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Version eliminada con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar version",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getAllTeams = (estado) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/teams");
    const newData = res.data.map((data) => {
      return {
        ...data,
        id_incorporacion: data.inv_incorporacion_inventario?.id,
        fecha_incorporacion: dayjs(data.inv_incorporacion_inventario?.fecha_ingreso).add(1, "day").format("DD/MM/YYYY") || "S/D",
        tipoText: data.inv_tipo_equipo?.descripcion,
        versionText: data.inv_version?.descripcion,
        marcaText: data.inv_marca?.descripcion,
        estadoText: data.bodegaId ? data.bodega.name : data?.inv_estado?.descripcion,
        elementosEstado: data.inv_accesorios.every((acc) => acc.cantidad_actual === acc.cantidad_requerida),
      };
    });
    // const teamsFilterd = newData.filter((el) => el.estadoText === estado);
    dispatch(setTeams(newData));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const getAllTeamsBodega = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/teamsBodega");
    const newData = res.data.map((el) => ({
      ...el,
      tipoText: el.inv_tipo_equipo?.descripcion,
      versionText: el.inv_version?.descripcion,
      marcaText: el.inv_marca?.descripcion,
      estadoText: el.bodegaId ? el.bodega.name : el?.inv_estado?.descripcion,
    }));

    dispatch(setTeamsBodega(newData));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const createTeams = (values, accesories) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    values.accesories = accesories;
    const res = await axiosInstance.post("/inventory/teams", values);
    const newData = {
      ...res.data,
      tipoText: res.data.inv_tipo_equipo?.descripcion,
      versionText: res.data.inv_version?.descripcion,
      marcaText: res.data.inv_marca?.descripcion,
      estadoText: res.data.bodegaId ? res.data.bodega.name : res.data?.inv_estado?.descripcion,
    };
    dispatch(addTeam(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Equipo creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar equipo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const updateTeams = (values, id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/inventory/teams", { values, id });
    const newData = {
      ...res.data,
      tipoText: res.data.inv_tipo_equipo?.descripcion,
      versionText: res.data.inv_version?.descripcion,
      marcaText: res.data.inv_marca?.descripcion,
      estadoText: res.data.bodegaId ? res.data.bodega.name : res.data?.inv_estado?.descripcion,
      elementosEstado: res.data.inv_accesorios.every((acc) => acc.cantidad_actual === acc.cantidad_requerida),
    };
    dispatch(setUpdateTeam(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Equipo actualizado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar equipo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const deleteTeams = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.delete(`/inventory/teams?id=${id}`);
    // const newData = {
    //   ...res.data,
    //   tipoText: res.data.inv_tipo_equipo?.descripcion,
    //   versionText: res.data.inv_version?.descripcion,
    //   marcaText: res.data.inv_marca?.descripcion,
    //   estadoText: res.data?.inv_estado?.descripcion,
    //   elementosEstado: res.data.inv_accesorios.every((acc) => acc.cantidad_actual === acc.cantidad_requerida),
    // };
    dispatch(setDeleteTeam(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Equipo eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error.response.data.message);
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data?.message || "Error al eliminar equipo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getAllBrandsSelect = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/inventory/brands/select");
    dispatch(setBrandsSelect(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getAllTeamsSelect = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/inventory/teams/select");
    dispatch(setTeamsSelect(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getAllProvidersSelect = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/inventory/providers/select");
    dispatch(setProviderSelect(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getAllTeamTypesSelect = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/inventory/team-types/select");
    dispatch(setTeamTypesSelect(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getAllVersionsSelect = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/inventory/version/select");
    dispatch(setVersionsSelect(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getAllStatusSelect = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/inventory/status");
    dispatch(setStatusSelect(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getAllRepairParts = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/repair-parts");
    const newData = res.data.map((data) => {
      return {
        ...data,
        equipoText: data?.inv_tipo_equipo?.descripcion,
        estadoText: data.bodegaId ? data.bodega.name : data?.inv_estado?.descripcion,
        marcaText: data.inv_marca?.descripcion,
      };
    });
    dispatch(setRepairParts(newData));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const getAllRepairPartsBodega = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/repair-parts-bodega");
    const newData = res.data.map((data) => {
      return {
        ...data,
        equipoText: data?.inv_tipo_equipo?.descripcion,
        estadoText: data.bodegaId ? data.bodega.name : data?.inv_estado?.descripcion,
        marcaText: data.inv_marca?.descripcion,
      };
    });
    dispatch(setRepairPartsBodega(newData));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const createNewRepairPart = (values) => async (dispatch) => {
  const formatedValues = formatDataModal(values);
  formatedValues.medidas = String(values.medidas);
  formatedValues.modelo = String(values.modelo);
  formatedValues.serie = String(values.serie);
  formatedValues.codigo_loginteg = String(values.codigo_loginteg);
  formatedValues.descripcion = String(values.descripcion);

  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/inventory/mold", formatedValues);
    const newData = {
      ...res.data,
      equipoText: res.data?.inv_tipo_equipo?.descripcion,
      marcaText: res.data.inv_marca?.descripcion,
    };
    dispatch(addRepairPartMold(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Repuesto creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear repuesto",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const updateRepairPart = (id, estado, cantidad, valueCost, bodegaId) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/inventory/repair-parts", { id, estado, cantidad, valueCost, bodegaId });
    const newData = {
      ...res.data,
      equipoText: res.data?.inv_tipo_equipo?.descripcion,
      estadoText: res.data.bodegaId ? res.data.bodega.name : res.data?.inv_estado?.descripcion,
      marcaText: res.data.inv_marca?.descripcion,
    };
    dispatch(setUpdateRepairPart(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Repuesto actualizado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar repuesto",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const deleteRepairPart = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.delete(`/inventory/repair-parts?id=${id}`);
    dispatch(setRemoveRepairPart(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Repuesto eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data?.message || "Error al eliminar repuesto",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getAllRepairPartsSelect = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/inventory/repair-parts/select");
    dispatch(setRepairPartsSelect(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getAllBrands = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/brands");
    const newDate = res.data.map((brand) => {
      return {
        ...brand,
        equipoText: brand.equipo ? "Si" : "No",
        repuestoText: brand.repuesto ? "Si" : "No",
        f_creacion: dayjs(brand.f_creacion).add(1, "day").format("DD-MM-YYYY"),
      };
    });
    dispatch(setBrands(newDate));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const createNewBrand = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/inventory/brands", values);
    const newData = {
      ...res.data,
      equipoText: res.data.equipo ? "Si" : "No",
      repuestoText: res.data.repuesto ? "Si" : "No",
      f_creacion: dayjs(res.data.f_creacion).add(1, "day").format("DD-MM-YYYY"),
    };
    dispatch(addBrand(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Marca creada con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear marca",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const updateNewBrand = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));

    const res = await axiosInstance.put("/inventory/brands", values);
    const newData = {
      ...res.data,
      equipoText: res.data.equipo ? "Si" : "No",
      repuestoText: res.data.repuesto ? "Si" : "No",
      f_creacion: dayjs(res.data.f_creacion).add(1, "day").format("DD-MM-YYYY"),
    };
    dispatch(setUpdateBrand(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Marca actualizada con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar marca",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const deleteBrand = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.delete(`/inventory/brands?id=${id}`);
    dispatch(setSuccess(false));
    dispatch(setRemoveBrand(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Marca eliminada con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data?.message || "Error al eliminar marca",
        open: true,
      })
    );
    dispatch(setSuccess(true));
    return error.response.data?.message;
  }
};

export const getAllModels = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/models");
    const newData = res.data.map((model) => ({
      ...model,
      tipo_de_equipo: model.inv_tipo_equipo.descripcion,
    }));
    dispatch(setModel(newData));
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al Obtener modelos",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getAllModelsSelect = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/inventory/models/select");
    dispatch(setModelSelect(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const createModel = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("inventory/models", values);
    const newData = { ...res.data, tipo_de_equipo: res.data.inv_tipo_equipo.descripcion };
    dispatch(addModel(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Modelo creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "success",
        message: "Error al crear modelo",
        open: true,
      })
    );
  }
};

export const updateModal = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/inventory/models", values);
    const newData = { ...res.data, tipo_de_equipo: res.data.inv_tipo_equipo.descripcion };
    dispatch(setUpdateModel(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Modelo editado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "success",
        message: "Error al editar modelo",
        open: true,
      })
    );
  }
};

export const deleteModel = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put(`/inventory/models/${id}`);
    dispatch(setRemoveModel(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Modelo eliminada con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar modelo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};
export const getAllTypesOfDocuments = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/types-of-documents");
    dispatch(setTypeOfDocument(res.data));
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al Obtener documentos",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const createTypesOfDocument = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/inventory/types-of-documents", values);
    dispatch(addTypeOfDocument(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Documento creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "success",
        message: "Error al crear documento",
        open: true,
      })
    );
  }
};

export const updateTypesOfDocument = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/inventory/types-of-documents", values);
    dispatch(setUpdateTypeOfDocument(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Documento editado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "success",
        message: "Error al editar documento",
        open: true,
      })
    );
  }
};

export const deleteTypesOfDocument = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));

    const res = await axiosInstance.put(`/inventory/types-of-documents/${id}`);
    dispatch(setRemoveTypeOfDocument(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Documento eliminada con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar documento",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getItemsTypeSelect = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/inventory/item-type/select");
    dispatch(setItemTypeSelect(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getAllPiezas = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/pieza");
    const newData = res.data.map((res) => ({
      ...res,
      nombre_modelo: res.inv_modelo_inv_pieza_equipos_inv_modeloToinv_modelo.nombre,
      nombre_equipo: res.inv_tipo_equipo_inv_pieza_equipos_inv_tipo_equipoToinv_tipo_equipo.descripcion,
      nombre_item: res.inv_tipo_item_equipo.nombre,
      nombre_version: res.inv_version.descripcion,
    }));

    dispatch(setPieza(newData));
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al Obtener Piezas",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getPiezaById = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get(`/inventory/piezaById?inv_tipo_equipo=${values.equipoId}&inv_modelo=${values.modeloId}&version=${values.versionId}`);
    dispatch(setPiezaById(res.data));
    return res.data;
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al Obtener Piezas",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const createPieza = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/inventory/pieza", values);
    const newData = {
      ...res.data,
      nombre_modelo: res.data.inv_modelo_inv_pieza_equipos_inv_modeloToinv_modelo.nombre,
      nombre_equipo: res.data.inv_tipo_equipo_inv_pieza_equipos_inv_tipo_equipoToinv_tipo_equipo.descripcion,
      nombre_item: res.data.inv_tipo_item_equipo.nombre,
      nombre_version: res.data.inv_version.descripcion,
    };
    dispatch(addPieza(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "pieza creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear pieza",
        open: true,
      })
    );
  }
};

export const updatePieza = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/inventory/pieza", values);
    const newData = {
      ...res.data,
      nombre_modelo: res.data.inv_modelo_inv_pieza_equipos_inv_modeloToinv_modelo.nombre,
      nombre_equipo: res.data.inv_tipo_equipo_inv_pieza_equipos_inv_tipo_equipoToinv_tipo_equipo.descripcion,
      nombre_item: res.data.inv_tipo_item_equipo.nombre,
      nombre_version: res.data.inv_version.descripcion,
    };
    dispatch(setUpdatePieza(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Pieza editado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar bien",
        open: true,
      })
    );
  }
};

export const deletePieza = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));

    const res = await axiosInstance.put(`/inventory/pieza/${id}`);
    dispatch(setRemovePieza(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Pieza eliminada con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar pieza",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getAllArticles = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/articles");

    const newData = res.data.map((article) => ({
      ...article,
      lotes: article.lotes ? article.lotes : "S/D",

      estadoText: article.bodegaId ? article.bodega.name : article?.inv_estado?.descripcion || "S/D",
      fecha_vencimientoFormat: article.fecha_vencimiento ? dayjs(article.fecha_vencimiento).format("DD/MM/YYYY") : "S/D",
    }));

    dispatch(setArticles(newData));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const createNewArticle = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/inventory/articles", values);

    dispatch(addArticle(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Insumo creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear Insumo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const updateArticle = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));

    const res = await axiosInstance.put("/inventory/articles", values);
    const newData = {
      ...res.data,
      lotes: res.data.lotes ? res.data.lotes : "S/D",
      estadoText: res.data.bodegaId ? res.data.bodega.name : res.data?.inv_estado?.descripcion || "S/D",
      fecha_vencimientoFormat: res.data.fecha_vencimiento ? dayjs(res.data.fecha_vencimiento).format("DD/MM/YYYY") : "S/D",
    };

    dispatch(setUpdateArticle(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Insumo actualizado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar Insumo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const deleteArticle = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.delete(`/inventory/articles?id=${id}`);
    dispatch(setRemoveArticle(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Insumo eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar Insumo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getAllArticlesMold = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/article-mold");
    const newData = res.data.map((article) => ({
      ...article,
      lotes: article.lotes ? article.lotes : "S/D",
    }));

    dispatch(setArticlesMold(newData));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const createNewArticleMold = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/inventory/article-mold", values);

    const newData = {
      ...res.data,
      lotes: res.data.lotes ? res.data.lotes : "S/D",
    };

    dispatch(addArticlesMold(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Insumo creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear Insumo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const updateArticleMold = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));

    const res = await axiosInstance.put("/inventory/article-mold", values);
    const newData = {
      ...res.data,
      lotes: res.data.lotes ? res.data.lotes : "S/D",
    };

    dispatch(setUpdateArticlesMold(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Insumo actualizado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar Insumo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const deleteArticleMold = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.delete(`/inventory/article-mold?id=${id}`);
    dispatch(setRemoveArticlesMold(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Insumo eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar Insumo",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getAllItemTypes = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/item-type");
    const newData = res.data.map((data) => {
      return {
        ...data,
        modeloText: data.inv_modelo.nombre,
      };
    });
    dispatch(setItemType(newData));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const createItemType = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/inventory/item-type", values);
    const newData = {
      ...res.data,
      modeloText: res.data.inv_modelo.nombre,
    };
    dispatch(addItemType(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Tipo item creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear tipo item",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const updateItemType = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/inventory/item-type", values);
    const newData = {
      ...res.data,
      modeloText: res.data.inv_modelo.nombre,
    };
    dispatch(setUpdateItemType(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Tipo item actualizado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar tipo item",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const deleteItemType = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.delete(`/inventory/item-type?id=${id}`);
    dispatch(setRemoveItemType(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Tipo item eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar tipo item",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getAllProviders = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/providers");
    const newData = res.data.map((data) => {
      return {
        ...data,
        nacionalText: data.nacional ? "Nacional" : "Extranjero",
      };
    });
    dispatch(setProvider(newData));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const createProvider = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.post("/inventory/providers", values);
    const newData = {
      ...res.data,
      nacionalText: res.data.nacional ? "Nacional" : "Extranjero",
    };
    dispatch(addProvider(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Proveedor creado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear proveedor",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const updateProvider = (values) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/inventory/providers", values);
    const newData = {
      ...res.data,
      nacionalText: res.data.nacional ? "Nacional" : "Extranjero",
    };
    dispatch(setUpdateProvider(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Proveedor actualizado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar proveedor",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const deleteProvider = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.delete(`/inventory/providers?id=${id}`);
    dispatch(setRemoveProvider(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Proveedor eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar proveedor",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getAllInventoryIncorporation =
  ({ type }) =>
  async (dispatch) => {
    try {
      const { data } = await axiosInstance.get(`/inventory/incorporation?type=${type}`);
      const newData = data.map((obj) => ({
        ...obj,
        id_incorporacion: obj.id,
        fecha_ingreso_format: dayjs(obj.fecha_ingreso).add(1, "day").format("DD/MM/YYYY"),
        nombre_proveedor: obj.inv_proveedores.descripcion,
        oc_numero: obj.oc_numero || "S/D",
        factura_numero: obj.factura_numero || "S/D",
      }));

      newData.sort(function (a, b) {
        if (a.id_incorporacion > b.id_incorporacion) {
          return -1;
        }
        if (a.id_incorporacion < b.id_incorporacion) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });

      dispatch(setIncorporation(newData));
    } catch (error) {
      console.log(error);
      dispatch(setSuccess(true));
    }
  };

export const getAllRepairPartMold = () => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.get("/inventory/mold");
    const newData = res.data.map((data) => {
      return {
        ...data,
        equipoText: data?.inv_tipo_equipo?.descripcion,
        marcaText: data.inv_marca?.descripcion,
      };
    });

    dispatch(setRepairPartMold(newData));
  } catch (error) {
    console.log(error);
    dispatch(setSuccess(true));
  }
};

export const updateRepairPartMold = (values) => async (dispatch) => {
  const formatedValues = formatDataModal(values);
  formatedValues.medidas = String(values.medidas);
  formatedValues.modelo = String(values.modelo);
  formatedValues.serie = String(values.serie);
  formatedValues.codigo_loginteg = String(values.codigo_loginteg);
  formatedValues.descripcion = String(values.descripcion);

  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.put("/inventory/mold", formatedValues);
    const newData = {
      ...res.data,
      equipoText: res.data?.inv_tipo_equipo?.descripcion,
      marcaText: res.data.inv_marca?.descripcion,
    };
    dispatch(setUpdateRepairPartMold(newData));
    dispatch(
      setNotification({
        status: "success",
        message: "Repuesto actualizado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar repuesto",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const deleteRepairPartMold = (id) => async (dispatch) => {
  try {
    dispatch(setSuccess(false));
    const res = await axiosInstance.delete(`/inventory/mold?id=${id}`);
    dispatch(setRemoveRepairPartMold(res.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Repuesto eliminado con éxito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: error.response.data?.message || "Error al eliminar repuesto",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const getAllStorages = (id) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get("/inventory/storages");
    const up = data.map((el) => ({
      ...el,
      country: el?.paises?.name || "S/D",
    }));

    dispatch(set({ name: "bodegas", data: up }));
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al traer bodegas",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const createNewStorage = (values) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.post("/inventory/storages", { values });

    const up = {
      ...data,
      country: data?.paises?.name || "S/D",
    };

    dispatch(add({ name: "bodegas", data: up }));
    dispatch(
      setNotification({
        status: "success",
        message: "Bodega creada con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear bodega",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const updateStorage = (values) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.put("/inventory/storages", values);
    const up = {
      ...data,
      country: data?.paises?.name || "S/D",
    };

    dispatch(update({ name: "bodegas", data: up }));
    dispatch(
      setNotification({
        status: "success",
        message: "Bodega actualizado con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar bodega",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const deleteStorage = (id) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.delete(`/inventory/storages?id=${id}`);
    dispatch(remove({ name: "bodegas", data: data.id }));
    dispatch(
      setNotification({
        status: "success",
        message: "Bodega eliminada con éxito",
        open: true,
      })
    );
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eiminar bodegas",
        open: true,
      })
    );
    dispatch(setSuccess(true));
  }
};

export const {
  set,
  add,
  update,
  remove,
  setTeamTypes,
  addTeamType,
  setSuccess,
  setUpdateTeamType,
  setRemoveTeamType,
  setVersions,
  addVersion,
  setUpdateVersion,
  setRemoveVersion,
  setTeams,
  setTeamsBodega,
  addTeam,
  setUpdateTeam,
  setRemoveTeam,
  setBrandsSelect,
  setProviderSelect,
  setTeamTypesSelect,
  setVersionsSelect,
  setStatusSelect,
  setRepairParts,
  setRepairPartsBodega,
  addRepairPart,
  setUpdateRepairPart,
  setRemoveRepairPart,
  setTeamsSelect,
  setBrands,
  addBrand,
  setRepairPartsSelect,
  setUpdateBrand,
  setRemoveBrand,
  setModel,
  setModelSelect,
  addModel,
  setUpdateModel,
  setRemoveModel,
  setTypeOfDocument,
  addTypeOfDocument,
  setRemoveTypeOfDocument,
  setUpdateTypeOfDocument,
  setItemTypeSelect,
  setPieza,
  setUpdatePieza,
  addPieza,
  setPiezaById,
  setRemovePieza,
  setArticles,
  addArticle,
  setUpdateArticle,
  setRemoveArticle,
  setItemType,
  addItemType,
  setUpdateItemType,
  setRemoveItemType,
  setProvider,
  addProvider,
  setUpdateProvider,
  setRemoveProvider,
  setElements,
  setIncorporation,
  addRepairPartMold,
  setRemoveRepairPartMold,
  setRepairPartMold,
  setUpdateRepairPartMold,
  addArticlesMold,
  setArticlesMold,
  setRemoveArticlesMold,
  setUpdateArticlesMold,
  setDeleteTeam,
} = inventorySlice.actions;

export default inventorySlice.reducer;
