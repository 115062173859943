import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid";
import exportToExcel from "../../../utils/exportToExcel";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

export default function DataTransitionComponent({ columns, rows, success, dateSort, filename = "TablaExportada" }) {
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <div
          className="text-[#2B2C84] font-medium flex items-center gap-1 text-[13px] cursor-pointer hover:bg-slate-50"
          onClick={() => exportToExcel(rows, columns, filename)}
        >
          <SaveAltIcon sx={{ height: "22px", width: "22px" }} /> <span>EXPORTAR A EXCEL</span>
        </div>
      </GridToolbarContainer>
    );
  }
  return (
    <Box sx={{ height: "500px" }}>
      <DataGrid
        className="transition-all"
        sx={{ p: 1, overflowX: "scroll" }}
        rows={rows}
        columns={columns}
        slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress }}
        hideFooterPagination={true}
        initialState={{
          sorting: {
            sortModel: [{ field: !dateSort ? "id" : "closestMaintenance", sort: "asc" }],
          },
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        loading={!success ? true : false}
      />
    </Box>
  );
}
