import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../utils/axiosInstance';
import { setNotification } from '../notification';
import dayjs from 'dayjs';

const initialState = {
  bodegasSelect: [],
  teamTypeSelect: [],
  modelSelect: [],
  versionSelect: [],
  storageTeam: [],
  storageInsumos: [],
  InsumosDetail: [],
  storageRepuestos: [],
  contactsDetail: [],
  teamsAssigned: [],
  technicalSelect: [],
  otAssigned: [],
  otGeneral: [],
  rendicionAnual: [],
  rendicionGastos: [],
  listadoRendicion: [],
  status: 'idle',
};

const informeSlice = createSlice({
  name: 'informes',
  initialState,
  reducers: {
    setter: (state, action) => {
      state[action.payload.setter] = action.payload.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const getBodegaSelect = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/informes/equipos-bodega/bodegasSelect');
    dispatch(setter({ setter: 'bodegasSelect', payload: response.data }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getTeamTypeSelect = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/informes/equipos-bodega/teamTypeSelect');
    dispatch(setter({ setter: 'teamTypeSelect', payload: response.data }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getModelSelect = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/informes/equipos-bodega/modelsSelect');
    dispatch(setter({ setter: 'modelSelect', payload: response.data }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getVersionSelect = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/informes/equipos-bodega/versionSelect');
    dispatch(setter({ setter: 'versionSelect', payload: response.data }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getStorageTemsFilted =
  ({ bodegaId, teamId, model, versionId }) =>
  async (dispatch) => {
    try {
      const { data } = await axiosInstance.get(
        `/informes/equipos-bodega/storageTems?bodegaId=${bodegaId}&teamsId=${teamId}&model=${model}&versionId=${versionId}`
      );
      const newData = data.map((el) => ({
        ...el,
        tipo_equipo_nombre: el.inv_tipo_equipo?.descripcion || 'S/D',
        version_nombre: el.inv_version?.descripcion || 'S/D',
      }));
      dispatch(setter({ setter: 'storageTeam', payload: newData }));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(error));
    }
  };

export const getStorageInsumosFilted =
  ({ bodegaId }) =>
  async (dispatch) => {
    try {
      const { data } = await axiosInstance.get(`/informes/inv-insumo/storageInsumos?bodegaId=${bodegaId}`);
      const parsedDate = [];

      data.forEach((insumo) => {
        if (!parsedDate.length) {
          parsedDate.push({
            id: insumo.id,
            codigo_loginteg: insumo.codigo_loginteg,
            bodegaName: insumo.bodega.id,
            nombre: insumo.nombre,
            cantidad: insumo.cantidad,
          });
        } else {
          let finded = parsedDate.find((el) => el.codigo_loginteg === insumo.codigo_loginteg);
          if (!finded) {
            parsedDate.push(insumo);
          } else {
            finded.cantidad += insumo.cantidad;
          }
        }
      });

      // const newData = data.map((el) => ({
      //   ...el,
      //   vencimiento: dayjs(el?.fecha_vencimiento).add(1, "day").format("DD/MM/YYYY") || "S/D",
      // }));
      dispatch(setter({ setter: 'storageInsumos', payload: parsedDate }));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(error));
    }
  };

export const getRendicionAnual = (year) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get(`/informes/rendicion-anual?year=${year}`);

    const newData = data.map((el) => ({
      ...el,
      colaboradorInforme: el.colaborador,
      enero: `$ ${el.enero.toLocaleString()}`,
      febrero: `$ ${el.febrero.toLocaleString()}`,
      marzo: `$ ${el.marzo.toLocaleString()}`,
      abril: `$ ${el.abril.toLocaleString()}`,
      mayo: `$ ${el.mayo.toLocaleString()}`,
      junio: `$ ${el.junio.toLocaleString()}`,
      julio: `$ ${el.julio.toLocaleString()}`,
      agosto: `$ ${el.agosto.toLocaleString()}`,
      septiembre: `$ ${el.septiembre.toLocaleString()}`,
      octubre: `$ ${el.octubre.toLocaleString()}`,
      noviembre: `$ ${el.noviembre.toLocaleString()}`,
      diciembre: `$ ${el.diciembre.toLocaleString()}`,
      total: `${el.total.toLocaleString()}`,
    }));
    dispatch(setter({ setter: 'rendicionAnual', payload: newData }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getRendicionGastos = (initial_date, final_date) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get(`/informes/rendicion-gastos?initial_date=${initial_date}&final_date=${final_date}`);
    const newData = data.map((el) => ({
      ...el,
      colaboradorInforme: el.colaborador,
    }));
    dispatch(setter({ setter: 'rendicionGastos', payload: newData }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getListadoRendicion = (initial_date, final_date, userId) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get(`/informes/listado-rendicion?initial_date=${initial_date}&final_date=${final_date}&userId=${userId}`);

    dispatch(setter({ setter: 'listadoRendicion', payload: data }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getStorageInsumosByCode = (code, bodegaId) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get(`/informes/inv-insumo/storageInsumosByCode?code=${code}&bodegaId=${bodegaId}`);

    dispatch(setter({ setter: 'InsumosDetail', payload: data }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getStorageRepuestosFiltered =
  ({ bodegaId }) =>
  async (dispatch) => {
    try {
      const { data } = await axiosInstance.get(`/informes/inv-insumo/storageRepuestos?bodegaId=${bodegaId}`);
      const newData = data.map((el) => ({
        ...el,
        marca: el.inv_marca?.descripcion || 'S/D',
      }));
      dispatch(setter({ setter: 'storageRepuestos', payload: newData }));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(error));
    }
  };

export const getContactsDetail = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get('/informes/contacts/contactsDetail');
    const newData = data.map((el) => ({
      ...el,
      cargo_nombre: el.gen_cargo?.descripcion || 'S/D',
      area_nombre: el.area?.name || 'S/D',
      medio_contacto_nombre: el.gen_medio_contacto?.name || 'S/D',
      pais_nombre: el.paises?.name || 'S/D',
      is_principal: el.principal ? 'SI' : 'NO',
      fecha_nacimiento: el.f_nacimiento ? dayjs(el?.f_nacimiento).add(1, 'day').format('DD/MM/YYYY') : 'S/D',
    }));
    dispatch(setter({ setter: 'contactsDetail', payload: newData }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getTeamsAssigned = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance.get('/informes/equipos-asignados/teamsOt');

    const newData = data.map((el) => ({
      id: el.id,
      bodega: el.inv_equipo?.bodega?.name || 'S/D',
      marca: el.inv_equipo?.inv_marca?.descripcion || 'S/D',
      proveedor: el.inv_equipo?.inv_proveedor?.descripcion || 'S/D',
      tipo_equipo: el.inv_equipo?.inv_tipo_equipo?.descripcion || 'S/D',
      modelo: el.inv_equipo?.inv_tipo_equipo?.inv_modelo?.descripcion || 'S/D',
      version: el.inv_equipo?.inv_version?.descripcion || 'S/D',
      nombre: el.cliente_cliente_equipo_clienteTocliente?.nombre,
      rut: el.cliente_cliente_equipo_clienteTocliente?.rut,
      direccion: el.cliente_cliente_equipo_clienteTocliente?.direccion,
      tipo_empresa: el.cliente_cliente_equipo_clienteTocliente?.tipo_empresa,
      nombre_pago: el.cliente_cliente_equipo_clienteTocliente?.nombre_pago || 'S/D',
      correo_pago: el.cliente_cliente_equipo_clienteTocliente?.correo_pago || 'S/D',
      comuna: el.cliente_cliente_equipo_clienteTocliente?.gen_comuna?.descripcion || 'S/D',
      region: el.cliente_cliente_equipo_clienteTocliente?.gen_region?.descripcion || 'S/D',
      razon_social: el.cliente_cliente_equipo_clienteTocliente?.gen_tipo_razon_social?.descripcion || 'S/D',
      pais: el.cliente_cliente_equipo_clienteTocliente?.pais?.name || 'S/D',
      serie: el?.inv_equipo?.serie || 'S/D',
    }));
    dispatch(setter({ setter: 'teamsAssigned', payload: newData }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getTechnicalSelect = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/informes/ot-asignadas/techincalSelect');
    dispatch(setter({ setter: 'technicalSelect', payload: response.data }));
  } catch (error) {
    console.log(error);
    dispatch(setStatus(error));
  }
};

export const getOtAssigned =
  ({ initial_date, final_date, technical }) =>
  async (dispatch) => {
    try {
      const { data } = await axiosInstance.get(
        `/informes/ot-asignadas/otAssigned?initial_date=${initial_date}&final_date=${final_date}&technical=${technical}`
      );
      // const dataStatus = data.finalizada
      //   ? "Finalizada"
      //   : data.cerrada
      //   ? "Cerrada"
      //   : data.ot_usuarios?.length
      //   ? "Asignada"
      //   : "Sin Asignar";

      const newData = data.map((el) => ({
        ...el,
        nro_ot: el.ot?.id,
        estado: el.ot?.finalizada ? 'Finalizada' : el.ot?.cerrada ? 'Cerrada' : el.ot?.ot_usuarios?.length ? 'Asignada' : 'Sin Asignar',
        cliente: el.ot?.cliente_ot_clienteTocliente?.nombre,
        fecha: dayjs(el?.ot?.f_ot).add(1, 'day').format('DD/MM/YYYY'),
      }));

      dispatch(setter({ setter: 'otAssigned', payload: newData }));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(error));
    }
  };

export const getOtGeneral =
  ({ initial_date, final_date, client, n_oc }) =>
  async (dispatch) => {
    try {
      const { data } = await axiosInstance.get(
        `/informes/ot-asignadas/otGeneral?initial_date=${initial_date}&final_date=${final_date}&client=${client}&n_oc=${n_oc}`
      );
      // const dataStatus = data.finalizada
      //   ? "Finalizada"
      //   : data.cerrada
      //   ? "Cerrada"
      //   : data.ot_usuarios?.length
      //   ? "Asignada"
      //   : "Sin Asignar";

      const newData = data.map((el) => {
        let personal_asignado = '';
        el.ot_usuarios?.forEach((el) => (personal_asignado += `${el.gen_usuario.nombre}, `));
        return {
          ...el,
          ot_id: el.id,
          estado: el?.finalizada ? 'Finalizada' : el.cerrada ? 'Cerrada' : el.ot_usuarios?.length ? 'Asignada' : 'Sin Asignar',
          cliente: el.cliente_ot_clienteTocliente?.nombre,
          direccion: el.cliente_ot_clienteTocliente?.direccion,
          tipo: el.gen_tipo_ot?.descripcion,
          fecha: dayjs(el?.f_ot).add(1, 'day').format('DD/MM/YYYY'),
          personal: personal_asignado,
          f_ejecucion: el.f_ejecucion ? dayjs(el?.f_ejecucion).add(1, 'day').format('DD/MM/YYYY') : 'S/D',
        };
      });

      dispatch(setter({ setter: 'otGeneral', payload: newData }));
    } catch (error) {
      console.log(error);
      dispatch(setStatus(error));
    }
  };

export const { setter, setStatus } = informeSlice.actions;

export default informeSlice.reducer;
