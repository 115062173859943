import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import useDataGrid from '../../../hooks/useDataGrid';
import { getAllArticles } from '../../../redux/slices/inventory';
import TeamModalCreate from '../../modal/TeamsModalCreate';
import DataTransitionComponent from './DataTransitionComponent';
import TeamTransitonModalCreate from '../../modal/TeamTransitonModalCreate';
import Checkbox from '@mui/material/Checkbox';

const createArr = [
  { title: 'Tipo de equipo', value: 'tipoText' },
  { title: 'Modelo', value: 'modeloText' },
  { title: 'Version', value: 'versionText' },
  { title: 'Serie', value: 'serie' },
  { title: 'Marca', value: 'marcaText' },
  { title: 'Valor', value: 'valueCost' },
  { title: 'Eliminar', value: 'deleteTeam' },
];

const editArr = [
  { title: 'Tipo de equipo', value: 'inv_tipo_equipo' },
  { title: 'Modelo', value: 'modelo' },
  { title: 'Version', value: 'inv_version' },
  { title: 'Serie', value: 'serie' },
  { title: 'Marca', value: 'inv_marca' },
  { title: 'Estado', value: 'inv_estado' },
];

export default function EquipamentTransitAccordion({ equipos, setEquipos, edit, setEquiposCheck, equiposCheck }) {
  const [id, setId] = useState(1);

  const permitedRenderDataColumn = edit ? editArr : createArr;

  const dispatch = useDispatch();
  const { articles, success } = useSelector((state) => state.inventory);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const handleDelete = (params) => {
    setEquipos(equipos.filter((equipo) => equipo.id !== params));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, 'Articulos', false, setOpen);

  useEffect(() => {
    dispatch(getAllArticles());
  }, [dispatch]);

  return (
    <div>
      <Accordion>
        <AccordionSummary sx={{ bgcolor: '#e4dadab8', borderRadius: 1 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <div className="flex justify-center font-bold uppercase tracking-wider text-sm mr-5">Recepción de Equipos</div>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: '#fdf9f9', borderBottomLeftRadius: 1, borderBottomRightRadius: 1 }}>
          <div>
            <div className={`flex justify-end mb-5 ${edit && 'hidden'}`}>
              <div>
                <TeamTransitonModalCreate id={id} setId={setId} setEquipos={setEquipos} />
              </div>
            </div>

            {!edit ? (
              <DataTransitionComponent columns={columns} rows={equipos} success={success} />
            ) : (
              <>
                <div className="grid grid-cols-10 mb-4">
                  <div className="col-span-2">Tipo de equipo</div>
                  <div className="col-span-2">Modelo</div>
                  <div className="col-span-2">Version</div>
                  <div>Serie</div>
                  <div>Marca</div>
                  <div>Valor</div>
                  <div>Completo</div>
                </div>
                {equipos.map((el) => (
                  <div className="grid grid-cols-10">
                    <div className="col-span-2">{el.inv_tipo_equipo}</div>
                    <div className="col-span-2">{el.modelo}</div>
                    <div className="col-span-2">{el.inv_version}</div>
                    <div>{el.serie}</div>
                    <div>{el.inv_marca}</div>
                    <div>{el.valueCost}</div>
                    <div>
                      <Checkbox
                        checked={el.isComplete || equiposCheck.some((id) => id === el.id_complete)}
                        disabled={el.isComplete}
                        onChange={(e) => {
                          if (e.target.checked) {
                            if (!equiposCheck.some((id) => id === el.id_complete)) {
                              setEquiposCheck((prevState) => [...prevState, el.id_complete]);
                            }
                          } else {
                            setEquiposCheck((prevState) => prevState.filter((id) => id !== el.id_complete));
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
