import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { useEffect, useState } from "react";
import useDataGrid from "../hooks/useDataGrid";
import DataGrid from "../components/tables/DataGrid";
import Chip from "@mui/material/Chip";
import { getAllMaintenances, setOpenMaintenance } from "../redux/slices/invProducts";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { setNotification } from "../redux/slices/notification";

const programmingColumns = [
  { value: "descripcion", title: "Descripcion", minWidth: 170 },
  { value: "serie", title: "Serie", minWidth: 100 },
  { value: "f_programacion", title: "Fecha de mantencion", minWidth: 100 },
  { value: "dias_faltantes", title: "Dias faltantes" },
  { value: "generar_ot", title: "Generar OT" },
];

const permitedRenderDataColumn = [
  { value: "descripcion", title: "Descripcion", minWidth: 170 },
  { value: "modelo", title: "Modelo", minWidth: 100 },
  {
    value: "serie",
    title: "Serie",
    minWidth: 170,
  },
  {
    value: "cliente",
    title: "Cliente",
    minWidth: 170,
  },
  {
    title: "Fecha mantencion",
    value: "closestMaintenance",
    minWidth: 170,
  },
  {
    value: "mantencion",
    title: "Accion",
    minWidth: 170,
    align: "center",
  },
];

export default function Maintenance() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [render, setRender] = useState([]);
  const { maintenances, openMaintenance, maintenance, success } = useSelector((state) => state.invProducts);

  const handleDelete = () => {};

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, "Crear OT", false);

  useEffect(() => {
    dispatch(getAllMaintenances());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (maintenances?.length) {
      const render = [...maintenances]
        .filter((data) => {
          const maintenanceDates = data.inv_equipo.ot_programacion.map((date) => date.f_programacion);
          const permited = maintenanceDates.filter((date) => dayjs(date.slice(0, -2)).isAfter(dayjs(), "day"));
          return permited.length > 0;
        })
        .map((el) => ({ ...el, descripcion: el.inv_equipo?.inv_tipo_equipo?.descripcion }));

      setRender(render);
    }
  }, [maintenances]);

  const handleBack = () => {
    dispatch(setOpenMaintenance({ openMaintenance: false, maintenance: null }));
    dispatch(getAllMaintenances());
  };

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Listado Mantenciones</h1>
          <BreadCrumb origin={"Servicio técnico"} current={"Listado Mantenciones"} />
        </div>
      </div>
      {!openMaintenance ? (
        <DataGrid
          dateSort={true}
          columns={columns}
          rows={render.filter((data) => {
            const maintenanceDates = data.inv_equipo.ot_programacion.map((date) => date.f_programacion);
            const permited = maintenanceDates.filter((date) => dayjs(date.slice(0, -2)).isAfter(dayjs(), "day"));
            return permited.length > 0;
          })}
          success={success}
        />
      ) : (
        <div className="mt-5">
          <div className="flex mb-5">
            <Chip color="primary" onClick={handleBack} sx={{ textTransform: "none", mr: 1 }} variant="contained" label="Volver a la lista" />
          </div>
          <div className=" mb-2 bg-slate-200 text-lg p-2 rounded font-semibold">Mantenciones Programadas</div>
          <TableContainer sx={{ maxHeight: 300, position: "relative" }} className="mb-5 bg-slate-100 rounded p-2">
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  {programmingColumns.map((column) => (
                    <TableCell key={column.value}>{column.title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {[...maintenance.inv_equipo.ot_programacion]
                  .filter(
                    (ot) => dayjs(ot.f_programacion.slice(0, -2)).isSame(dayjs().format("YYYY-MM-DD")) || dayjs(ot.f_programacion.slice(0, -2)).isAfter(dayjs())
                  )
                  .sort((a, b) => dayjs(a.f_programacion.slice(0, -2)).diff(dayjs()) - dayjs(b.f_programacion.slice(0, -2)).diff(dayjs()))
                  .map((ot) => (
                    <TableRow key={ot.id}>
                      <TableCell>{ot.inv_equipo.inv_tipo_equipo.descripcion}</TableCell>
                      <TableCell>{ot.inv_equipo.serie}</TableCell>
                      <TableCell>{dayjs(ot.f_programacion.slice(0, -2)).format("DD/MM/YYYY")}</TableCell>
                      <TableCell>
                        {dayjs(ot.f_programacion.slice(0, -2)).diff(dayjs(), "day")} dias{" "}
                        {dayjs(ot.f_programacion.slice(0, -2)).diff(dayjs(), "day") === 0 &&
                          dayjs(ot.f_programacion.slice(0, -2)).diff(dayjs(), "hours") + " horas"}
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Chip
                          color="primary"
                          onClick={() =>
                            axiosInstance.post("/equipo/mantencion/ot", ot).then(() => {
                              dispatch(
                                setNotification({
                                  open: true,
                                  message: "OT Creada con éxito",
                                  status: "success",
                                })
                              );
                            })
                          }
                          sx={{ textTransform: "none", mr: 1 }}
                          variant="contained"
                          label="Generar OT"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
