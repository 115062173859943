import React from "react";
import { Page, Text, View, Document, StyleSheet, Font, pdf, Image, PDFViewer } from "@react-pdf/renderer";
import enviroments from "../../utils/enviroments";
import RobotoBold from "../../assets/Roboto-Bold.ttf";
import RobotoRegular from "../../assets/Roboto-Regular.ttf";

const styles = StyleSheet.create({
  borderedView: { border: "2px solid black", height: "100%" },
  titleHeaderBlue: {
    backgroundColor: "#2B2C84",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: "14pt",
  },
  formTitle: { fontSize: "9pt", fontWeight: "bold", minWidth: "40px", marginRight: "5px" },
  formTitleMedium: { fontSize: "9pt", fontWeight: "bold", minWidth: "65px", marginRight: "5px" },
  formTitleLarge: { fontSize: "9pt", fontWeight: "bold", minWidth: "80px", marginRight: "5px" },
  formTitleXLarge: { fontSize: "9pt", fontWeight: "bold", minWidth: "100px", marginRight: "5px" },
  formTitleTiny: { fontSize: "9pt", fontWeight: "bold", minWidth: "10px" },
  formData: {
    fontSize: "9pt",
    borderBottom: "2px solid black",
    marginLeft: "10px",
    marginRight: "10px",
    width: "100%",
  },
});

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoRegular,
    },
    {
      src: RobotoBold,
      fontWeight: "bold",
    },
  ],
});
const PayFundAccountingPdf = ({ data, current, current_rut }) => {
  return (
    <Document>
      {data.rendicion_detalle.length &&
        data.rendicion_detalle.map((el, index) => (
          <Page
            key={index}
            size="A4"
            style={{
              padding: 10,
              display: "flex",
              fontFamily: "Roboto",
              justifyContent: "center",
            }}
          >
            <Image
              source={`${enviroments.img_endpoint}${el.documento}`}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Page>
        ))}
    </Document>
  );
};

export const generateAndSendPayPDF = async (data, current, current_rut) => {
  try {
    const pdfBlob = await pdf(<PayFundAccountingPdf data={data} current={current} current_rut={current_rut} />).toBlob();
    return pdfBlob;
  } catch (error) {
    console.log(error);
  }
};

export default PayFundAccountingPdf;
