import { Button, Chip, InputAdornment, MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getClientById, setClientById } from '../../../redux/slices/clients';
import PreFacturaAccordionEquipamnt from '../../../components/accordion/PreFacturaAccordions/PreFacturaAccordionEquipamnt';
import PreFacturaAccordionItems from '../../../components/accordion/PreFacturaAccordions/PreFacturaAccordionItems';
import PreFacturaAccordionRepuestos from '../../../components/accordion/PreFacturaAccordions/PreFacturaAccordionRepuestos';
import { useState } from 'react';
import { createFacturacion, getFacturacionById, setFacturaId, updateOC } from '../../../redux/slices/facturacion';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import uploadFiles from '../../../utils/UploadFiles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getAllServices, setServices } from '../../../redux/slices/services';
import PreFacturaAccordionServices from '../../../components/accordion/PreFacturaAccordions/PreFacturaAccordionServices';
import { getAllGanancias } from '../../../redux/slices/ganancias';
import { getAllRiesgo } from '../../../redux/slices/riesgo';
import thousandSeparator from '../../../utils/thousandSeparator';
import removeNonNumeric from '../../../utils/removeNaN';
import enviroments from '../../../utils/enviroments';

export default function PruchOrderEdit() {
  const [equiposAsignados, setEquiposAsignados] = useState([]);
  const [itemsAsignados, setItemsAsignados] = useState([]);
  const [itemsAsignadosBackend, setItemsAsignadosBackend] = useState([]);
  const [riesgoCliente, setRiesgoCliente] = useState(0);
  const [nroOcMp, setNroOcMp] = useState('');
  const [nroOc, setNroOc] = useState('');
  const [descuento, setDescuento] = useState(0);
  const [totalDescuento, setTotalDescuento] = useState(0);
  const [montoIva, setMontoIva] = useState('');
  const [monto, setMonto] = useState('');
  const [ganEquipos, setGanEquipos] = useState(0);
  const [ganRepuestos, setGanRepuestos] = useState(0);
  const [ganArticulos, setGanArticulos] = useState(0);
  const [subTotalRepuestos, setSubTotalRepuestos] = useState(0);
  const [subTotalEquipos, setSubTotalEquipos] = useState(0);
  const [subTotalItems, setSubTotalItems] = useState(0);
  const [date, setDate] = useState(dayjs());
  const [dateFacturacion, setDateFacturacion] = useState(dayjs());
  const [repuestosBackend, setRepuestosBackend] = useState([]);
  const [repuestosAsignados, setRepuestosAsignados] = useState([]);
  const [nroLicitacion, setNroLicitacion] = useState('');
  const [serviciosAsignados, setServiciosAsignados] = useState([]);
  const [observacion, setObservacion] = useState('');
  const [file, setFile] = useState({});
  const [isParcial, setIsParcial] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);
  const [generateOt, setGenerateOt] = useState(false);
  const [serviceId, setServicesId] = useState(0);
  const [subTotalServicios, setSubTotalServicios] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.clients);
  const { currentUser } = useSelector((state) => state.auth);
  const { services } = useSelector((state) => state.services);
  const { facturaId } = useSelector((state) => state.facturacion);
  const { ganancias } = useSelector((state) => state.ganancias);
  const { riesgo } = useSelector((state) => state.riesgo);
  const [montoSinIva, setMontoSinIva] = useState('');
  const [montoSinIvaNumber, setMontoSinIvaNumber] = useState('');

  useEffect(() => {
    if (facturaId) {
      dispatch(getClientById(facturaId.cliente));
      dispatch(getAllServices());
      dispatch(getAllGanancias());
      dispatch(getAllRiesgo());
    }

    return () => {
      dispatch(setClientById({}));
      dispatch(setServices([]));
    };
  }, [facturaId]);

  useEffect(() => {
    dispatch(getFacturacionById(id)).then(() => cargaFunction());

    return () => dispatch(setFacturaId({}));
  }, []);

  useEffect(() => {
    let iva = Math.round((montoSinIvaNumber * 19) / 100);
    let monto = Math.round(montoSinIvaNumber + iva);
    setMontoIva(thousandSeparator(iva));
    setMonto(thousandSeparator(monto));
  }, [montoSinIvaNumber]);

  useEffect(() => {
    if (ganancias) {
      let equipoGanancia = ganancias.find((el) => el.id === 3);
      let repuestosGanancia = ganancias.find((el) => el.id === 2);
      let articulosGanancia = ganancias.find((el) => el.id === 1);
      setGanEquipos(equipoGanancia?.porcentaje);
      setGanRepuestos(repuestosGanancia?.porcentaje);
      setGanArticulos(articulosGanancia?.porcentaje);
    }
  }, [ganancias]);

  useEffect(() => {
    if (facturaId) {
      let sumaEquipo = 0;
      let sumaRepuestos = 0;
      let sumaArticulos = 0;
      equiposAsignados.forEach((el) => (sumaEquipo += el.valueCost));
      repuestosAsignados.forEach((el) => (sumaRepuestos += el.valueCost));
      itemsAsignados.forEach((el) => (sumaArticulos += el.valueCost));
      setSubTotalEquipos(sumaEquipo);
      setSubTotalItems(sumaArticulos);
      setSubTotalRepuestos(sumaRepuestos);
    }
  }, [facturaId, equiposAsignados, repuestosAsignados, itemsAsignados]);

  useEffect(() => {
    let monto =
      // subTotalItems +

      // (ganArticulos * subTotalItems) / 100 +
      // (subTotalEquipos + (ganEquipos * subTotalEquipos) / 100) +
      subTotalEquipos + subTotalItems + subTotalRepuestos + subTotalServicios;
    // (subTotalRepuestos + (ganRepuestos * subTotalRepuestos) / 100);
    if (monto >= 1000) {
      setMontoSinIva(thousandSeparator(monto));
      setMontoSinIvaNumber(monto);
    } else {
      setMontoSinIvaNumber(monto);
      setMontoSinIva(monto);
    }
    if (monto - (descuento / 100) * monto >= 100) {
      let amount = monto;

      if (typeof monto === 'string' && monto.includes('.')) {
        amount = monto.replaceAll('.', '');
      }
      setTotalDescuento(thousandSeparator(Math.round(amount - (descuento / 100) * amount)));
    } else {
      let amount = monto;
      if (typeof monto === 'string' && monto.includes('.')) {
        amount = monto.replaceAll('.', '');
      }

      setTotalDescuento(amount - (descuento / 100) * amount);
    }
  }, [subTotalEquipos, subTotalItems, subTotalRepuestos, subTotalServicios]);

  useEffect(() => {
    cargaFunction();
  }, [facturaId]);

  useEffect(() => {
    const array = [];
    if (Object.keys(client).length && riesgo.length) {
      client?.facturacion_facturacion_clienteTocliente?.forEach((el) => {
        if (el.cobranza.length) {
          el.cobranza.forEach((cob) => {
            if (cob.cobranza_pagos.length) {
              array.push({ f_vencimiento: cob.f_vencimiento, f_pago: cob.cobranza_pagos[0].f_pago });
            }
          });
        }
      });
      let suma = 0;
      let cant = 0;
      array.forEach((el) => {
        const diffInDays = dayjs(el.f_vencimiento).diff(dayjs(el.f_pago), 'day');
        suma += diffInDays;
        cant += 1;
      });
      let diff = suma / cant;
      let riesgoFilter = riesgo.filter((el) => {
        return parseInt(el.desde) <= diff && parseInt(el.hasta) >= diff;
      });
      if (cant > 0) {
        setRiesgoCliente(riesgoFilter[0]?.name);
      } else {
        setRiesgoCliente('SIN HISTORIAL');
      }
    }
  }, [client, riesgo]);

  const cargaFunction = async () => {
    if (Object.keys(facturaId).length) {
      const resp = [];
      if (facturaId.facturacion_servicios.length) {
        facturaId.facturacion_servicios.forEach((serv) => {
          console.log(serv);
          resp.push({ ...serv.servicios, valueCost: parseInt(serv.valueCost), cantidad: serv.cantidad });
        });
        setServiciosAsignados(resp);
      }
      if (facturaId.facturacion_equipo.length) {
        const resp = [];
        facturaId.facturacion_equipo.forEach((equipo) => {
          resp.push({ ...equipo.inv_equipo, valueCost: parseInt(equipo.valueCost) });
        });
        setEquiposAsignados(resp);
      }
      if (facturaId?.facturacion_repuestos?.length) {
        const resp = [];
        facturaId.facturacion_repuestos.forEach((rep) => {
          resp.push({
            ...rep.inv_repuesto,
            valueCost: parseInt(rep.valueCost),
            cantidad_solic: rep.cantidad,
            cant_back: rep.cantidad,
          });
        });
        setRepuestosAsignados(resp);
        setRepuestosBackend(resp);
      }
      if (facturaId?.facturacion_articulos?.length) {
        const resp = [];
        facturaId.facturacion_articulos.forEach((rep) => {
          resp.push({
            ...rep.inv_articulos,
            valueCost: parseInt(rep.valueCost),
            cantidad_solic: rep.cantidad,
            cant_back: rep.cantidad,
          });
        });
        setItemsAsignados(resp);
        setItemsAsignadosBackend(resp);
      }
      if (facturaId.monto) {
        setMonto(facturaId.monto >= 100 ? thousandSeparator(Math.round(facturaId.monto)) : facturaId.monto);
      }
      if (facturaId.nro_oc) {
        setNroOcMp(facturaId.nro_oc);
      }
      if (facturaId.nroLicitacion) {
        setNroLicitacion(facturaId.nroLicitacion);
      }
      if (facturaId.observacion) {
        setObservacion(facturaId.observacion);
      }
      setIsParcial(facturaId.pago_parcial);
      setIsMultiple(facturaId.multiple);
      if (facturaId.descuento) {
        setDescuento(facturaId.descuento);
      }
      setTotalDescuento(thousandSeparator(Math.round(facturaId.monto - (facturaId.descuento / 100) * facturaId.monto)));
      if (facturaId.nro_oc_noMP) {
        setNroOc(facturaId.nro_oc_noMP);
      }
    }
  };

  const handleChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOc = (e) => {
    setNroOcMp(e.target.value);
  };

  const handleOcNoMP = (e) => {
    setNroOc(e.target.value);
  };

  const handleMonto = (e) => {
    let val = e.target.value;
    setMonto(thousandSeparator(removeNonNumeric(e.target.value)));
    if (val.includes('.')) {
      val = e.target.value.replaceAll('.', '');
    }
    setTotalDescuento(thousandSeparator(Math.round(val - (descuento / 100) * val)));
  };

  const handleDescuento = (e) => {
    setDescuento(e.target.value);
    let amount = monto;
    if (monto.includes('.')) {
      amount = monto.replaceAll('.', '');
    }
    setTotalDescuento(thousandSeparator(Math.round(amount - (e.target.value / 100) * amount)));
  };

  const handleDateChange = (e) => {
    setDate(e);
  };

  const handleDateFacturacionChange = (e) => {
    setDateFacturacion(e);
  };

  const handleObservation = (e) => {
    setObservacion(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = {};
    if (file.name) {
      const uploaded = await uploadFiles('docs', file);
      values.file_oc = uploaded.data;
    }
    const equipos = [];
    const repuestos = [];
    if (equiposAsignados.length) {
      equiposAsignados.forEach((equipo) => equipos.push(equipo));
    }
    if (repuestosAsignados.length) {
      repuestosAsignados.forEach((repuesto) =>
        repuestos.push({
          ...repuesto,
          cantidad: repuesto.cantidad,
          cantidad_solic: repuesto.cantidad_solic,
          id: repuesto.id,
          id_inventario: repuesto.id_inventario,
        })
      );
    }
    console.log(facturaId);
    values.cliente = facturaId.cliente;
    values.responsable = currentUser.id;
    values.generateOt = generateOt;
    values.equipos = equipos;
    values.articulos = itemsAsignados;
    values.repuestos = repuestos;
    values.nro_oc = nroOcMp;
    values.fecha_oc = date;
    values.pago_parcial = isParcial;
    values.observacion = observacion;
    values.multiple = isMultiple;
    values.monto = typeof monto === 'string' && monto.includes('.') ? parseInt(monto.replaceAll('.', '')) : monto;
    values.servicios = serviciosAsignados;
    values.clienteNombre = client.nombre;
    values.facturaId = facturaId.id;
    values.descuento = descuento;
    values.nro_oc_noMP = nroOc;
    values.ot = facturaId.ot_id;
    values.repuestosToDelete = repuestosBackend;
    values.articulosToDelete = itemsAsignadosBackend;
    values.nroLicitacion = nroLicitacion === '' ? null : nroLicitacion;
    // console.log(values);
    const res = await dispatch(updateOC(values));
    if (res.status === 201) {
      navigate('/por-facturar');
    }
  };
  const handleServiceChange = (e) => {
    setServicesId(e.target.value);
  };

  return (
    <div>
      {client && Object.keys(client).length > 0 ? (
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="flex mb-2">
            <div className="w-[10%] flex items-center">
              <Chip label="Volver" sx={{ minWidth: '100px' }} color="primary" onClick={() => navigate('/por-facturar')} />
            </div>
          </div>
          <div className="flex justify-between mb-10">
            <div>
              <div className="flex">
                <div className="min-w-[150px] font-semibold">NOMBRE: </div>
                <div>{client.nombre?.toUpperCase()}</div>
              </div>
              <div className="flex">
                <div className="min-w-[150px] font-semibold ">DIRECCION: </div>
                <div>{client.direccion?.toUpperCase()}</div>
              </div>
              <div className="flex">
                <div className="min-w-[150px] font-semibold ">RUT: </div>
                <div>{client.rut} </div>
              </div>
              <div className="flex">
                <div className="min-w-[150px] font-semibold ">MAIL CONTACTO: </div>
                <div>{client.mail_contacto?.toUpperCase()} </div>
              </div>
              <div className="flex">
                <div className="min-w-[150px] font-semibold ">OC: </div>
                <a
                  href={`${enviroments.img_endpoint}${facturaId?.file_oc}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  download="proposed_file_name"
                  className="w-[140px] h-[19px] p-3 rounded-[20px] bg-red-500 text-white flex justify-center items-center text-sm mt-2"
                >
                  Descargar OC
                </a>
              </div>
            </div>
            <div>
              <div className="text-4xl flex pr-10">ORDEN DE COMPRA</div>
              <div className="flex justify-center mt-5">
                {riesgoCliente === 'ALTO' ? (
                  <Chip color="error" label="RIESGO ALTO" />
                ) : riesgoCliente === 'MEDIO' ? (
                  <Chip color="warning" label="RIESGO MEDIO" />
                ) : riesgoCliente === 'BAJO' ? (
                  <Chip color="success" label="RIESGO BAJO" />
                ) : riesgoCliente === 'SIN HISTORIAL' ? (
                  <Chip label="SIN HISTORIAL" />
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
          <div className="mb-[50px]">
            <PreFacturaAccordionEquipamnt
              equiposAsignados={equiposAsignados}
              setEquiposAsignados={setEquiposAsignados}
              setSubTotalEquipos={setSubTotalEquipos}
              subTotalEquipos={subTotalEquipos}
              ganEquipos={ganEquipos}
            />
          </div>
          <div className="mb-[50px]">
            <PreFacturaAccordionRepuestos
              repuestosAsignados={repuestosAsignados}
              setRepuestosAsignados={setRepuestosAsignados}
              subTotalRepuestos={subTotalRepuestos}
              setSubTotalRepuestos={setSubTotalRepuestos}
              ganRepuestos={ganRepuestos}
            />
          </div>
          <div className="mb-[50px]">
            <PreFacturaAccordionItems
              itemsAsignados={itemsAsignados}
              setItemsAsignados={setItemsAsignados}
              subTotalItems={subTotalItems}
              setSubTotalItems={setSubTotalItems}
              ganArticulos={ganArticulos}
            />
          </div>
          <div className="mb-[50px]">
            <PreFacturaAccordionServices
              asignados={serviciosAsignados}
              setter={setServiciosAsignados}
              subTotalServicios={subTotalServicios}
              setSubTotalServicios={setSubTotalServicios}
            />
          </div>
          <div className="grid grid-cols-2 gap-10">
            <div>
              <div className="mb-5 flex gap-3">
                <TextField
                  disabled
                  value={montoSinIva}
                  fullWidth
                  id="monto"
                  label="Monto"
                  size="small"
                  onChange={handleMonto}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
                  }}
                />
                <TextField
                  value={montoIva}
                  fullWidth
                  id="iva"
                  disabled
                  label="IVA"
                  size="small"
                  onChange={handleMonto}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
                  }}
                />
                <TextField
                  value={monto}
                  fullWidth
                  id="monto_total"
                  label="Monto Total"
                  size="small"
                  disabled
                  onChange={handleMonto}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
                  }}
                />
              </div>
              <div className="mb-5 grid grid-cols-2 gap-5">
                <div className="">
                  <TextField fullWidth id="nro_oc_mp" label="Número de OC Mercado Público" size="small" value={nroOcMp} onChange={handleOc} disabled={nroOc} />
                </div>
                {!!nroOcMp ? (
                  <TextField
                    fullWidth
                    id="nro_licitacion"
                    label="Número de licitación"
                    size="small"
                    value={nroLicitacion}
                    onChange={(e) => setNroLicitacion(e.target.value)}
                  />
                ) : (
                  <TextField fullWidth id="nro_oc" label="Número de OC privada" size="small" value={nroOc} onChange={handleOcNoMP} disabled={nroOcMp} />
                )}
              </div>
              <div className="flex gap-3">
                <div className="w-full">
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                    <DatePicker
                      label="Fecha OC"
                      sx={{ width: '100%' }}
                      value={date}
                      onChange={handleDateChange}
                      format={'DD/MM/YYYY'}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </LocalizationProvider>
                </div>
                <div className="flex w-full">
                  <label className="mr-2"> Archivo: </label>
                  <Button variant="contained" component="label" size="small">
                    Subir
                    <input
                      hidden
                      accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,image/jpeg"
                      type="file"
                      onChange={handleChangeFile}
                    />
                  </Button>
                </div>
                <div className="flex ml-2 items-end w-[85%]">
                  {file.name && (
                    <div>
                      {file.name} <CheckCircleOutlineIcon sx={{ color: 'green', marginLeft: 2 }} />
                    </div>
                  )}
                </div>
              </div>
              {/* <a
                href={`${enviroments.img_endpoint}${facturaId?.file_oc}`}
                target="_blank"
                rel="noreferrer noopener"
                download="proposed_file_name"
                className="w-[140px] h-[19px] p-3 rounded-[20px] bg-red-500 text-white flex justify-center items-center text-sm mt-5"
              >
                Descargar OC
              </a> */}
            </div>
            <div>
              <div className="mb-5">
                <TextField id="outlined-multiline-static" label="Observación" value={observacion} fullWidth multiline rows={3} onChange={handleObservation} />
              </div>
              <div className="grid grid-cols-3">
                <div>
                  <FormControlLabel value={isParcial} control={<Switch onChange={(e) => setIsParcial(!isParcial)} />} label="Pago Parcial" />
                </div>
                <div>
                  <FormControlLabel value={isMultiple} control={<Switch onChange={(e) => setIsMultiple(!isMultiple)} />} label="Multiple Facturacion" />
                </div>
                <div>
                  <FormControlLabel value={generateOt} control={<Switch onChange={(e) => setGenerateOt(!generateOt)} />} label="No Genera Ot" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-5">
            <Button
              type="submit"
              variant="outlined"
              disabled={
                !(equiposAsignados.length || itemsAsignados.length || repuestosAsignados.length || Object.entries(serviciosAsignados).length) ||
                monto === '' ||
                (nroOcMp && nroLicitacion === '')
              }
            >
              Enviar
            </Button>
          </div>
        </form>
      ) : (
        <div className="w-[100%] h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
