import { Chip, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { deleteOt, getOTByID, setOT } from '../../redux/slices/workOrders';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import dateFormatter from '../../utils/dateFormatter';
import DeleteOtModal from '../modal/DeleteOtModal';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const columns = [
  { title: 'ID', value: 'id' },
  { title: 'Equipo', value: 'equipo' },
  { title: 'Modelo', value: 'modelo' },
  { title: 'Nº serie', value: 'serie' },
  { title: 'Fecha de instalacion', value: 'instalacion' },
  { title: 'Fecha de garantia', value: 'garantia' },
];

const columns_rp = [
  { title: 'Equipo' },
  { title: 'Código Loginteg' },
  { title: 'Nombre' },
  { title: 'N° Modelo' },
  { title: 'Serie' },
  { title: 'Cantidad disponible' },
  { title: 'Cantidad deseada' },
];

const columns_insumos = [
  { title: 'Código Loginteg' },
  { title: 'Nombre' },
  { title: 'Lote' },
  { title: 'Fecha Vencimiento' },
  { title: 'Cantidad' },
  { title: 'Cantidad Solicitada' },
];

const columns_servicios = [{ title: 'Nombre' }, { title: 'Cantidad' }];

export default function OtViewOrders() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ot } = useSelector((state) => state.workOrders);

  useEffect(() => {
    dispatch(getOTByID(id));
    return () => dispatch(setOT({}));
  }, [id]);

  const handleDelete = (id) => {
    dispatch(deleteOt(id));
  };

  return (
    <div>
      <div className="flex mb-5 justify-between">
        <div className="w-[10%] flex items-center">
          <Chip label="Volver" sx={{ minWidth: '100px' }} color="primary" onClick={() => navigate('/ordenes-facturadas')} />
        </div>
        {/* <div className="flex items-center">
          <DeleteOtModal id={id} />
        </div> */}
      </div>

      <section>
        <div className="mb-3 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">INFORMACIÓN DEL CLIENTE - OT {ot.id}</div>
        <div className="md:flex">
          <div className="w-full md:w-[50%] flex items-center md:pr-8  mb-1">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              nombre:
            </label>
            <TextField
              size="small"
              fullWidth
              disabled
              InputProps={{
                style: { fontSize: '14px' },
              }}
              id="filled-disabled-1"
              value={ot.cliente_ot_clienteTocliente?.nombre}
              variant="outlined"
              sx={{
                textTransform: 'capitalize',
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                textAlign: 'center',
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              rut:
            </label>
            <TextField
              size="small"
              fullWidth
              disabled
              InputProps={{
                style: { fontSize: '14px' },
              }}
              id="filled-disabled"
              value={ot.cliente_ot_clienteTocliente?.rut}
              variant="outlined"
              sx={{
                textTransform: 'capitalize',
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
        </div>
        <div className="md:flex">
          <div className="w-full md:w-[50%] flex md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start md:pl-5 rounded mr-1 leading-4">
              comuna:
            </label>
            <TextField
              size="small"
              fullWidth
              disabled
              InputProps={{
                style: { fontSize: '14px' },
              }}
              id="filled-disabled"
              value={ot.cliente_ot_clienteTocliente?.gen_comuna.descripcion}
              variant="outlined"
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              direccion:
            </label>
            <TextField
              size="small"
              disabled
              InputProps={{
                style: { fontSize: '14px' },
              }}
              fullWidth
              id="filled-disabled"
              value={ot.cliente_ot_clienteTocliente?.direccion}
              variant="outlined"
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
        </div>
        <div className="md:flex">
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              contacto:
            </label>
            <TextField
              size="small"
              fullWidth
              disabled
              InputProps={{
                style: { fontSize: '14px' },
              }}
              id="filled-disabled"
              value={'PRINCIPAL'}
              variant="outlined"
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              telefono:
            </label>
            <TextField
              size="small"
              fullWidth
              disabled
              InputProps={{
                style: { fontSize: '14px' },
              }}
              id="filled-disabled"
              value={'S/D'}
              variant="outlined"
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
        </div>
        {/* <div className="md:flex">
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              servicio:
            </label>
            <TextField
              size="small"
              fullWidth
              id="filled-disabled"
              defaultValue={ot?.servicio}
              inputProps={{ style: { fontSize: "14px", textTransform: "capitalize" } }}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#707070",
                },
              }}
              {...register("servicio")}
            />
          </div>
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              celular:
            </label>
            <TextField
              size="small"
              disabled
              InputProps={{
                style: { fontSize: "14px" },
              }}
              fullWidth
              id="filled-disabled"
              value={telefono1}
              variant="outlined"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#707070",
                },
                backgroundColor: "#F1F5F9",
              }}
            />
          </div>
        </div> */}
        <div className="md:flex">
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              garantia:
            </label>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                style: { fontSize: '14px' },
              }}
              id="filled-disabled"
              variant="outlined"
              disabled
              required
              value={ot.garantia === true ? 'EQUIPO CON GARANTÍA' : 'EQUIPO SIN GARANTÍA'}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              email:
            </label>
            <TextField
              size="small"
              fullWidth
              disabled
              InputProps={{
                style: { fontSize: '14px' },
              }}
              id="filled-disabled"
              value={ot?.cliente_ot_clienteTocliente?.mail_contacto}
              variant="outlined"
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
        </div>
        <div className="md:flex">
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              cliente resp:
            </label>
            <TextField
              size="small"
              fullWidth
              required
              disabled
              id="filled-disabled"
              value={ot?.contacto?.nombre || 'S/D'}
              inputProps={{ style: { fontSize: '14px', textTransform: 'capitalize' } }}
              variant="outlined"
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
          <div className="w-full md:w-[50%]  md:pr-8 mb-1 items-center ">
            <div className="flex w-full">
              <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
                email resp:
              </label>
              <TextField
                size="small"
                fullWidth
                required
                disabled
                id="filled-disabled"
                value={ot?.resp_correo}
                variant="outlined"
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#707070',
                  },
                  backgroundColor: '#F1F5F9',
                }}
              />
            </div>
          </div>
        </div>
        <div className="md:flex">
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              Rut cliente:
            </label>
            <TextField
              size="small"
              fullWidth
              id="filled-disabled"
              value={ot?.cliente_ot_clienteTocliente?.rut}
              inputProps={{ style: { fontSize: '14px', textTransform: 'capitalize' } }}
              variant="outlined"
              disabled
              required
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              Medio de Contacto:
            </label>
            <TextField
              size="small"
              fullWidth
              id="filled-disabled"
              value={ot?.medio_contacto}
              InputProps={{
                style: { fontSize: '14px' },
              }}
              disabled
              variant="outlined"
              required
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
        </div>
        <div className="md:flex">
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center ">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              Tipo:
            </label>
            <TextField
              size="small"
              fullWidth
              disabled
              id="filled-disabled"
              value={ot?.gen_tipo_ot?.descripcion}
              variant="outlined"
              required
              InputProps={{
                style: { fontSize: '14px' },
              }}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
          <div className="w-full md:w-[50%] flex  md:pr-8 mb-1 items-center">
            <label className="md:pr-5 min-w-[110px] md:min-w-[150px] max-w-[150px] capitalize  bg-slate-300  h-[37px]  text-[14px] font-semibold  flex items-center justify-start pl-5 rounded mr-1 leading-4">
              costo estimado:
            </label>
            <TextField
              size="small"
              fullWidth
              id="filled-disabled"
              value={ot?.costo}
              InputProps={{
                style: { fontSize: '14px' },
              }}
              variant="outlined"
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#707070',
                },
                backgroundColor: '#F1F5F9',
              }}
            />
          </div>
        </div>
      </section>
      {!ot?.ot_equipo_ot_equipo_otToot?.length ? null : (
        <section className="flex flex-col">
          <div className="mt-3 mb-2 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">EQUIPOS</div>
          <div className="max-md:hidden">
            <TableContainer sx={{ position: 'relative' }} className="mb-5 bg-slate-100 rounded p-5 shadow-md">
              <Table sx={{ minWidth: 650 }} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell sx={{ fontWeight: 500 }} key={column.value} align="center">
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ot?.ot_equipo_ot_equipo_otToot?.map((row) => {
                    return (
                      <TableRow className="capitalize" key={row.id}>
                        <TableCell align="center">{row.id}</TableCell>
                        <TableCell align="center">{capitalizeFirstLetter(row.inv_equipo?.inv_tipo_equipo?.descripcion)}</TableCell>
                        <TableCell align="center">{capitalizeFirstLetter(row.inv_equipo?.modelo)}</TableCell>
                        <TableCell align="center">{capitalizeFirstLetter(row.inv_equipo?.serie)}</TableCell>
                        <TableCell align="center">{dateFormatter(row.inv_equipo?.f_instalacion)}</TableCell>
                        <TableCell align="center">{dateFormatter(row.inv_equipo?.f_garantia)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="w-full h-auto grid grid-cols-1 md:grid-cols-3 md:hidden">
            {ot?.ot_equipo_ot_equipo_otToot?.map((row) => {
              return (
                <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                  <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                    <label className="tracking-wider text-sm font-semibold">ID:</label>
                    <label className="text-sm">{row.id}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Equipo:</label>

                    <label className="text-sm">{capitalizeFirstLetter(row.inv_equipo?.inv_tipo_equipo?.descripcion)}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Modelo:</label>
                    <label className="text-sm">{capitalizeFirstLetter(row.inv_equipo?.modelo)}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Serie:</label>
                    <label className="text-sm">{capitalizeFirstLetter(row.inv_equipo?.serie)}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Fecha de instalación:</label>
                    <label className="text-sm">{dateFormatter(row.inv_equipo?.f_instalacion)}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Fecha de garantia:</label>
                    <label className="text-sm">{dateFormatter(row.inv_equipo?.f_garantia)}</label>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      )}
      {!ot.otFacturacionRepuesto?.length ? null : (
        <section>
          <div className=" mb-2 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">REPUESTOS</div>
          <TableContainer sx={{ position: 'relative' }} className="mb-5 bg-slate-100 rounded p-5 shadow-md max-md:hidden">
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  {columns_rp.map((column, idx) => (
                    <TableCell size="small" sx={{ fontWeight: 500 }} key={idx} align="center">
                      {column.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ot.otFacturacionRepuesto?.map((row) => {
                  return (
                    row.cantidad !== 0 && (
                      <TableRow className="capitalize" key={row.id}>
                        <TableCell size="small" align="center">
                          {row.inv_repuesto.codigo_loginteg || 'S/D'}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.inv_repuesto.descripcion || 'S/D'}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {capitalizeFirstLetter(row.inv_repuesto.modelo) || 'S/D'}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {capitalizeFirstLetter(row.inv_repuesto.serie)}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.inv_repuesto.cantidad}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.cantidad}
                        </TableCell>
                      </TableRow>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="w-full h-auto grid grid-cols-1 md:grid-cols-3 md:hidden">
            {ot.otFacturacionRepuesto?.map((row) => {
              return (
                <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                  <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                    <label className="tracking-wider text-sm font-semibold">Código Loginteg:</label>
                    <label className="text-sm">{row.inv_repuesto.codigo_loginteg}</label>
                  </div>
                  <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                    <label className="tracking-wider text-sm font-semibold">Nombre:</label>
                    <label className="text-sm">{row.inv_repuesto.descripcion}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Modelo:</label>
                    <label className="text-sm">{capitalizeFirstLetter(row.inv_repuesto.modelo)}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Serie:</label>
                    <label className="text-sm">{capitalizeFirstLetter(row.inv_repuesto.serie)}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Cantidad:</label>
                    <label className="text-sm">{row.inv_repuesto.cantidad}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Cantidad Solicitada:</label>
                    <label className="text-sm">{row.cantidad}</label>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      )}
      {!ot.otFacturacionArticulo?.length ? null : (
        <section>
          <div className=" mb-2 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">INSUMOS</div>
          <TableContainer sx={{ position: 'relative' }} className="mb-5 bg-slate-100 rounded p-5 shadow-md max-md:hidden">
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  {columns_insumos.map((column, idx) => (
                    <TableCell size="small" sx={{ fontWeight: 500 }} key={idx} align="center">
                      {column.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ot.otFacturacionArticulo?.map((row) => {
                  return (
                    row.cantidad !== 0 && (
                      <TableRow className="capitalize" key={row.id}>
                        <TableCell size="small" align="center">
                          {row.inv_articulos.nombre || 'S/D'}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.inv_articulos.nombre || 'S/D'}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.inv_articulos.lotes || 'S/D'}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.inv_articulos.fecha_vencimiento ? dayjs(row.inv_articulos.fecha_vencimiento).format('DD/MM/YYYY') : 'S/D'}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.inv_articulos.cantidad}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.cantidad}
                        </TableCell>
                      </TableRow>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="w-full h-auto grid grid-cols-1 md:grid-cols-3 md:hidden">
            {ot.otFacturacionArticulo?.map((row) => {
              return (
                <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                  <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                    <label className="tracking-wider text-sm font-semibold">Código Loginteg:</label>
                    <label className="text-sm">{row.inv_articulos.nombre}</label>
                  </div>
                  <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                    <label className="tracking-wider text-sm font-semibold">Nombre:</label>
                    <label className="text-sm">{row.inv_articulos.nombre}</label>
                  </div>
                  <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                    <label className="tracking-wider text-sm font-semibold">Lote:</label>
                    <label className="text-sm">{row.inv_articulos.lotes}</label>
                  </div>
                  <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                    <label className="tracking-wider text-sm font-semibold">Fecha de vencimiento:</label>
                    <label className="text-sm">
                      {row.inv_articulos.fecha_vencimiento ? dayjs(row.inv_articulos.fecha_vencimiento).format('DD/MM/YYYY') : 'S/D'}
                    </label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Cantidad:</label>
                    <label className="text-sm">{row.inv_articulos.cantidad}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Cantidad Solicitada:</label>
                    <label className="text-sm">{row.cantidad}</label>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      )}
      {!ot.otFacturacionServicio?.length ? null : (
        <section>
          <div className=" mb-2 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">SERVICIOS</div>
          <TableContainer sx={{ position: 'relative' }} className="mb-5 bg-slate-100 rounded p-5 shadow-md max-md:hidden">
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  {columns_servicios.map((column, idx) => (
                    <TableCell size="small" sx={{ fontWeight: 500 }} key={idx} align="center">
                      {column.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ot.otFacturacionServicio?.map((row) => {
                  return (
                    row.cantidad !== 0 && (
                      <TableRow className="capitalize" key={row.id}>
                        <TableCell size="small" align="center">
                          {row.servicios.nombre || 'S/D'}
                        </TableCell>
                        <TableCell size="small" align="center">
                          {row.cantidad || 'S/D'}
                        </TableCell>
                      </TableRow>
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="w-full h-auto grid grid-cols-1 md:grid-cols-3 md:hidden">
            {ot.otFacturacionServicio?.map((row) => {
              return (
                <div key={row.id} className="bg-slate-200 rounded p-2 h-auto mb-3 mr-2">
                  <div className="flex justify-between p-1 mb-2 bg-slate-100 rounded">
                    <label className="tracking-wider text-sm font-semibold">Nombre:</label>
                    <label className="text-sm">{row.servicios.nombre}</label>
                  </div>
                  <div className="flex justify-between p-1 bg-slate-100 rounded mb-2">
                    <label className="tracking-wider text-sm font-semibold">Cantidad:</label>
                    <label className="text-sm">{row.cantidad}</label>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      )}
      <section className="flex flex-col">
        <div className=" mb-5 text-center bg-[#2B2C84] rounded text-[white] pt-2 pb-2">OBSERVACIONES</div>
        <div>
          <TextField
            size="small"
            fullWidth
            id="outlined-multiline-static"
            multiline
            disabled
            rows={4}
            value={ot?.ot_observacion_ot_observacion_otToot?.descripcion || 'Sin Observaciones'}
            sx={{
              '& .MuiOutlinedInput-input.Mui-disabled': {
                WebkitTextFillColor: '#000',
              },
            }}
          />
        </div>
      </section>
    </div>
  );
}
