import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: "10px",
    borderBottom: "3px solid black",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& td, & th": {
    borderBottom: "1px solid #8c8c8c",
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: "none",
}));

export default function FacturaEquipamentTable({ rows, isPrefactura = false, setEquipos }) {
  let cost = 0;
  const calculateTotal = (rows) => {
    if (!rows || rows.length === 0) return 0;
    return rows.reduce((acc, row) => acc + (parseFloat(row.valorFacturado) || 0), 0);
  };

  const handleValorFacturadoChange = (value, index) => {
    setEquipos((prevRows) => {
      const updatedRows = prevRows.map((row, i) => (i === index ? { ...row, valorFacturado: parseFloat(value) || 0 } : row));
      return updatedRows;
    });
  };
  return isPrefactura ? (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <StyledTableCell>DETALLE</StyledTableCell>
          <StyledTableCell align="left">MARCA</StyledTableCell>
          <StyledTableCell align="left">MODELO</StyledTableCell>
          <StyledTableCell align="left">VERSION</StyledTableCell>
          <StyledTableCell align="left">SERIE</StyledTableCell>
          <StyledTableCell align="left">TOTAL</StyledTableCell>
          <StyledTableCell align="left">VALOR A FACTURAR</StyledTableCell>
        </TableHead>
        <TableBody>
          {rows?.length > 0 &&
            rows.map((row, index) => (
              <StyledTableRow key={row?.id} style={{ backgroundColor: row.valorFacturadoTotal !== 0 ? "#d4d2d2" : "white" }}>
                {console.log(row)}
                <StyledTableCell>{row?.inv_equipo?.inv_tipo_equipo?.descripcion}</StyledTableCell>
                <StyledTableCell align="left">{row?.inv_equipo?.inv_marca?.descripcion}</StyledTableCell>
                <StyledTableCell align="left">{row?.inv_equipo?.modelo}</StyledTableCell>
                <StyledTableCell align="left">{row?.inv_equipo?.inv_version?.descripcion}</StyledTableCell>
                <StyledTableCell align="left">{row?.inv_equipo?.serie}</StyledTableCell>
                <StyledTableCell align="left" width={"150px"}>
                  $ {row?.valueCost?.toLocaleString()}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TextField
                    type="number"
                    size="small"
                    disabled={row.valorFacturadoTotal !== 0}
                    // value={row.valorFacturadoTotal || 0}
                    onChange={(e) => handleValorFacturadoChange(e.target.value, index)}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      <div className="flex justify-end w-full pr-10 mt-5 font-bold ">TOTAL: $ {calculateTotal(rows).toLocaleString()}</div>
    </StyledTableContainer>
  ) : (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <StyledTableCell>DETALLE</StyledTableCell>
          <StyledTableCell align="left">MARCA</StyledTableCell>
          <StyledTableCell align="left">MODELO</StyledTableCell>
          <StyledTableCell align="left">VERSION</StyledTableCell>
          <StyledTableCell align="left">SERIE</StyledTableCell>
          <StyledTableCell align="left">TOTAL</StyledTableCell>
        </TableHead>
        <TableBody>
          {rows?.length > 0 &&
            rows.map((row) => {
              cost += row?.valorFacturadoTotal || row.valor;
              return (
                <StyledTableRow key={row?.id}>
                  {console.log(row)}
                  <StyledTableCell>{row?.inv_equipo?.inv_tipo_equipo?.descripcion}</StyledTableCell>
                  <StyledTableCell align="left">{row?.inv_equipo?.inv_marca?.descripcion}</StyledTableCell>
                  <StyledTableCell align="left">{row?.inv_equipo?.modelo}</StyledTableCell>
                  <StyledTableCell align="left">{row?.inv_equipo?.inv_version?.descripcion}</StyledTableCell>
                  <StyledTableCell align="left">{row?.inv_equipo.serie}</StyledTableCell>
                  <StyledTableCell align="left" width={"150px"}>
                    $ {row?.valorFacturadoTotal?.toLocaleString() || row.valor?.toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
      <div className="flex justify-end w-full pr-10 mt-5 font-bold ">TOTAL: $ {cost?.toLocaleString()}</div>
    </StyledTableContainer>
  );
}
