import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import { useEffect, useState } from 'react';
import useDataGrid from '../../../hooks/useDataGrid';
import { deleteArticle, getAllArticles, getAllStorages } from '../../../redux/slices/inventory';
import RepairPartIncorporation from '../../modal/RepairPartIncorporation';
import DataTransitionComponent from './DataTransitionComponent';
import RepairPartTransitionIncorporation from '../../modal/RepairPartTransitionIncorporation';
import { InputAdornment, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
const createArr = [
  { title: 'Codigo Loginteg', value: 'codigo_loginteg' },
  { title: 'Descripcion', value: 'descripcion' },
  { title: 'Marca', value: 'marcaText' },
  { title: 'Modelo', value: 'modelo' },
  { title: 'Cantidad', value: 'cantidad' },
  { title: 'Codigo Proveedor', value: 'serie' },
  { title: 'Compatibilidad', value: 'equipoText' },
  { title: 'Estado', value: 'estadoText' },
  { title: 'Valor', value: 'valueCost' },
  { title: 'Eliminar', value: 'deleteTeam' },
];

const editArr = [
  { title: 'Codigo Loginteg', value: 'codigo_loginteg' },
  { title: 'Descripcion', value: 'descripcion' },
  { title: 'Marca', value: 'inv_marca' },
  { title: 'Modelo', value: 'modelo' },
  { title: 'Cantidad', value: 'cantidad' },
  { title: 'Codigo Proveedor', value: 'serie' },
  { title: 'Compatibilidad', value: 'inv_tipo_equipo' },
  { title: 'Estado', value: 'inv_estado' },
];

export default function RepairPartsTransitAccordion({ repuestos, setRepuestos, edit, setRepuestosCheck, repuestosCheck }) {
  const [id, setId] = useState(1);

  const permitedRenderDataColumn = edit ? editArr : createArr;
  const dispatch = useDispatch();
  const { articles, success } = useSelector((state) => state.inventory);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const handleDelete = (params) => {
    setRepuestos(repuestos.filter((rep) => rep.id !== params));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, 'Articulos', false, setOpen);

  useEffect(() => {
    dispatch(getAllArticles());
    dispatch(getAllStorages());
  }, [dispatch]);

  return (
    <div>
      <Accordion>
        <AccordionSummary sx={{ bgcolor: '#e4dadab8', borderRadius: 1 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <div className="flex justify-center font-bold uppercase tracking-wider text-sm ">Recepción de Repuestos</div>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: '#fdf9f9', borderBottomLeftRadius: 1, borderBottomRightRadius: 1 }}>
          <div>
            <div className={`flex justify-end mb-5 ${edit && 'hidden'}`}>
              <div>
                <RepairPartTransitionIncorporation id={id} setId={setId} repuestos={repuestos} setRepuestos={setRepuestos} />
              </div>
            </div>
            {!edit ? (
              <DataTransitionComponent columns={columns} rows={repuestos} success={success} />
            ) : (
              <>
                <div className="grid grid-cols-11 mb-4">
                  <div className="col-span-2">Código Loginteg</div>
                  <div className="col-span-2">Descripcion</div>
                  <div className="col-span-2">Marca</div>
                  <div>Modelo</div>
                  <div>Codigo Proveedor</div>
                  <div>Compatibilidad</div>
                  <div>Cantidad</div>
                  <div>Completo</div>
                </div>
                {repuestos.map((el) => (
                  <div className="grid grid-cols-11">
                    <div className="col-span-2">{el.codigo_loginteg}</div>
                    <div className="col-span-2">{el.descripcion}</div>
                    <div className="col-span-2">{el.inv_marca}</div>
                    <div>{el.modelo}</div>
                    <div>{el.serie}</div>
                    <div>{el.inv_tipo_equipo}</div>
                    <div>{el.cantidad}</div>
                    <div>
                      <Checkbox
                        checked={el.isComplete || repuestosCheck.some((id) => id === el.id_complete)}
                        disabled={el.isComplete}
                        onChange={(e) => {
                          if (e.target.checked) {
                            if (!repuestosCheck.some((id) => id === el.id_complete)) {
                              setRepuestosCheck((prevState) => [...prevState, el.id_complete]);
                            }
                          } else {
                            setRepuestosCheck((prevState) => prevState.filter((id) => id !== el.id_complete));
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
