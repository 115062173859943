import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import PreFacturaEquipament from "../../modal/PreFacturaEquipament";
import { useEffect } from "react";
import { getAllTeamsBodega } from "../../../redux/slices/inventory";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PreFacturaEquipamentTable from "../../tables/PreFacturaTable/PreFacturaEquipamentTable";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)();

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#ffffff" }} />} {...props} />
))(({ theme }) => ({
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0.5),
  textAlign: "start",
  paddingLeft: "20px",
  paddingRight: "20px",
  fontSize: "15px",
}));

export default function PreFacturaAccordionEquipamnt({ equiposAsignados, setEquiposAsignados, type, setSubTotalEquipos, subTotalEquipos, ganEquipos }) {
  const [expanded, setExpanded] = useState(false);
  const [bodega, setBodega] = useState([]);
  const [search, setSearch] = useState([]);
  const { teamsBodega } = useSelector((state) => state.inventory);
  const dispatch = useDispatch();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(getAllTeamsBodega());
  }, []);

  useEffect(() => {
    setBodega(teamsBodega);
    setSearch(teamsBodega);
  }, [teamsBodega]);

  useEffect(() => {
    let total = 0;
    equiposAsignados.forEach((el) => {
      total += parseInt(el.valueCost);
    });
    setSubTotalEquipos(total);
  }, [equiposAsignados]);

  return (
    <div>
      <div className="mb-5">
        <div className="flex justify-end bg-[#2b2c84]/70 h-[40px]">
          <div className="text-white flex items-center justify-center w-[80%]">EQUIPOS</div>
          <div className="w-[20%] flex justify-end pr-5 items-center">
            <PreFacturaEquipament
              bodega={bodega}
              setBodega={setBodega}
              asignados={equiposAsignados}
              setAsignados={setEquiposAsignados}
              setSubTotalEquipos={setSubTotalEquipos}
              search={search}
              setSearch={setSearch}
            />
          </div>
        </div>
        <div>
          <PreFacturaEquipamentTable rows={equiposAsignados} setAsignados={setEquiposAsignados} />
        </div>

        <div className="flex justify-end mt-5 font-semibold text-xl pr-2">Total: ${subTotalEquipos?.toLocaleString()}</div>
      </div>
    </div>
  );
}
