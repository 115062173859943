import { Chip } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { getFacturacionById, setFacturaId } from '../../../redux/slices/facturacion';
import FacturaEquipamentTable from '../../../components/tables/Facturacion/FacturaEquipamentTable';
import FacturaRepuestoTable from '../../../components/tables/Facturacion/FacturaRepuestoTable';
import FacturaItemTable from '../../../components/tables/Facturacion/FacturaItemTable';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import dayjs from 'dayjs';
import enviroments from '../../../utils/enviroments';
import CircularProgress from '@mui/material/CircularProgress';
import FacturaServicioTable from '../../../components/tables/Facturacion/FacturaServicioTable';
import FacturadoIdEquipamentTable from '../../../components/tables/Facturacion/FacturadoIdEquipamentTable';
import FacturadoIdRepuestoTable from '../../../components/tables/Facturacion/FacturadoIdRepuestoTable';
import FacturadoIdItemTable from '../../../components/tables/Facturacion/FacturadoIdItemTable';

export default function FacturadoId() {
  const [equipos, setEquipos] = useState([]);
  const [items, setItems] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [repuestos, setRepuestos] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { facturaId } = useSelector((state) => state.facturacion);
  useEffect(() => {
    dispatch(getFacturacionById(id));

    return () => dispatch(setFacturaId({}));
  }, []);

  useEffect(() => {
    setEquipos(facturaId.facturacion_equipo);
    setRepuestos(facturaId.facturacion_repuestos);
    setItems(facturaId.facturacion_articulos);
    setServicios(facturaId.facturacion_servicios);
  }, [facturaId]);

  return (
    <div>
      {facturaId && Object.keys(facturaId).length > 0 ? (
        <div>
          <div className="flex mb-10">
            <div className="w-[10%] flex items-center">
              <Chip label="Volver" sx={{ minWidth: '100px' }} color="primary" onClick={() => navigate('/ordenes-facturadas')} />
            </div>
          </div>
          <div className="flex justify-between mb-10">
            <div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold">NOMBRE: </div>
                <div>{facturaId?.cliente_facturacion_clienteTocliente?.nombre?.toUpperCase()}</div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">DIRECCION: </div>
                <div>{facturaId?.cliente_facturacion_clienteTocliente?.direccion?.toUpperCase()}</div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">RUT: </div>
                <div>{facturaId?.cliente_facturacion_clienteTocliente?.rut} </div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">MAIL CONTACTO: </div>
                <div>{facturaId?.cliente_facturacion_clienteTocliente?.mail_contacto?.toUpperCase()} </div>
              </div>
              {facturaId?.nro_oc && (
                <div className="flex">
                  <div className="min-w-[180px] font-semibold ">NRO OC DE MERCADO: </div>
                  <div>{facturaId?.nro_oc ? facturaId?.nro_oc : 'S/D'} </div>
                </div>
              )}
              {facturaId?.nroLicitacion && (
                <div className="flex">
                  <div className="min-w-[180px] font-semibold ">NRO LICITACIÓN: </div>
                  <div>{facturaId?.nroLicitacion ? facturaId?.nroLicitacion : 'S/D'} </div>
                </div>
              )}
              {facturaId?.nro_oc_noMP && (
                <div className="flex">
                  <div className="min-w-[180px] font-semibold ">NRO OC: </div>
                  <div>{facturaId?.nro_oc_noMP ? facturaId?.nro_oc_noMP : 'S/D'} </div>
                </div>
              )}
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">FECHA OC: </div>
                <div>{facturaId?.fecha_oc ? dayjs(facturaId?.fecha_oc).format('DD/MM/YYYY') : 'S/D'} </div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">METODO DE PAGO: </div>
                <div>{facturaId?.pago_parcial ? 'PARCIAL' : 'TOTAL'} </div>
              </div>
              <div className="flex">
                <div className="min-w-[180px] font-semibold ">OC: </div>
                <a
                  href={`${enviroments.img_endpoint}${facturaId?.file_oc}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  download="proposed_file_name"
                  className="w-[140px] h-[19px] p-3 rounded-[20px] bg-red-500 text-white flex justify-center items-center text-sm"
                >
                  Descargar OC
                </a>
              </div>
            </div>
            <div className="flex flex-col pr-10 text-center">
              <div className="text-4xl">ORDEN DE COMPRA</div>
              <div>
                {facturaId.cobranza.map((el, idx) => (
                  <span className="text-2xl">
                    {el.n_factura}
                    {facturaId.cobranza.length - 1 !== idx && ' | '}
                  </span>
                ))}
              </div>

              <div className="mt-1 text-2xl">{dayjs(facturaId?.f_facturacion).add(1, 'day').format('DD/MM/YYYY')}</div>
            </div>
          </div>
          {facturaId.facturacion_equipo.length ? (
            <div className="mb-[40px]">
              <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
                <div className="text-white flex ">EQUIPOS</div>
              </div>
              <FacturadoIdEquipamentTable rows={equipos} />
            </div>
          ) : (
            <></>
          )}
          {facturaId.facturacion_repuestos.length ? (
            <div className="mb-[40px]">
              <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
                <div className="text-white flex ">REPUESTOS</div>
              </div>
              <FacturadoIdRepuestoTable rows={repuestos} />
            </div>
          ) : (
            <></>
          )}
          {facturaId.facturacion_articulos.length ? (
            <div className="mb-[40px]">
              <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
                <div className="text-white flex ">INSUMOS</div>
              </div>
              <FacturadoIdItemTable rows={items} />
            </div>
          ) : (
            <></>
          )}
          {facturaId.facturacion_servicios.length ? (
            <div className="mb-[40px]">
              <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
                <div className="text-white flex ">SERVICIOS</div>
              </div>
              <FacturaServicioTable rows={servicios} />
            </div>
          ) : (
            <></>
          )}
          {facturaId?.observacion ? (
            <div className="mb-4">
              <div className="flex bg-[#2b2c84]/70 h-[40px] items-center justify-center mb-2">
                <div className="text-white font-semibold flex">OBSERVACION</div>
              </div>
              <div>{facturaId?.observacion}</div>
            </div>
          ) : (
            <></>
          )}
          {/* <div className="mb-[50px]">
            <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
              <div className="text-white flex ">EQUIPOS</div>
            </div>
            <FacturaEquipamentTable rows={equipos} />
          </div>
          <div className="mb-[50px]">
            <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
              <div className="text-white flex ">REPUESTOS</div>
            </div>
            <FacturaRepuestoTable rows={repuestos} />
          </div>
          <div className="mb-[50px]">
            <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
              <div className="text-white flex ">INSUMOS</div>
            </div>
            <FacturaItemTable rows={items} />
          </div>
          <div className="mb-[50px]">
            <div className="flex bg-[#2B2C84]/70 h-[40px] items-center justify-center">
              <div className="text-white flex ">SERVICIOS</div>
            </div>
            <FacturaServicioTable rows={servicios} />
          </div> */}
          <div className="flex justify-end items-end">
            {/* <div>
              <div className="flex flex-col mb-5">
                <div className="text-xl mb-2">ADJUNTOS:</div>
                <div className="ml-5 flex mb-2">
                  <AttachFileIcon sx={{ marginRight: 1 }} />
                  <a
                    href={`${enviroments.img_endpoint}${facturaId?.file_xml}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hover:underline"
                  >
                    <p>{facturaId?.file_xml}</p>
                  </a>
                </div>
                <div className="ml-5 mb-2 flex">
                  <AttachFileIcon sx={{ marginRight: 1 }} />
                  <a
                    href={`${enviroments.img_endpoint}${facturaId?.file_pdf}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="hover:underline"
                  >
                    <p>{facturaId?.file_pdf}</p>
                  </a>
                </div>
                {facturaId?.file_oc && (
                  <div className="ml-5 flex">
                    <AttachFileIcon sx={{ marginRight: 1 }} />
                    <a
                      href={`${enviroments.img_endpoint}${facturaId?.file_oc}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="hover:underline"
                    >
                      <p>{facturaId?.file_oc}</p>
                    </a>
                  </div>
                )}
              </div>
            </div> */}
            <div className="flex justify-end mr-20">
              <div className="mr-5 text-2xl font-semibold">MONTO: </div>
              <div className="text-2xl ">$ {(facturaId?.monto - (facturaId?.descuento / 100) * facturaId?.monto).toLocaleString()}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[100%] h-screen flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
