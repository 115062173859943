import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Button, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  getAllBrandsSelect,
  getAllProvidersSelect,
  getAllStatusSelect,
  getAllModelsSelect,
  getAllTeamTypes,
  getPiezaById,
  setPiezaById,
  getAllStorages,
} from "../../redux/slices/inventory";
import Avatar from "@mui/material/Avatar";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axiosInstance from "../../utils/axiosInstance";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "99%", // 0
    sm: "90%", // 600
    md: "60%", // 900
    lg: "60%", // 1200
    xl: "60%", // 1536
  },
  height: "auto",

  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.3)",
    outline: "1px solid slategrey",
  },
};

const requiredFields = ["marca", "modelo", "proveedor", "tipo", "version"];

export default function TeamTransitonModalCreate({ setEquipos, id, setId }) {
  const { brandsSelect, providersSelect: providers, teamTypes, statusSelect, piezasId, bodegas } = useSelector((state) => state.inventory);
  const [accesories, setAccesories] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [equipoId, setEquipoId] = React.useState();
  const [equipoName, setEquipoName] = React.useState();
  const [modeloName, setModeloName] = React.useState();
  const [modeloId, setModeloId] = React.useState();
  const [versionId, setVersionId] = React.useState();
  const [url, setUrl] = React.useState();
  const [image, setImage] = React.useState();
  const [showRoom, setShowRoom] = React.useState(false);
  const [versionsByModel, setVersionsByModel] = React.useState([]);
  const [handledVersion, setHandledVersion] = React.useState(false);
  const [models, setModales] = React.useState([]);
  const [elements, setElements] = React.useState([]);
  const [datosPiezas, setDatosPiezas] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [values, setValues] = React.useState({});
  const [done, setDone] = React.useState(false);
  const [errors, setErrors] = React.useState({
    marca: false,
    cantidad: false,
    // descripcion: false,
    estado: false,
    modelo: false,
    proveedor: false,
    serie: false,
    tipo: false,
    version: false,
  });

  const uploadImage = (e) => {
    setUrl(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const handleChangeTeam = (team) => {
    setModales(team.inv_modelo);
    setHandledVersion({ models: true });
    setVersionsByModel([]);
    setEquipoId(team.id);
    setEquipoName(team.descripcion);
  };

  const dispatch = useDispatch();
  const { register, handleSubmit, reset, getValues, watch, setValue } = useForm({
    mode: "onSubmit",
    defaultValues: {
      marca: "",
      cantidad: "",
      descripcion: "",
      estado: "",
      modelo: "",
      proveedor: "",
      serie: "",
      tipo: "",
      version: "",
    },
  });

  const handleCantidadChange = (index, value) => {
    const pieza = piezasId[index];
    const nuevaLista = [...datosPiezas];
    nuevaLista[index] = {
      elementoId: pieza.id,
      cantidad: pieza.cantidad,
      actual: value,
    };

    if (value <= pieza.cantidad && value >= 0) {
      setValues({ ...values, [index]: value });
      setDatosPiezas(nuevaLista);
    }
  };

  const handleFindElements = async () => {
    let response = await dispatch(getPiezaById({ equipoId, modeloId, versionId }));
    let defaultValuesPiezas = [];
    response.forEach((el) => {
      let elementToPush = {
        elementoId: el.id,
        cantidad: el.cantidad,
        actual: `${el.cantidad}`,
      };
      defaultValuesPiezas.push(elementToPush);
    });
    setDatosPiezas(defaultValuesPiezas);
  };

  const handleClose = (e) => {
    reset();
    setErrors({
      marca: false,
      cantidad: false,
      // descripcion: false,
      estado: false,
      modelo: false,
      proveedor: false,
      serie: false,
      tipo: false,
      version: false,
    });
    setOpen(false);
    setUrl();
    setVersionsByModel([]);
    setHandledVersion(false);
    setModales([]);
    setElements([]);
    setImage();
    setEquipoId();
    setModeloId();
    setVersionId();
    setAccesories([]);
    setDatosPiezas([]);
    setValues({});
    dispatch(setPiezaById([]));
  };

  const handleAccesory = () => {
    setAccesories([...accesories, { id: accesories.length, descripciones: "" }]);
  };

  const newTeam = async (value) => {
    const isRequired = requiredFields.map((field) => {
      if (value[field] === "") {
        setErrors((prevState) => {
          return {
            ...prevState,
            [field]: true,
          };
        });

        return true;
      } else {
        setErrors((prevState) => {
          return {
            ...prevState,
            [field]: false,
          };
        });

        return false;
      }
    });

    if (isRequired.find((field) => field)) return;

    if (Boolean(image)) {
      const formData = new FormData();
      formData.append("image", image);
      const res = await axiosInstance.post(`/images`, formData);
      value.imagen = res.data;
    }

    const equipos = [];
    value.tipoText = equipoName;
    value.modeloText = modeloName;
    value.versionText = versionsByModel.find((el) => el.id === value.version).descripcion;
    value.marcaText = brandsSelect.find((el) => el.id === value.marca).descripcion;
    value.elementos = datosPiezas;
    // value.fecha_ing_bodega = fecha;

    const quantity = value?.cantidad || 1;

    for (let i = 0; i < quantity; i++) {
      const timestamp = Date.now().toString();
      const random = Math.random().toString(36).substring(2, 8);

      const equipo = {
        ...value,
        id: timestamp + random,
        accesories: accesories,
      };

      equipos.push(equipo);
    }

    setEquipos((prevState) => [...prevState, ...equipos]);
    handleClose();
  };

  const handleVersionByModel = (model) => {
    setModeloName(model.nombre);
    setHandledVersion({ ...handledVersion, versions: true });
    setElements(model.inv_pieza_equipos_inv_pieza_equipos_inv_modeloToinv_modelo);
    setVersionsByModel(model.inv_version);
    setModeloId(model.id);
  };

  const handleVersion = (version) => {
    setVersionId(version.id);
  };

  useEffect(() => {
    const getAll = async () => {
      await dispatch(getAllBrandsSelect());
      await dispatch(getAllProvidersSelect());
      await dispatch(getAllTeamTypes());
      await dispatch(getAllModelsSelect());
      await dispatch(getAllStatusSelect());
      await dispatch(getAllStorages());
      setDone((prev) => !prev);
    };

    getAll();
  }, [update, dispatch]);

  useEffect(() => {
    if (equipoId) {
      const team = teamTypes.find((el) => el.id === equipoId);

      if (team?.inv_modelo?.length) {
        setModales(team.inv_modelo);
        setVersionsByModel([]);
        setHandledVersion({ models: true });
      }
    }
  }, [done]);

  useEffect(() => {
    if (modeloId) {
      const model = models.find((el) => el.id === modeloId);
      if (model?.inv_version?.length) {
        setVersionsByModel(model.inv_version);
        setHandledVersion({ ...handledVersion, versions: true });
      }
    }
  }, [models]);

  return (
    <div>
      <Button onClick={() => setOpen(true)} variant="contained" sx={{ textTransform: "none" }}>
        Crear nuevo Equipo
      </Button>
      <Modal
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
          "& .MuiModal-backdrop": { zIndex: -9999 },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(newTeam)}>
              <div className="flex flex-col sm:flex-row">
                <div className="w-[100%] sm:w-[60%] mr-5">
                  <div className="flex flex-col items-center sm:flex-row w-full">
                    <div className="mr-5 relative">
                      <Avatar
                        variant="rounded"
                        src={url && url}
                        onClick={() => setShowRoom(!showRoom)}
                        sx={{
                          height: 134,
                          width: 200,
                          marginBottom: 2,
                          transition: "all 0.3s ease-in-out",
                          cursor: "pointer",
                          "&:hover": { padding: 0.3 },
                        }}
                      />
                      <div className="absolute bottom-4  w-full">
                        <label htmlFor="file-upload" className="text-sm w-full flex justify-center font-bold items-center p-[6px] bg-white/60 cursor-pointer">
                          <FileUploadIcon sx={{ mr: 0.5, fontSize: 16 }} />
                          Subir Imagen
                        </label>
                        <input id="file-upload" type="file" accept="image/*" onChange={uploadImage} />
                      </div>
                    </div>
                    <div className="w-full">
                      <h1 className="font-semibold w-full text-gray-500 rounded border-[#c5c4c8] border-[1px] uppercase h-min p-[15px] mb-5">
                        Registrar un nuevo Equipo
                      </h1>
                      <Button onClick={() => setUpdate((prev) => !prev)} variant="outlined">
                        Actualizar campos
                      </Button>
                    </div>
                  </div>
                  <div className="flex">
                    <TextField
                      fullWidth
                      id={"marca"}
                      sx={{ mr: 2 }}
                      label={"Marca"}
                      type={"text"}
                      {...register("marca")}
                      error={Boolean(errors["marca"])}
                      helperText={Boolean(errors["marca"]) ? "El campo es requerido" : " "}
                      select
                    >
                      {brandsSelect.length &&
                        brandsSelect
                          ?.filter((brand) => brand.equipo)
                          .map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.descripcion}
                            </MenuItem>
                          ))}
                    </TextField>
                    <TextField
                      fullWidth
                      id={"equipo"}
                      label={"Tipo de equipo"}
                      type={"text"}
                      {...register("tipo")}
                      helperText={Boolean(errors["tipo"]) ? "El campo es requerido" : " "}
                      error={Boolean(errors["tipo"])}
                      select
                      defaultValue={""}
                    >
                      {teamTypes?.map((team) => (
                        <MenuItem key={team.id} value={team.id} onClick={() => handleChangeTeam(team)}>
                          {team.descripcion}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="flex">
                    <TextField
                      fullWidth
                      id={"modelo"}
                      label={"Modelo"}
                      sx={{ mr: 2 }}
                      type={"text"}
                      defaultValue={""}
                      {...register("modelo")}
                      helperText={Boolean(errors["modelo"]) ? "El campo es requerido" : " "}
                      error={Boolean(errors["modelo"])}
                      select
                    >
                      {models?.length ? (
                        models?.map((model) => (
                          <MenuItem key={model.id} value={model.id} onClick={() => handleVersionByModel(model)}>
                            {model.nombre}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={1} disabled>
                          {!handledVersion.models ? "Selecciona un equipo" : "No hay modelos para este equipo"}
                        </MenuItem>
                      )}
                    </TextField>
                    <TextField
                      fullWidth
                      id={"version"}
                      label={"Version"}
                      type={"text"}
                      {...register("version")}
                      helperText={Boolean(errors["version"]) ? "El campo es requerido" : " "}
                      error={Boolean(errors["version"])}
                      select
                      defaultValue={""}
                    >
                      {versionsByModel.length ? (
                        versionsByModel?.map((option) => (
                          <MenuItem key={option.id} value={option.id || ""} onClick={() => handleVersion(option)}>
                            {option.descripcion}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={1} disabled>
                          {!handledVersion.versions ? "Selecciona un modelo" : "No hay versiones para este modelo"}
                        </MenuItem>
                      )}
                    </TextField>
                  </div>
                  <div className="flex">
                    <TextField fullWidth id={"serie"} label={"Serie"} type={"text"} sx={{ mr: 2 }} defaultValue={""} {...register("serie")} helperText={" "} />
                    <TextField
                      fullWidth
                      id={"proveedor"}
                      label={"Proveedor"}
                      type={"text"}
                      {...register("proveedor")}
                      error={Boolean(errors["proveedor"])}
                      helperText={Boolean(errors["proveedor"]) ? "El campo es requerido" : " "}
                      select
                      defaultValue={""}
                    >
                      {providers.length &&
                        providers?.map((option) => (
                          <MenuItem key={option.id} value={option.id || ""}>
                            {option.descripcion}
                          </MenuItem>
                        ))}
                    </TextField>
                  </div>

                  <div className="mt-[-9px] mb-2 flex justify-between gap-3">
                    {/* <div className="w-full">
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            sx={{ width: "100%" }}
                            onChange={handleFechaChange}
                            format={"DD/MM/YYYY"}
                            defaultValue={dayjs()}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div> */}
                    <div className="mt-2 w-full flex">
                      <TextField
                        sx={{ mr: 2 }}
                        fullWidth
                        id={"valueCost"}
                        label={"Valor"}
                        type={"text"}
                        {...register("valueCost")}
                        error={Boolean(errors["valueCost"])}
                        helperText={Boolean(errors["valueCost"]) ? "El campo es requerido" : " "}
                        defaultValue={""}
                      />
                      <TextField
                        fullWidth
                        id={"descripcion"}
                        label={"Descripción"}
                        type={"text"}
                        {...register("descripcion")}
                        // error={Boolean(errors["descripcion"])}
                        // helperText={Boolean(errors["descripcion"]) ? "El campo es requerido" : " "}
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-[100%] sm:w-[40%]">
                  <div className=" rounded border-[#c8c4c4] border-[1px] h-full max-h-[470px] flex flex-col px-5 ">
                    <div className="h-[15%] flex  items-center gap-5">
                      <div className="text-lg font-semibold flex justify-center">ELEMENTOS</div>
                      <div className="flex justify-center">
                        <Button
                          sx={{ height: 40 }}
                          variant="contained"
                          onClick={handleFindElements}
                          disabled={modeloId && equipoId && versionId ? false : true}
                        >
                          Buscar
                        </Button>
                      </div>
                    </div>
                    {piezasId.length > 0 ? (
                      <div className="overflow-y-auto max-h-[85%]">
                        <div className="flex w-full bg-slate-300 mb-3">
                          <div className="w-[50%] border-left border-r-2 pl-5">ELEMENTO</div>
                          <div className="w-[25%] flex justify-center border-r-2">Cantidad</div>
                          <div className="w-[25%] flex justify-center">Actual</div>
                        </div>
                        {piezasId.map((pieza, index) => (
                          <div key={index} className={`w-full flex ${index % 2 === 0 ? "bg-slate-100" : "bg-slate-200"} pt-2 pb-2`}>
                            <div className="w-[50%] pl-5 flex items-center">{pieza.inv_tipo_item_equipo.nombre}</div>
                            <div className="w-[25%] flex justify-center items-center">{pieza.cantidad}</div>
                            <input
                              type="number"
                              className="w-[22%] flex justify-center rounded border-[1px] border-blue-200 px-2"
                              size="small"
                              required
                              min={0}
                              max={pieza.cantidad}
                              defaultValue={pieza.cantidad}
                              value={values[index]}
                              onChange={(e) => handleCantidadChange(index, e.target.value)}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex justify-center mt-5 mb-5">No hay elementos asociados</div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <Modal
                  open={showRoom}
                  onClose={() => setShowRoom(false)}
                  aria-labelledby="child-modal-title"
                  slotProps={{
                    backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(8px)" } },
                  }}
                  aria-describedby="child-modal-description"
                >
                  <Box sx={{ ...style, width: "auto", padding: 1 }}>
                    <img src={url && url} />
                  </Box>
                </Modal>
              </div>
              <div className="flex flex-col-reverse sm:flex-row mt-3 relative w-[100%] sm:w-[70%] gap-3">
                <Button type="submit" sx={{ mr: 1, width: "200px" }} variant="contained">
                  Enviar
                </Button>
                <Button sx={{ mr: 1, width: "200px" }} variant="contained" onClick={handleClose}>
                  Cerrar
                </Button>
                <div className="w-[100%] sm:w-[50%] mr-3">
                  <TextField label="Cantidad de equipos" {...register("cantidad", { pattern: /^[0-9]*$/ })} fullWidth size="small" />
                </div>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
