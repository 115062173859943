import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Button, MenuItem, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getAllArticlesMold } from "../../redux/slices/inventory";
import textFieldSelectFormat from "../../utils/textFieldSelectFormat";
import dayjs from "dayjs";
import { values } from "pdf-lib";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "99%", // 0
    sm: "80%", // 600
    md: "40%", // 900
    lg: "40%", // 1200
    xl: "40%", // 1536
  },
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function ArticleTransitionCreate({ setArticulos, id, setId, articulos }) {
  const [open, setOpen] = React.useState(false);
  const { articlesMold, statusSelect, bodegas } = useSelector((state) => state.inventory);
  const [selected, setSelected] = React.useState(null);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm();

  const handleClose = () => {
    setOpen(false);
    reset();
    setSelected(null);
  };
  const newArticle = async (value) => {
    const selectedValue = articlesMold.find((obj) => obj.id === selected.id);

    value.nombre = selectedValue.nombre;
    value.codigo_loginteg = selectedValue.codigo_loginteg;
    value.moldId = selected.id;
    value.cantidad = parseInt(value.cantidad);
    value.estado = 6;

    const existingIndex = articulos.findIndex(
      (item) =>
        item.moldId === selected.id &&
        item.lotes === value.lotes &&
        dayjs(item.fecha_vencimiento).format("DD/MM/YYYY") === dayjs(value.fecha_vencimiento).format("DD/MM/YYYY")
    );

    if (existingIndex !== -1) {
      const updatedArticulos = [...articulos];
      updatedArticulos[existingIndex].cantidad += parseInt(value.cantidad);
      setArticulos(updatedArticulos);
    } else {
      setArticulos((prevState) => [...prevState, { ...value, id: id }]);
    }

    setId((id += 1));
    reset();
    setOpen(false);
    setSelected(null);
  };

  React.useEffect(() => {
    dispatch(getAllArticlesMold());
  }, [dispatch]);

  React.useEffect(() => {
    if (selected?.id) {
      const finded = articlesMold?.find((el) => el?.id === selected?.id);
      if (finded) {
        setValue("codigo_proveedor", finded?.codigo_proveedor || "Sin código");
      }
    }
  }, [selected]);

  return (
    <div>
      <Button onClick={() => setOpen(true)} variant="contained" sx={{ textTransform: "none" }}>
        Registrar nuevo insumo
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(newArticle)}>
              <div className="flex">
                <div className="w-full mr-5">
                  <div className="mb-5 w-full flex gap-4">
                    <div className="w-full">
                      <Autocomplete
                        value={selected || { id: 0, label: "" }}
                        onChange={(option, value) => setSelected(value)}
                        options={textFieldSelectFormat(articlesMold)}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Elegir Insumo"
                            size="small"
                            fullWidth
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                  },
                                },
                              },
                            }}
                            sx={{
                              "& .MuiFormHelperText-root": {
                                marginLeft: 0,
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="w-full">
                      <Controller
                        id="codigo_proveedor"
                        name="codigo_proveedor"
                        rules={{ required: true }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            size="small"
                            fullWidth
                            id={"codigo_proveedor"}
                            label={"Código Proveedor"}
                            disabled
                            value={value || ""}
                            onChange={onChange}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#707070",
                              },
                            }}
                            type={"text"}
                            error={Boolean(errors["codigo_proveedor"])}
                            helperText={Boolean(errors["codigo_proveedor"]) && "El campo es requerido"}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className={`flex gap-4 mb-5`}>
                    <Controller
                      id="lotes"
                      name="lotes"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          size="small"
                          fullWidth
                          id={"lotes"}
                          label={"Lotes"}
                          value={value || ""}
                          onChange={onChange}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#707070",
                            },
                          }}
                          type={"text"}
                          error={Boolean(errors["lotes"])}
                          helperText={Boolean(errors["lotes"]) && "El campo es requerido"}
                        />
                      )}
                    />
                    <Controller
                      id="cantidad"
                      name="cantidad"
                      rules={{ required: true, pattern: /^[0-9]*$/ }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          size="small"
                          fullWidth
                          id={"cantidad"}
                          label={"Cantidad"}
                          value={value || ""}
                          onChange={onChange}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#707070",
                            },
                          }}
                          type={"text"}
                          error={Boolean(errors["cantidad"])}
                          helperText={Boolean(errors["cantidad"]) && "El campo es requerido"}
                        />
                      )}
                    />
                  </div>
                  <div className="flex gap-5 min-h-[58px]">
                    <div className="w-full">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          id={"fecha_vencimiento"}
                          control={control}
                          rules={{ required: true }}
                          name={"fecha_vencimiento"}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              format="DD/MM/YYYY"
                              label="Fecha de vencimiento"
                              slotProps={{
                                textField: {
                                  size: "small",
                                  fullWidth: true,
                                  error: !!errors["fecha_vencimiento"],
                                },
                              }}
                              onChange={onChange}
                              value={value || null}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="w-full">
                      <Controller
                        id="valueCost"
                        name="valueCost"
                        rules={{ required: true, pattern: /^[0-9]*$/ }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            id={"valueCost"}
                            label={"Valor"}
                            sx={{ mr: 2 }}
                            type={"text"}
                            error={Boolean(errors["valueCost"])}
                            size="small"
                            value={value || ""}
                            onChange={onChange}
                            helperText={
                              (Boolean(errors?.valueCost?.type === "required") && "El campo es requerido") ||
                              (Boolean(errors?.valueCost?.type === "pattern") && "El campo solo admite numeros")
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center w-full justify-between">
                <Button type="submit" variant="contained" sx={{ mt: 2, width: "100%" }}>
                  Enviar formulario
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
