import DataGridComponent from "../../components/tables/DataGrid";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import { dataColumns } from "../../hooks/fundAccounting";
import useDataGrid from "../../hooks/useDataGrid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteFundAccounting, getAllFundAccountings } from "../../redux/slices/fundAccounting";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, InputAdornment, MenuItem, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { getCurrencies } from "../../redux/slices/moneda";
import { getOtSelect, setOT } from "../../redux/slices/workOrders";
import { getBanks } from "../../redux/slices/banco";
import { getAllProyectsBilling } from "../../redux/slices/facturacion";
import { createAnticipo, getExpenseClaimCategories } from "../../redux/slices/fundAccounting";
import { getAllUsers } from "../../redux/slices/users";
import CustomSelectMultiple from "../../components/CustomSelectMultiple";
import thousandSeparator from "../../utils/thousandSeparator";
import removeNonNumeric from "../../utils/removeNaN";

export default function RDFList() {
  const dispatch = useDispatch();
  const { fundAccounting } = useSelector((state) => state.fundAccounting);
  const { currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDelete = async (param) => {
    const response = await dispatch(deleteFundAccounting(param.id));
    return response;
  };

  useEffect(() => {
    if (currentUser.id) {
      dispatch(getAllFundAccountings(currentUser?.id));
    }
  }, [dispatch, currentUser.id]);

  const [column] = useDataGrid(dataColumns, handleDelete, "", false);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Rendicion</h1>
          <BreadCrumb origin={"Rendicion de gastos"} current={"Rendicion"} />
        </div>
        <div className="flex gap-2">
          <Button onClick={() => navigate("/rendicion-de-fondos/create", { state: { from: location } })} variant="contained">
            Crear Rendición
          </Button>

          <BasicModal />
        </div>
      </div>
      <DataGridComponent
        columns={column}
        rows={[...fundAccounting].sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        })}
        success={true}
      />
    </div>
  );
}

const BasicModal = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { currentUser } = useSelector((state) => state.auth);
  const { select } = useSelector((state) => state.workOrders);
  const { currency } = useSelector((state) => state.currency);
  const { banks } = useSelector((state) => state.banks);
  const { data: userList } = useSelector((state) => state.users);
  const [successData, setSuccessData] = useState(null);
  const [ots, setOts] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getCurrencies());
    dispatch(getOtSelect());
    dispatch(getBanks());
    dispatch(getAllProyectsBilling());
    dispatch(getExpenseClaimCategories());
  }, [dispatch]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const submit = async (v) => {
    v.ots = ots;
    v.anticipo = parseInt(v.anticipo.replaceAll(".", ""));
    const data = await dispatch(createAnticipo(v));
    if (data.status === 201) {
      setSuccessData(data.data);
      setOts([]);
      reset();
    }
  };

  useEffect(() => {
    if (userList.length && currentUser) {
      const finded = userList.find((el) => el.id === currentUser.id);
      if (finded) {
        setValue("nombre_completo", finded.id);
        setValue("banco_id", finded.bancosId);
        setValue("cuenta_nro", finded.account);
      }
    }
  }, [userList, currentUser]);

  const isNum = (val) => {
    if (/^(0|[1-9][0-9]*)$/.test(val)) return true;
    return "El campo es numerico";
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained">
        Crear Fondo por Rendir
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="py-5 max-w-[1442px] mx-auto flex justify-center flex-col w-full">
            <Divider textAlign="center" sx={{ mb: 4 }}>
              <Chip sx={{ minWidth: "140px" }} color="primary" label="INFORMACIÓN DE FONDO POR RENDIR" />
            </Divider>
            <div className="gap-5 flex mb-7">
              <Controller
                id="anticipo"
                name="anticipo"
                rules={{ required: "* El campo es requerido" }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    id={"anticipo"}
                    label={"Valor Anticipo"}
                    value={value || ""}
                    onChange={(e) => onChange(thousandSeparator(removeNonNumeric(e.target.value)))}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#707070",
                      },
                    }}
                    type={"text"}
                    error={errors?.anticipo?.message}
                    helperText={errors?.anticipo?.message}
                  />
                )}
              />
              <Controller
                id="nombre_completo"
                name="nombre_completo"
                rules={{ required: "* El campo es requerido" }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    id={"nombre_completo"}
                    label={"Nombre completo del receptor"}
                    value={value || ""}
                    onChange={onChange}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#707070",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      },
                    }}
                    type={"text"}
                    error={errors?.nombre_completo?.message}
                    helperText={errors?.nombre_completo?.message}
                    select
                  >
                    {userList.length &&
                      userList?.map((option) => (
                        <MenuItem key={option.id} value={option?.id || ""}>
                          {option.nombre}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
            </div>
            <Divider textAlign="center" sx={{ mb: 4 }}>
              <Chip sx={{ minWidth: "140px" }} color="primary" label="INFORMACIÓN REQUERIDA PARA RENDICIÓN" />
            </Divider>
            <div className="gap-5 flex mb-7 w-full">
              <CustomSelectMultiple
                id="ot_id"
                name="ot_id"
                width={"170px"}
                label={"Orden de trabajo"}
                value={ots}
                disabled={false}
                size="standard"
                onChange={(e) => setOts(e)}
                options={[{ id: -1, nombre: "Sin orden de trabajo" }].concat(
                  select.map((el) => ({
                    id: el.id,
                    nombre: `OT ${el.id} - ${el.cliente_ot_clienteTocliente.nombre}`,
                  }))
                )}
              />
            </div>
            <div className="gap-5 flex mb-7">
              <Controller
                id={"fecha"}
                control={control}
                name={"fecha"}
                rules={{ required: true }}
                defaultValue={dayjs()}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"} localeText={esES}>
                    <DatePicker value={value || ""} onChange={(date) => onChange(date)} slotProps={{ textField: { fullWidth: true } }} format="DD/MM/YYYY" />
                  </LocalizationProvider>
                )}
              />
              <Controller
                id="banco_id"
                name="banco_id"
                rules={{ required: "* El campo es requerido" }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    id={"banco_id"}
                    label={"Banco"}
                    value={value || ""}
                    onChange={onChange}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#707070",
                      },
                    }}
                    type={"text"}
                    error={errors?.banco_id?.message}
                    helperText={errors?.banco_id?.message}
                    select
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      },
                    }}
                  >
                    {banks.length &&
                      [{ id: -1, nombre: "EFECTIVO" }].concat(banks)?.map((option) => (
                        <MenuItem key={option.id} value={option?.id || ""}>
                          {option.nombre}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
            </div>
            <div className="gap-5 flex mb-7">
              <Controller
                id="cuenta_nro"
                name="cuenta_nro"
                rules={{ required: "* El campo es requerido", validate: isNum }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start">N.° </InputAdornment>,
                    }}
                    id={"cuenta_nro"}
                    label={"Cuenta"}
                    value={value || ""}
                    onChange={onChange}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#707070",
                      },
                    }}
                    type={"text"}
                    error={errors?.cuenta_nro?.message}
                    helperText={errors?.cuenta_nro?.message}
                  />
                )}
              />
              <Controller
                id="moneda_id"
                name="moneda_id"
                rules={{ required: "* El campo es requerido" }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    id={"moneda_id"}
                    label={"Tipo de Moneda"}
                    value={value || ""}
                    onChange={onChange}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#707070",
                      },
                    }}
                    type={"text"}
                    error={errors?.moneda_id?.message}
                    helperText={errors?.moneda_id?.message}
                    select
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      },
                    }}
                  >
                    {currency.length &&
                      currency?.map((option) => (
                        <MenuItem key={option.id} value={option?.id || ""}>
                          {option.nombre}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              />
            </div>

            <div className="w-full flex flex-col items-center mt-5">
              <Button sx={{ minWidth: 350, mb: 1 }} onClick={handleSubmit(submit)} variant="contained">
                Registar fondo por rendir
              </Button>
              <span className="text-neutral-500">El valor de anticipo se le restara a la devolución</span>
            </div>
            <SimpleDialog open={!!successData} setOpen={setSuccessData} data={successData} closeModal={setOpen} />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

function SimpleDialog({ open, setOpen, data, closeModal }) {
  const handleClose = () => {
    setOpen(null);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"¡Anticipo y rendición creados con éxito!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Nos complace informarte que el proceso de creación del anticipo ha sido ejecutado de manera exitosa y ya puedes visualizarlo desde el listado
            correspondiente. <br /> Además, queremos destacar que la rendición <b className="text-green-500">(N°{data?.rendicion_gastos?.id})</b> ha sido
            habilitada para que puedas registrar los gastos asociados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              closeModal(false);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
