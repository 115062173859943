import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: "10px",

    borderBottom: "3px solid black",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& td, & th": {
    borderBottom: "1px solid #8c8c8c",
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: "none",
}));
export default function FacturadoIdTable({ rows }) {
  console.log(rows);
  let cost = 0;
  return (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <StyledTableCell>DETALLE</StyledTableCell>
          <StyledTableCell align="left">MARCA</StyledTableCell>
          <StyledTableCell align="left">MODELO</StyledTableCell>
          <StyledTableCell align="left">VERSION</StyledTableCell>
          <StyledTableCell align="left">SERIE</StyledTableCell>
          <StyledTableCell align="left">TOTAL</StyledTableCell>
        </TableHead>
        <TableBody>
          {rows?.length > 0 &&
            rows.map((row) => {
              cost += row?.valueCost;
              return (
                <StyledTableRow key={row?.id}>
                  <StyledTableCell>{row?.inv_equipo?.inv_tipo_equipo?.descripcion}</StyledTableCell>
                  <StyledTableCell align="left">{row?.inv_equipo?.inv_marca?.descripcion}</StyledTableCell>
                  <StyledTableCell align="left">{row?.inv_equipo?.modelo}</StyledTableCell>
                  <StyledTableCell align="left">{row?.inv_equipo?.inv_version?.descripcion}</StyledTableCell>
                  <StyledTableCell align="left">{row?.inv_equipo.serie}</StyledTableCell>
                  <StyledTableCell align="left" width={"150px"}>
                    $ {row?.valueCost?.toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
      <div className="flex justify-end w-full pr-10 mt-5 font-bold ">TOTAL: $ {cost?.toLocaleString()}</div>
    </StyledTableContainer>
  );
}
