import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: "10px",
    borderBottom: "3px solid black",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& td, & th": {
    borderBottom: "1px solid #8c8c8c",
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: "none",
}));

export default function FacturaItemTable({ rows = [], setItems, isPrefactura = false }) {
  let cost = 0;
  const calculateTotal = (rows) => {
    if (!Array.isArray(rows) || rows.length === 0) return 0;
    return rows.reduce((acc, row) => acc + (parseFloat(row.valorFacturado) || 0), 0);
  };

  const handleCantidadFacturarChange = (value, index, row) => {
    if (parseInt(row.cantidad) < parseInt(value) + parseInt(row.cantidadFacturada)) return;
    else {
      setItems((prevRows) => {
        const updatedRows = prevRows.map((row, i) =>
          i === index
            ? {
                ...row,
                cantidadFacturar: parseInt(value) || 0,
              }
            : row
        );
        return updatedRows;
      });
    }
  };

  const handleValorFacturadoChange = (value, index) => {
    setItems((prevRows) => {
      const updatedRows = prevRows.map((row, i) =>
        i === index
          ? {
              ...row,
              valorFacturado: parseFloat(value) || 0,
            }
          : row
      );
      return updatedRows;
    });
  };

  return isPrefactura ? (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>DETALLE</StyledTableCell>
            <StyledTableCell align="left">LOTE</StyledTableCell>
            <StyledTableCell align="left">FECHA VENCIMIENTO</StyledTableCell>
            <StyledTableCell align="left">TOTAL</StyledTableCell>
            <StyledTableCell align="left">CANTIDAD</StyledTableCell>
            <StyledTableCell align="left">CANTIDAD FACTURADA</StyledTableCell>
            <StyledTableCell align="left">CANTIDAD A FACTURAR</StyledTableCell>
            <StyledTableCell align="left">VALOR A FACTURAR</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={row.id} style={{ backgroundColor: row.cantidadFacturada === row.cantidad ? "#d4d2d2" : "white" }}>
              <StyledTableCell component="th" scope="row">
                {row.inv_articulos?.nombre}
              </StyledTableCell>
              <StyledTableCell align="left">{row.inv_articulos.lotes || "S/D"}</StyledTableCell>
              <StyledTableCell align="left">
                {row.inv_articulos.fecha_vencimiento ? dayjs(row.inv_articulos.fecha_vencimiento).format("DD/MM/YYYY") : "S/D"}
              </StyledTableCell>
              <StyledTableCell width={"150px"} align="left">
                $ {row?.valueCost}
              </StyledTableCell>
              <StyledTableCell align="left">{row.cantidad}</StyledTableCell>
              <StyledTableCell align="left">{row.cantidadFacturada || 0}</StyledTableCell>
              <StyledTableCell align="left">
                <TextField
                  type="number"
                  size="small"
                  value={row.cantidadFacturar || 0}
                  onChange={(e) => handleCantidadFacturarChange(e.target.value, index, row)}
                  disabled={row.cantidadFacturada === row.cantidad}
                />
              </StyledTableCell>
              <StyledTableCell align="left">
                <TextField
                  type="number"
                  size="small"
                  value={row.valorFacturado || 0}
                  onChange={(e) => handleValorFacturadoChange(e.target.value, index)}
                  disabled={row.cantidadFacturada === row.cantidad}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-end w-full pr-10 mt-5 font-bold ">TOTAL: $ {calculateTotal(rows).toLocaleString()}</div>
    </StyledTableContainer>
  ) : (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>DETALLE</StyledTableCell>
            <StyledTableCell align="left">LOTE</StyledTableCell>
            <StyledTableCell align="left">FECHA VENCIMIENTO</StyledTableCell>
            <StyledTableCell align="left">CANTIDAD</StyledTableCell>
            <StyledTableCell align="left">TOTAL</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length > 0 &&
            rows.map((row) => {
              cost += row?.valorFacturadoTotal || row.valor;
              return (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.inv_articulos?.nombre}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.inv_articulos.lotes ? row.inv_articulos.lotes : "S/D"}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.inv_articulos.fecha_vencimiento ? dayjs(row.inv_articulos.fecha_vencimiento).format("DD/MM/YYYY") : "S/D"}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.cantidadFacturada || row.cantidad}</StyledTableCell>
                  <StyledTableCell width={"150px"} align="left">
                    $ {row?.valorFacturadoTotal?.toLocaleString() || row.valor?.toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
      <div className="flex justify-end w-full pr-10 mt-5 font-bold ">TOTAL: $ {cost?.toLocaleString()}</div>
    </StyledTableContainer>
  );
}

// import * as React from "react";
// import { styled } from "@mui/material/styles";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import dayjs from "dayjs";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#FFFFFF",
//     color: "black",
//     padding: "10px",

//     borderBottom: "3px solid black",
//     fontSize: 16,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//     padding: "10px",
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "& td, & th": {
//     borderBottom: "1px solid #8c8c8c",
//   },
// }));

// const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
//   boxShadow: "none",
// }));
// export default function FacturaItemTable({ rows }) {
//   let cost = 0;
//   return (
//     <StyledTableContainer component={Paper}>
//       <Table sx={{ minWidth: 700 }} aria-label="customized table">
//         <TableHead>
//           <TableRow>
//             <StyledTableCell>DETALLE</StyledTableCell>
//             <StyledTableCell align="left">CANTIDAD</StyledTableCell>
//             <StyledTableCell align="left">LOTE</StyledTableCell>
//             <StyledTableCell align="left">FECHA VENCIMIENTO</StyledTableCell>
//             <StyledTableCell align="left">TOTAL</StyledTableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {rows?.length > 0 &&
//             rows.map((row) => {
//               cost += row?.valueCost;
//               return (
//                 <StyledTableRow key={row.id}>
//                   <StyledTableCell component="th" scope="row">
//                     {row.inv_articulos?.nombre}
//                   </StyledTableCell>
//                   <StyledTableCell align="left">{row.cantidad}</StyledTableCell>
//                   <StyledTableCell align="left">
//                     {row.inv_articulos.lotes ? row.inv_articulos.lotes : "S/D"}
//                   </StyledTableCell>
//                   <StyledTableCell align="left">
//                     {row.inv_articulos.fecha_vencimiento
//                       ? dayjs(row.inv_articulos.fecha_vencimiento).format("DD/MM/YYYY")
//                       : "S/D"}
//                   </StyledTableCell>
//                   <StyledTableCell width={"150px"} align="left">
//                     $ {row?.valueCost}
//                   </StyledTableCell>
//                 </StyledTableRow>
//               );
//             })}
//         </TableBody>
//       </Table>
//       <div className="flex justify-end w-full pr-10 mt-5 font-bold ">TOTAL: $ {cost}</div>
//     </StyledTableContainer>
//   );
// }
