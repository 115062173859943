import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Chip, IconButton, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { setOpen } from '../redux/slices/editModal';
import DeleteModal from '../components/deleteModal/DeleteModal';
import RecoverModal from '../components/RecoverModal';
import { useLocation, useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HistoryIcon from '@mui/icons-material/History';
import { getOTByClientID } from '../redux/slices/workOrders';
import { setOpen as setOpenProgramming } from '../redux/slices/programming';
import { setOpenMaintenance } from '../redux/slices/invProducts';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InformarPagoModal from '../components/modal/InformarPagoModal';
import NotaDeCreditoModal from '../components/modal/NotaDeCreditoModal';
import HistorialCobranzaModal from '../components/modal/HistorialCobranzaModal';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import enviroments from '../utils/enviroments';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import dayjs from 'dayjs';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import UndoIcon from '@mui/icons-material/Undo';
import WorkOrderViewModal from '../components/Informes/OtGeneral/WorkOrderViewModal';
import InformeInsumosModal from '../components/modal/InformeInsumosModal';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PagoLogintegModal from '../components/modal/PagoLogintegModal';
import PagoColaboradorModal from '../components/modal/PagoColaboradorModal';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { DeleteOutline } from '@mui/icons-material';
import FormRDF from '../pages/Rendicion/FormRDF';
import RowModal from '../pages/Rendicion/RowModal';
import ProyectModal from '../pages/Rendicion/ProyectModal';
const dayInMonthComparator = (v1, v2) => new Date(v1) - new Date(v2);

const useDataGrid = (permitedRenderDataColumn, handleDelete, name, client = false, openEdit, isInform = false) => {
  const { currentUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleEdit = (params) => {
    dispatch(setOpen({ open: true, id: params.id }));
  };

  const handleDetail = (params) => {
    navigate(`/clients/${params.id}`);
  };

  const handleHistory = (params) => {
    dispatch(getOTByClientID(params.id));
    dispatch(setOpen({ open: true, id: params.id }));
  };

  const handleEquipos = (params) => {
    navigate(`/assign-team/${params.id}?client=${params.row.nombre}&rut=${params.row.rut}`);
  };

  const columns = permitedRenderDataColumn.map((column) => {
    return {
      ...(column?.hide && { hide: true, filterable: false, disableColumnMenu: true }),

      field: column.value,
      headerName: column.title,
      description: '',
      sortable: true,
      flex: 1,
      minWidth: column.width || 150,
      ...(column.maxWidth && { maxWidth: column.maxWidth }),
      ...(column.value === 'id' && { maxWidth: 70, minWidth: 30 }),
      ...(column.value === 'rendicion_id' && {
        maxWidth: 100,
        minWidth: 100,
        renderCell: (params) => <>#{params.row.rendicion_id}</>,
      }),
      ...(column.value === 'descripcion' && {
        minWidth: 450,
      }),
      ...(column.value === 'title' && {
        minWidth: 450,
      }),
      ...(column.value === 'solicitante' && {
        minWidth: 250,
      }),
      ...(column.value === 'colaboradorInforme' && {
        minWidth: 450,
      }),
      ...(column.value === 'solicitada_por' && {
        minWidth: 450,
      }),
      ...(column.value === 'tipo_razon_social' && {
        minWidth: 250,
      }),
      ...(column.value === 'tipoEmpresaRender' && {
        minWidth: 120,
      }),
      ...(column.value === 'hijoRender' && {
        minWidth: 120,
      }),
      ...(column.value === 'nombre' && {
        minWidth: 250,
      }),
      ...(column.value === 'mail_contacto' && {
        minWidth: 250,
      }),
      ...(column.value === 'cliente' && {
        minWidth: 400,
      }),
      ...(column.value === 'item_name' && {
        minWidth: 400,
      }),
      ...(column.value === 'tipoText' && {
        minWidth: 400,
      }),
      ...(column.value === 'fecha_ingreso' && {
        valueGetter: (params) => new Date(dayjs(params.row.fecha_ingreso).add(1, 'day')),
        type: 'date',
        sortComparator: dayInMonthComparator,
      }),
      ...(column.value === 'accion' && {
        renderCell: (params) => {
          return (
            <div className="flex">
              <IconButton id={params.id} onClick={() => navigate(`/create-ot/${params.id}`)}>
                <AddCircleOutlineIcon sx={{ pointerEvents: 'none' }} />
              </IconButton>
              <IconButton id={params.id} onClick={() => handleHistory(params)}>
                <HistoryIcon sx={{ pointerEvents: 'none' }} />
              </IconButton>
            </div>
          );
        },
      }),
      ...(column.value === 'acciones' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              {client && (
                <IconButton id={params.id} onClick={() => handleDetail(params)}>
                  <SearchIcon sx={{ pointerEvents: 'none', color: '#F9A825' }} />
                </IconButton>
              )}
              <IconButton id={params.id} onClick={() => handleEdit(params)}>
                <EditOutlinedIcon sx={{ pointerEvents: 'none' }} />
              </IconButton>
              <IconButton id={params.id}>
                <DeleteModal handleDelete={handleDelete} params={params} name={name} />
              </IconButton>
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_cliente' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              {client && (
                <IconButton id={params.id} onClick={() => handleDetail(params)}>
                  <SearchIcon sx={{ pointerEvents: 'none', color: '#F9A825' }} />
                </IconButton>
              )}
              <IconButton id={params.id} onClick={() => openEdit({ status: true, data: params.row })}>
                {currentUser.action_mode === 'view' ? <VisibilityIcon sx={{ pointerEvents: 'none' }} /> : <EditOutlinedIcon sx={{ pointerEvents: 'none' }} />}
              </IconButton>
              {currentUser.action_mode !== 'view' && (
                <IconButton id={params.id}>
                  <DeleteModal handleDelete={handleDelete} params={params} name={name} />
                </IconButton>
              )}
            </div>
          );
        },
      }),
      ...(column.value === 'signedFile' && {
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              {params.row.signedFile ? (
                <IconButton color="error" sx={{ p: 1 }} id={params.id}>
                  <a href={`${enviroments.img_endpoint}${params.row.signedFile}`} target="_blank" download>
                    <PictureAsPdfIcon />
                  </a>
                </IconButton>
              ) : (
                'Sin Documento'
              )}
            </div>
          );
        },
      }),
      ...(column.value === 'documento_informe' && {
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              {params.row?.documento_informe ? (
                <IconButton color="error" sx={{ p: 1 }} id={params.id}>
                  <a href={`${enviroments.img_endpoint}${params.row.documento_informe}`} target="_blank" download>
                    <PictureAsPdfIcon />
                  </a>
                </IconButton>
              ) : (
                'Sin Documento'
              )}
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_inv' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <IconButton id={params.id} onClick={() => openEdit({ status: true, data: params.row })}>
                <EditOutlinedIcon sx={{ pointerEvents: 'none' }} />
              </IconButton>
              <IconButton id={params.id}>
                <DeleteModal handleDelete={handleDelete} params={params} name={name} />
              </IconButton>
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_gestion_envios' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <IconButton id={params.id} onClick={() => openEdit({ status: true, data: params.row })}>
                <EditOutlinedIcon sx={{ pointerEvents: 'none' }} />
              </IconButton>
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_bodegas' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <IconButton id={params.id} onClick={() => openEdit({ status: true, data: params.row })}>
                <EditOutlinedIcon sx={{ pointerEvents: 'none' }} />
              </IconButton>
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_contacto' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <IconButton id={params.id} onClick={() => openEdit({ status: true, data: params.row })}>
                {currentUser.action_mode === 'view' ? <VisibilityIcon sx={{ pointerEvents: 'none' }} /> : <EditOutlinedIcon sx={{ pointerEvents: 'none' }} />}
              </IconButton>
              <IconButton color="warning" onClick={() => navigate(`/clients/${params.row.cliente_contacto_clienteTocliente.id}`)}>
                <SearchIcon />
              </IconButton>
              {currentUser.action_mode !== 'view' && (
                <IconButton id={params.id}>
                  <DeleteModal handleDelete={handleDelete} params={params} name={name} />
                </IconButton>
              )}
            </div>
          );
        },
      }),
      ...(column.value === 'anticipo_actions' && {
        filterable: false,
        minWidth: 200,
        maxWidth: 200,
        renderCell: (params) => {
          return (
            <div className="flex gap-2">
              <div className="flex">
                <IconButton onClick={() => navigate(`/rendicion-de-fondos/pending/${params.row.rendicionId}`, { state: { from: location } })}>
                  <VisibilityIcon color="success" />
                </IconButton>
                <IconButton color={!!params.row?.metodo ? 'success' : 'default'} onClick={() => openEdit({ open: true, data: params.row })}>
                  <FactCheckIcon />
                </IconButton>
                <Tooltip title="Pago Loginteg">
                  <IconButton>
                    {params.row?.rendicion_gastos?.archivo_pagada ? (
                      <a href={`${enviroments.img_endpoint}${params.row?.rendicion_gastos?.archivo_pagada}`} target="_blank" download>
                        <RequestQuoteIcon color="success" />
                      </a>
                    ) : (
                      <RequestQuoteIcon color="disabled" />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Devuelto Colaborador">
                  <IconButton>
                    {params.row.devolucion &&
                    parseInt(params.row.devolucion.replaceAll('.', '')) < 0 &&
                    params.row?.rendicion_gastos?.rendicion_estado?.id === 2 ? (
                      <PagoColaboradorModal params={params.row} />
                    ) : (
                      <RequestQuoteIcon color="disabled" />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        },
      }),
      ...(column.value === 'rendicion_pago_actions' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex gap-2">
              <div className="flex">
                <IconButton onClick={() => navigate(`/rendicion-de-fondos/${params.row.id}`, { state: { from: location } })}>
                  <VisibilityIcon sx={{ pointerEvents: 'none' }} />
                </IconButton>
                <IconButton color={params.row?.pagada ? 'success' : 'default'} onClick={() => openEdit({ open: true, data: params.row })}>
                  <FactCheckIcon />
                </IconButton>
              </div>
            </div>
          );
        },
      }),
      ...(column.value === 'rendicion_pago_semaforo' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <div
                className={`w-6 h-6 ${
                  params.row.color === 'red'
                    ? 'bg-red-400'
                    : params.row.color === 'yellow'
                    ? 'bg-yellow-400'
                    : params.row.color === 'green'
                    ? 'bg-green-400'
                    : 'bg-gray-500'
                } rounded-full`}
              ></div>
            </div>
          );
        },
      }),
      ...(column.value === 'reabrir_actions' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex gap-2">
              <div className="flex">
                <IconButton onClick={() => openEdit({ open: true, data: params.row })}>
                  <UndoIcon />
                </IconButton>
              </div>
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_mantenedores' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <IconButton id={params.id} onClick={() => openEdit({ status: true, data: params.row })}>
                <EditOutlinedIcon sx={{ pointerEvents: 'none' }} />
              </IconButton>
              <IconButton id={params.id}>
                <DeleteModal handleDelete={handleDelete} params={params} name={name} />
              </IconButton>
            </div>
          );
        },
      }),
      ...(column.value === 'itemCount' && {
        renderCell: (params) => {
          return <Chip sx={{ minWidth: 50 }} label={params.row.itemCount} color="primary" />;
        },
      }),
      ...(column.value === 'capacitacion_actions' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <IconButton id={params.id} onClick={() => openEdit({ open: true, data: params.row })}>
                <EditOutlinedIcon sx={{ pointerEvents: 'none' }} />
              </IconButton>
              <IconButton id={params.id}>
                <DeleteModal handleDelete={handleDelete} params={params} name={name} />
              </IconButton>
            </div>
          );
        },
      }),
      ...(column.value === 'deleteTeam' && {
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <IconButton id={params.id}>
                <DeleteOutlineOutlinedIcon sx={{ color: '#f44336' }} onClick={() => handleDelete(params.id)} />
              </IconButton>
            </div>
          );
        },
      }),
      ...(column.value === 'programacion' && {
        flex: 1,
        renderCell: (params) => {
          return (
            <Button
              onClick={() => dispatch(setOpenProgramming({ openClient: true, client: params.row }))}
              variant="contained"
              sx={{ textTransform: 'none' }}
              size="small"
              id={params.id}
            >
              Seleccionar
            </Button>
          );
        },
      }),
      ...(column.value === 'acciones_elements' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <Chip
              onClick={() => openEdit({ status: true, element: params.row, isInform })}
              variant="contained"
              sx={{ textTransform: 'none' }}
              id={params.id}
              label="Seleccionar"
              color="primary"
            />
          );
        },
      }),
      ...(column.value === 'statePending' && {
        flex: 1,
        renderCell: (params) => {
          return (
            <>
              {params.row.file && !params.row.signedFile ? (
                <Chip variant="outlined" sx={{ textTransform: 'none' }} id={params.id} label="Pendiente de Firma" color="warning" />
              ) : (
                <Chip
                  variant="outlined"
                  sx={{ textTransform: 'none' }}
                  id={params.id}
                  label="Pendiente de validación"
                  color="primary"
                  onClick={() => openEdit({ state: true, id: params.id, sign: params.row.signedFile, data: params.row })}
                />
              )}
            </>
          );
        },
      }),
      ...(column.value === 'mantencion' && {
        flex: 1,
        renderCell: (params) => {
          return (
            <Button
              onClick={() => dispatch(setOpenMaintenance({ openMaintenance: true, maintenance: params.row }))}
              variant="contained"
              sx={{ textTransform: 'none' }}
              size="small"
              id={params.id}
            >
              Seleccionar
            </Button>
          );
        },
      }),
      ...(column.value === 'equipos' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <Button variant="contained" sx={{ width: '80px', height: '35px' }} onClick={() => handleEquipos(params)}>
                Equipos
              </Button>
            </div>
          );
        },
      }),
      ...(column.value === 'elementosEstado' && {
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        maxWidth: 300,
        renderCell: (params) => {
          return params.value === true ? (
            <Chip size="small" label="Completo" sx={{ textTransform: 'capitalize', minWidth: '85px' }} color="success" />
          ) : (
            <Chip size="small" label="Incompleto" sx={{ textTransform: 'capitalize', minWidth: '85px' }} color="warning" />
          );
        },
      }),
      ...(column.value === 'acciones_pre_factura' && {
        filterable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <Chip color="primary" sx={{ mr: 1 }} label="Orden de compra" onClick={() => navigate(`/orden-de-compra/${params.id}`)} />
            </div>
          );
        },
      }),
      ...((column.value === 'equipos_length' ||
        column.value === 'repuestos_length' ||
        column.value === 'articulos_length' ||
        column.value === 'servicios_length') && {
        minWidth: 100,
        maxWidth: 100,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          return (
            <div className="flex">
              {params.value === 'SI' ? (
                <div>
                  <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                </div>
              ) : (
                <div>
                  <HighlightOffIcon sx={{ color: '#d10a0a' }} />
                </div>
              )}
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_sin_facturar' && {
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
        maxWidth: 200,
        renderCell: (params) => {
          return (
            <div className="flex gap-2">
              {params.row.ot_id ? (
                <div className="flex">
                  <Chip color="success" label="OT" onClick={() => navigate(`/ot-view/${params.row.ot_id}`)} />
                </div>
              ) : (
                <div className="flex">
                  <Chip color="success" label="OT" variant="outlined" />
                </div>
              )}
              <div>
                <div className="flex">
                  <Chip color="warning" label="OC" onClick={() => navigate(`/orden-de-compra-edit/${params.row.id}`)} />
                </div>
              </div>
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_sin_facturar_dos' && {
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
        maxWidth: 200,
        renderCell: (params) => {
          return (
            <div className="flex gap-2 items-center">
              <div className="flex">
                <Chip color="primary" label="Facturar" onClick={() => navigate(`/por-facturar/${params.id}`)} />
              </div>
              {params.row.ot ? (
                <IconButton>
                  <DeleteModal handleDelete={handleDelete} params={params} name={'OC'} />
                </IconButton>
              ) : (
                <IconButton>
                  <DeleteOutlineOutlinedIcon sx={{ color: '#f44336' }} />
                </IconButton>
              )}
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_incorporation' && {
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        maxWidth: 300,
        renderCell: (params) => {
          return (
            <div className="flex">
              <Tooltip title="En construccion">
                <Chip color="primary" label="Ver Incorporación" onClick={() => openEdit({ status: true, data: params.row })} />
              </Tooltip>
            </div>
          );
        },
      }),
      ...(column.value === 'nro_oc' && {
        minWidth: 200,
        maxWidth: 200,
      }),
      ...(column.value === 'fecha_oc_parsed' && {
        minWidth: 100,
        maxWidth: 100,
      }),
      ...(column.value === 'cliente_facutra' && {
        minWidth: 350,
        maxWidth: 350,
      }),
      ...(column.value === 'acciones_facturado' && {
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
        maxWidth: 200,
        renderCell: (params) => {
          return (
            <div className="flex gap-2">
              {params.row.ot_id ? (
                <div className="flex">
                  <Chip color="success" label="OT" onClick={() => navigate(`/ot-oc/${params.row.ot_id}`)} />
                </div>
              ) : (
                <div className="flex">
                  <Chip color="success" label="OT" variant="outlined" />
                </div>
              )}
              <div className="flex">
                <Chip color="primary" label="Detalle" onClick={() => navigate(`/ordenes-facturadas/${params.id}`)} />
              </div>
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_facturas' && {
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 300,
        maxWidth: 300,
        renderCell: (params) => {
          return (
            <div className="flex gap-2">
              {params.row.nro_ot ? (
                <div className="flex">
                  <Chip color="success" label="OT" onClick={() => navigate(`/facturas-generadas-ot/${params.row.nro_ot}`)} />
                </div>
              ) : (
                <div className="flex">
                  <Chip color="success" label="OT" variant="outlined" />
                </div>
              )}
              <div className="flex">
                <Chip color="primary" label="Ver Factura" onClick={() => navigate(`/facturas-generadas-factura/${params.id}`)} />
              </div>
              <div className="flex">
                <Chip color="info" label="Ver OC" onClick={() => navigate(`/facturas-generadas-oc/${params.row.facturacion.id}`)} />
              </div>
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_facturas_2' && {
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 300,
        maxWidth: 300,
        renderCell: (params) => {
          return (
            <div className="flex gap-2">
              {params.row.nro_ot ? (
                <div className="flex">
                  <Chip color="success" label="OT" onClick={() => navigate(`/facturas-generadas-ot/${params.row.nro_ot}`)} />
                </div>
              ) : (
                <div className="flex">
                  <Chip color="success" label="OT" variant="outlined" />
                </div>
              )}
              <div className="flex">
                <Chip color="primary" label="Ver Factura" onClick={() => navigate(`/facturas-generadas-factura/${params.id}`)} />
              </div>
              <div className="flex">
                <Chip color="info" label="Ver OC" onClick={() => navigate(`/facturas-generadas-oc/${params.row.facturacion.id}`)} />
              </div>
            </div>
          );
        },
      }),
      ...(column.value === 'historial_cobranza' && {
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 80,
        maxWidth: 100,
        renderCell: (params) => {
          return (
            <div className="flex">
              <HistorialCobranzaModal params={params.id} />
            </div>
          );
        },
      }),
      ...(column.value === 'compromiso' && {
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        maxWidth: 200,
        renderCell: (params) => {
          return (
            <div className="flex">
              <Chip color="primary" label="Registar" onClick={() => navigate(`/cobranza/${params.id}`)} />
            </div>
          );
        },
      }),
      ...(column.value === 'registrar_pago' && {
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        maxWidth: 150,
        renderCell: (params) => {
          return (
            <div className="flex">
              <InformarPagoModal color="success" label="Registar" handleDelete={handleDelete} params={params} />
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_informes_insumos' && {
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        maxWidth: 150,
        renderCell: (params) => {
          return (
            <div className="flex">
              <InformeInsumosModal codigo_loginteg={params.row.codigo_loginteg} bodegaName={params.row.bodegaName} />
            </div>
          );
        },
      }),
      ...(column.value === 'registrar_nota_credito' && {
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
        maxWidth: 150,
        renderCell: (params) => {
          return (
            <div className="flex">
              <NotaDeCreditoModal color="success" label="Registar" handleDelete={handleDelete} params={params} />
            </div>
          );
        },
      }),
      ...(column.value === 'facturas_pagadas' && {
        filterable: false,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 300,
        maxWidth: 300,
        renderCell: (params) => {
          return (
            <div className="flex">
              <Chip color="primary" label="Detalles" onClick={() => navigate(`/facturas-pagadas/${params.id}`)} />
            </div>
          );
        },
      }),
      ...(column.value === 'total' && {
        renderCell: (params) => {
          return <div className="font-semibold flex items-center text-[#105a37]">$ {params.row.total}</div>;
        },
      }),
      ...(column.value === 'anticipo' && {
        renderCell: (params) => {
          return <div className="font-semibold flex items-center text-[#105a37]">$ {params.row.anticipo}</div>;
        },
      }),
      ...(column.value === 'devolucion' && {
        renderCell: (params) => {
          return (
            <>
              {parseInt(params.row.devolucion?.replaceAll('.', '')) >= 0 ? (
                <div className="font-semibold flex items-center text-[#105a37]">$ {params.row.devolucion}</div>
              ) : (
                <div className="flex items-center text-[#5a1010]">$ {params.row.devolucion}</div>
              )}
            </>
          );
        },
      }),
      ...(column.value === 'estado_text' && {
        renderCell: (params) => {
          return (
            <div className="flex items-center">
              {params.row.estado_text === 'GUARDADA' ? (
                <Chip sx={{ minWidth: 120 }} color="warning" label="Guardada" />
              ) : params.row.estado_text === 'RECHAZADA' ? (
                <Chip sx={{ minWidth: 120 }} color="error" label="Rechazada" />
              ) : params.row.estado_text === 'APROBADA' ? (
                <Chip sx={{ minWidth: 120 }} color="primary" label="Aprobada" />
              ) : params.row.estado_text === 'SOLICITADA' ? (
                <Chip sx={{ minWidth: 120 }} color="info" label="Solicitado" />
              ) : params.row.estado_text === 'PAGADA' ? (
                <Chip sx={{ minWidth: 120 }} color="success" label="Pagado" />
              ) : params.row.estado_text === 'NO PAGADA' ? (
                <Chip sx={{ minWidth: 120 }} color="warning" label="No Pagada" />
              ) : (
                params.row.estado_text
              )}
            </div>
          );
        },
      }),
      ...(column.value === 'pending_actions' && {
        filterable: false,
        renderCell: (params) => {
          return (
            <div className="flex items-center mr-5">
              {params.row.estado_text === 'GUARDADA' ? (
                <IconButton onClick={() => navigate(`/rendicion-de-fondos/pending/${params.id}`, { state: { from: location } })}>
                  <VisibilityIcon color="success" />
                </IconButton>
              ) : (
                <IconButton onClick={() => navigate(`/rendicion-de-fondos/${params.id}`, { state: { from: location } })}>
                  <VisibilityIcon color="success" />
                </IconButton>
              )}
              {!params.row.estado_text && (
                <IconButton>
                  <DeleteModal handleDelete={handleDelete} params={params} name={name} />
                </IconButton>
              )}
              <Tooltip title="Fondo por Rendir">
                {params.row?.anticipo[0]?.file ? (
                  <a href={`${enviroments.img_endpoint}${params.row?.anticipo[0].file}`} target="_blank" download>
                    <FactCheckIcon color="success" />
                  </a>
                ) : (
                  <FactCheckIcon color="disabled" />
                )}
              </Tooltip>

              <Tooltip title="Pago Loginteg">
                {params.row.archivo_pagada ? (
                  <a href={`${enviroments.img_endpoint}${params.row?.archivo_pagada}`} target="_blank" download>
                    <RequestQuoteIcon color="success" />
                  </a>
                ) : (
                  <RequestQuoteIcon color="disabled" />
                )}
              </Tooltip>
              <Tooltip title="Devuelto colaborador">
                {params.row?.anticipo[0]?.file_colaborador ? (
                  <a href={`${enviroments.img_endpoint}${params.row?.anticipo[0].file_colaborador}`} target="_blank" download>
                    <RequestQuoteIcon color="success" />
                  </a>
                ) : (
                  <RequestQuoteIcon color="disabled" />
                )}
              </Tooltip>

              {params.row.estado_text !== 'PAGADA' && <DeleteModal handleDelete={handleDelete} params={params} name={name} />}
            </div>
          );
        },
      }),
      ...(column.value === 'vb_actions' && {
        filterable: false,
        renderCell: (params) => {
          return (
            <div className="flex items-center">
              <IconButton onClick={() => navigate(`/rendicion-de-fondos/vb/${params.id}`, { state: { from: location } })}>
                <VisibilityIcon color="success" />
              </IconButton>
              {!params.row.estado_text && (
                <IconButton>
                  <DeleteModal handleDelete={handleDelete} params={params} name={name} />
                </IconButton>
              )}
            </div>
          );
        },
      }),
      ...(column.value === 'rendicion_proyecto' && {
        filterable: false,
        renderCell: (params) => {
          return (
            <div className="flex items-center gap-2">
              <ProyectModal data={params.row} />
              <a href={`/rendicion-de-fondos/${params.row.rendicion_id}`} target="_blank">
                <VisibilityIcon color="success" />
              </a>

              {params.row.documento ? (
                <a href={`${enviroments.img_endpoint}${params.row?.documento}`} target="_blank" rel="noreferrer noopener" download="proposed_file_name">
                  <RequestQuoteIcon color="success" />
                </a>
              ) : (
                <RequestQuoteIcon color="disabled" />
              )}
            </div>
          );
        },
      }),
      ...(column.value === 'informe_proyecto' && {
        filterable: false,
        renderCell: (params) => {
          return (
            <div className="flex items-center">
              {params.row?.documento ? (
                <a href={`${enviroments.img_endpoint}${params.row?.documento}`} target="_blank" rel="noreferrer noopener" download="proposed_file_name">
                  <RequestQuoteIcon color="success" />
                </a>
              ) : (
                <RequestQuoteIcon color="disabled" />
              )}
            </div>
          );
        },
      }),
      ...(column.value === 'recover' && {
        filterable: false,
        renderCell: (params) => {
          return (
            <div className="flex items-center">
              <IconButton>
                <RecoverModal handleDelete={handleDelete} params={params} name={name} />
              </IconButton>
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_usuario' && {
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          return (
            <div className="flex">
              <IconButton id={params.id} onClick={() => openEdit({ status: true, data: params.row })}>
                <EditOutlinedIcon sx={{ pointerEvents: 'none' }} />
              </IconButton>
              <IconButton id={params.id}>
                <DeleteModal handleDelete={handleDelete} params={params} name={name} />
              </IconButton>
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_info_otGeneral' && {
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <WorkOrderViewModal order={params.id} client={params?.row?.cliente_ot_clienteTocliente?.id} />
            </div>
          );
        },
      }),
      ...(column.value === 'acciones_info_otAsgined' && {
        flex: 1,
        renderCell: (params) => {
          return (
            <div className="flex">
              <WorkOrderViewModal order={params?.row?.nro_ot} client={params?.id} />
            </div>
          );
        },
      }),
      ...(column.value === 'pago_loginteg' && {
        minWidth: 150,
        maxWidth: 150,
        renderCell: (params) => {
          return (
            <div className="flex justify-center">
              {parseInt(params.row.devolucion.replaceAll('.', '')) > 0 ? <PagoLogintegModal params={params.row} /> : <LocalAtmIcon color="disabled" />}
            </div>
          );
        },
      }),
      ...(column.value === 'devuelto_colaborador' && {
        minWidth: 150,
        maxWidth: 150,
        renderCell: (params) => {
          return (
            <div className="flex justify-center">
              {parseInt(params.row.devolucion.replaceAll('.', '')) < 0 ? (
                <PagoColaboradorModal params={params.row} />
              ) : (
                <LocalAtmIcon order={params?.row?.nro_ot} client={params?.id} color="disabled" />
              )}
            </div>
          );
        },
      }),
      ...(column.value === 'pdf_ot' && {
        flex: 1,
        renderCell: (params) => {
          return (
            (params.row.ot?.pdf || params.row?.pdf) && (
              <div className="flex">
                <a
                  href={`${enviroments.img_endpoint}${params.row.ot?.pdf || params.row?.pdf}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  download="proposed_file_name"
                  className="w-[70px] h-[19px] p-3 rounded-[20px] bg-red-500 text-white flex justify-center items-center text-sm"
                >
                  PDF
                </a>
              </div>
            )
          );
        },
      }),
    };
  });

  return [columns];
};

export default useDataGrid;
