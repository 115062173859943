import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { Button, IconButton, MenuItem, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import removeNan from "../../utils/removeNaN";
import thousandSeparator from "../../utils/thousandSeparator";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import FileUploader from "../../utils/FileUploader";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import uploadFiles from "../../utils/uploadRendicion";
import InputAdornment from "@mui/material/InputAdornment";
import validateChileanRut from "../../utils/validateChileanRut";
import axiosInstance from "../../utils/axiosInstance";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import "dayjs/locale/es";
import generateRandomID from "../../utils/randomIDGenerator";
import CustomSelectMultiple from "../../components/CustomSelectMultiple";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createExpenseWithFund, createFundAndExpense, deleteFoundAccountProyects, updateExpense } from "../../redux/slices/fundAccounting";

const expenseTypes = [
  { id: 1, name: "BOLETA" },
  { id: 2, name: "FACTURA" },
  { id: 3, name: "COMPROBANTE" },
];

const filter = createFilterOptions();

const ProyectForm = ({ data, type }) => {
  console.log(data);
  const { id: fundAccountingId } = useParams();
  const [km, setKm] = useState("");
  const { paises } = useSelector((state) => state.paises);
  const [date, setDate] = useState(dayjs());
  const [documentNumber, setDocumentNumber] = useState("");
  const [detail, setDetail] = useState("");
  const [amount, setAmount] = useState("");
  const [category, setCategory] = useState("");
  const [companies, setCompanies] = useState([]);
  const [rut, setRut] = useState("");
  const [expenseType, setExpenseType] = useState("");
  const [closeOnSave, setCloseOnSave] = useState(true);
  const [document, setDocument] = useState({ documento: null });
  const [companySelected, setCompanySelected] = useState(null);
  const [combinationError, setCombinationError] = useState(false);
  const [travels, setTravels] = useState([]);
  const [country, setCountry] = useState("");
  const [proyects, setProyects] = useState([]);
  const [searchByRut, setSearchByRut] = useState(false);
  const [travelInformation, setTravelInformation] = useState({
    initialKm: "",
    finalKm: "",
    usage: "",
    liters: "",
  });
  const { categories, bills } = useSelector((state) => state.fundAccounting);
  const { facturacionProyectos } = useSelector((state) => state.facturacion);
  const { pathname } = useLocation();

  const isViewMode = type === "view";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (data) {
      reset({
        name: data?.nombre || data?.name,
        rut: data?.rendicion_empresas?.rut || data?.rut,
      });
      setRut(data?.rendicion_empresas?.rut);
      setCountry(data?.paisId);
      setDocumentNumber(data?.numero_documento);
      setDocument({ documento: data?.documento });
      setCategory(data?.rendicion_tipo_id);
      setKm(data?.km);
      setAmount(thousandSeparator(data?.monto));
      setDetail(data?.detalle);
      setExpenseType(expenseTypes.find((e) => e.name === data?.tipo_gasto)?.id);

      setCompanySelected(data?.rendicion_empresas);
      setDate(dayjs(data?.fecha));
      setTravelInformation({
        initialKm: data?.initialKm,
        finalKm: data?.finalKm,
        usage: data?.usage,
        liters: data?.liters,
      });
      setTravels(data?.rendicion_viaje || []);
    }
  }, [data]);

  useEffect(() => {
    const callCompanies = async () => {
      const { data } = await axiosInstance.get("/fund-accounting/company");
      setCompanies(data);
    };

    callCompanies();
  }, []);

  useEffect(() => {
    if (!companySelected?.id) {
      setCompanySelected((prev) => ({
        ...prev,
        rut: rut,
        documentNumber: documentNumber,
      }));
    }
  }, [documentNumber, rut]);

  useEffect(() => {
    if (!data) {
      if (paises.length) {
        const chile = paises?.find((el) => el.iso === "CL");
        setCountry(chile.id);
        setValue("paisId", chile.id);
      }
    }
  }, [paises]);
  const submit = async () => {
    await dispatch(deleteFoundAccountProyects(data.id, proyects));
  };

  return (
    <form className="w-full">
      <div className="leading-4 mb-2 text-red-500 text-center font-semibold">
        ATENCIÓN! Ingresar detalle de Boleta o Factura: <br /> Alimentacion (comandas), compras de materiales, arriendo de maquinaria, taxi, etc..
      </div>
      <div className="flex justify-end">
        <div className="flex items-center">
          <Checkbox checked={searchByRut} onChange={() => setSearchByRut(!searchByRut)} />
          <span>Buscar por RUT</span>
        </div>
      </div>
      <div className="gap-3 flex mb-2 max-sm:flex-col">
        <div className="w-full">
          <TextField
            {...register("paisId")}
            onChange={(event) => setCountry(event.target.value)}
            value={country}
            label="País"
            disabled
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
            select
            size="small"
            // InputProps={{ sx: { fontSize: "14px" } }}
            fullWidth
            error={Boolean(errors?.paisId)}
            defaultValue={""}
          >
            {paises.length &&
              paises.map((category, index) => (
                <MenuItem key={index} value={category.id} dense divider>
                  {category.name}
                </MenuItem>
              ))}
          </TextField>
          <div className="text-xs text-red-500">{errors?.category && "El campo es requerido"}</div>
        </div>
        <div className="w-full">
          <Autocomplete
            options={companies}
            disabled={isViewMode || searchByRut}
            size="small"
            value={companySelected}
            onChange={(event, newValue) => {
              if (newValue && newValue.inputValue) {
                setCompanySelected({
                  name: newValue.inputValue,
                  rut: "",
                  documentNumber: "",
                });
              } else if (newValue !== null) {
                setCompanySelected(newValue);
                setRut(newValue.rut);
                setValue("rut", newValue.rut);
              }
            }}
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            filterOptions={(options, params) => {
              <div className="mb-2 flex justify-between items-center">
                <div className="text-lg font-bold text-gray-700">Nuevo gasto</div>
                <FormControlLabel
                  control={<Checkbox size="small" checked={closeOnSave} onChange={() => setCloseOnSave(!closeOnSave)} />}
                  label="Cerrar al guardar"
                />
              </div>;
              const filtered = filter(options, params);

              const { inputValue } = params;
              const isExisting = options.some((option) => inputValue === option.name);
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  name: `Agregar "${inputValue}"`,
                });
              }

              return filtered;
            }}
            getOptionLabel={(val) => val?.name || ""}
            renderInput={(params) => <TextField size="small" {...params} label="Empresa Emisora" disabled />}
          />
          <div className="text-xs text-red-500">{errors?.name && "El campo es requerido"}</div>
        </div>
      </div>

      <div className="gap-3 flex mb-2 max-sm:flex-col">
        <div className="w-full">
          {!searchByRut ? (
            <TextField
              label="Rut / Documento de identidad"
              size="small"
              fullWidth
              disabled={companySelected?.id || isViewMode}
              value={rut}
              error={Boolean(errors?.rut)}
              // InputProps={{ sx: { fontSize: "14px" } }}
              onChange={(event) => setRut(event.target.value)}
            />
          ) : (
            <Autocomplete
              label="Rut / Documento de identidad"
              size="small"
              fullWidth
              options={companies}
              select
              disabled={!searchByRut || isViewMode}
              value={rut}
              getOptionLabel={(val) => val?.rut || ""}
              onChange={(event, newValue) => {
                setCompanySelected(newValue);
                setRut(newValue);
                setValue("rut", newValue.rut);
              }}
              error={Boolean(errors?.rut)}
              // InputProps={{ sx: { fontSize: "14px" } }}

              renderInput={(params) => <TextField size="small" {...params} label="Rut / Documento de identidad  " />}
            />
          )}
          <div className="text-xs text-red-500">{errors?.rut?.type === "required" ? "El campo es requerido" : errors.rut && errors.rut.message}</div>
        </div>
        <div className="w-full">
          <TextField
            label="N.º Documento"
            size="small"
            fullWidth
            value={documentNumber}
            {...register("documentNumber", {
              // required: data ? false : true,
              pattern: /^\d+$/,
            })}
            error={Boolean(errors?.documentNumber)}
            // InputProps={{ sx: { fontSize: "14px" } }}
            disabled
            onChange={(event) => setDocumentNumber(event.target.value)}
          />
          <div className="text-xs text-red-500">
            {errors?.documentNumber?.type === "required" ? "El campo es requerido" : errors?.documentNumber?.type === "pattern" && "Este campo es numerico"}
          </div>
        </div>
      </div>
      <div className="gap-3 flex mb-2 max-sm:flex-col">
        <div className="w-full">
          <TextField
            {...register("category")}
            onChange={(event) => setCategory(event.target.value)}
            value={category}
            label="Categoria"
            disabled
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
            select
            size="small"
            // InputProps={{ sx: { fontSize: "14px" } }}
            fullWidth
            error={Boolean(errors?.category)}
            defaultValue={""}
          >
            {categories.length &&
              categories.map((category, index) => (
                <MenuItem key={index} value={category.id} dense divider>
                  {category.nombre}
                </MenuItem>
              ))}
          </TextField>
          <div className="text-xs text-red-500">{errors?.category && "El campo es requerido"}</div>
        </div>
      </div>
      {category === 2 && (
        <div className="w-full">
          <div>
            <div className="w-full flex gap-3 mt-3 mb-2">
              <TextField
                value={travelInformation.initialKm}
                onChange={(e) =>
                  setTravelInformation((prev) => ({
                    ...prev,
                    initialKm: thousandSeparator(removeNan(e.target.value)),
                  }))
                }
                label="Km Inicio"
                disabled
                size="small"
                fullWidth
              />
              <TextField
                value={travelInformation.finalKm}
                onChange={(e) =>
                  setTravelInformation((prev) => ({
                    ...prev,
                    finalKm: thousandSeparator(removeNan(e.target.value)),
                  }))
                }
                label="Km Término"
                size="small"
                disabled
                fullWidth
              />
              <TextField
                value={travelInformation.usage}
                disabled
                onChange={(e) =>
                  setTravelInformation((prev) => ({
                    ...prev,
                    usage: thousandSeparator(removeNan(e.target.value)),
                  }))
                }
                label="Consumo"
                size="small"
                fullWidth
              />
              <TextField
                value={travelInformation.liters}
                disabled
                onChange={(e) =>
                  setTravelInformation((prev) => ({
                    ...prev,
                    liters: thousandSeparator(removeNan(e.target.value)),
                  }))
                }
                label="Litros"
                size="small"
                fullWidth
              />
            </div>
            <div className="mb-2 min-h-28 min-scroll overflow-auto h-auto max-h-28 rounded">
              {!travels?.length
                ? null
                : travels
                    .filter((el) => !el?.deleted)
                    .map((el, index) => (
                      <div className="w-full flex gap-1 mb-1">
                        <TextField
                          value={el.detail}
                          InputProps={{ sx: { bgcolor: "gainsboro" } }}
                          placeholder="Detalle"
                          size="small"
                          fullWidth
                          disabled
                          onChange={(e) => {
                            const updatedTravels = travels.map((item, i) => (i === index ? { ...item, detail: e.target.value } : item));
                            setTravels(updatedTravels);
                          }}
                        />
                        <TextField
                          value={el.distance}
                          InputProps={{ sx: { bgcolor: "gainsboro" } }}
                          placeholder="Distancia"
                          size="small"
                          disabled
                          fullWidth
                          onChange={(e) => {
                            const updatedTravels = travels.map((item, i) => (i === index ? { ...item, distance: e.target.value } : item));
                            setTravels(updatedTravels);
                          }}
                        />
                        <TextField
                          value={el.initialPlace}
                          InputProps={{ sx: { bgcolor: "gainsboro" } }}
                          placeholder="Origen"
                          size="small"
                          fullWidth
                          disabled
                          onChange={(e) => {
                            const updatedTravels = travels.map((item, i) => (i === index ? { ...item, initialPlace: e.target.value } : item));
                            setTravels(updatedTravels);
                          }}
                        />
                        <TextField
                          value={el.finalPlace}
                          InputProps={{ sx: { bgcolor: "gainsboro" } }}
                          placeholder="Destino"
                          size="small"
                          disabled
                          fullWidth
                          onChange={(e) => {
                            const updatedTravels = travels.map((item, i) => (i === index ? { ...item, finalPlace: e.target.value } : item));
                            setTravels(updatedTravels);
                          }}
                        />
                        {type !== "view" && (
                          <IconButton
                            disabled
                            onClick={() => {
                              if (type === "update") {
                                return setTravels((prev) => {
                                  const finded = prev.find((travel) => travel.id === el.id);
                                  finded.deleted = true;
                                  return [...prev.filter((travel) => travel.id !== el.id), finded];
                                });
                              }
                              setTravels((prev) => [...prev.filter((travel) => travel.id !== el.id)]);
                            }}
                            size="small"
                            color="error"
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        )}
                      </div>
                    ))}
            </div>
            <div className="w-full flex justify-between items-center gap-3 mb-3">
              <div className="w-full">
                <Button
                  disabled
                  onClick={() =>
                    setTravels((prev) => [
                      ...prev,
                      {
                        detail: "",
                        distance: "",
                        initialPlace: "",
                        finalPlace: "",
                        id: generateRandomID(6),
                      },
                    ])
                  }
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  fullWidth
                >
                  Agregar
                </Button>
              </div>
              <div className="w-full">
                <TextField
                  value={parseInt(travelInformation?.usage?.replaceAll(".", "")) * parseInt(travelInformation?.liters?.replaceAll(".", "")) || ""}
                  disabled
                  label="Km Estimado"
                  size="small"
                  fullWidth
                />
              </div>
              <div className="w-full">
                <TextField
                  value={
                    (travels?.length &&
                      travels.reduce((acc, curr) => {
                        return parseInt(acc) + parseInt(curr.distance);
                      }, 0)) ||
                    0
                  }
                  disabled
                  label="Km Recorridos"
                  size="small"
                  fullWidth
                />
              </div>
              <div className="w-full">
                <TextField
                  value={
                    (travels?.length &&
                      parseInt(travelInformation?.usage?.replaceAll(".", "")) * parseInt(travelInformation?.liters?.replaceAll(".", "")) -
                        travels.reduce((acc, curr) => {
                          return parseInt(acc) + parseInt(curr.distance);
                        }, 0)) ||
                    0
                  }
                  disabled
                  label="Diferencia"
                  size="small"
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="gap-3 flex mb-2 max-sm:flex-col">
        <div className="w-full">
          <TextField
            label="Monto ($)"
            size="small"
            fullWidth
            disabled
            value={amount}
            {...register("amount", {
              pattern: /^[0-9.]+$/,
            })}
            onChange={(event) => setAmount(thousandSeparator(removeNan(event.target.value)))}
            error={Boolean(errors?.amount)}
            InputProps={{
              sx: { fontSize: "14px" },
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <div className="text-xs text-red-500">
            {errors?.amount?.type === "required" ? "El campo es requerido" : errors?.amount?.type === "pattern" && "Este campo es numerico"}
          </div>
        </div>
        <div className="w-full">
          <TextField
            {...register("expenseType")}
            onChange={(event) => setExpenseType(event.target.value)}
            value={expenseType}
            label="Tipo de gasto"
            disabled
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              },
            }}
            select
            size="small"
            // InputProps={{ sx: { fontSize: "14px" } }}
            fullWidth
            error={Boolean(errors?.category)}
            defaultValue={""}
          >
            {expenseTypes.length &&
              expenseTypes.map((category, index) => (
                <MenuItem key={index} value={category.id} dense divider>
                  {category.name}
                </MenuItem>
              ))}
          </TextField>
          <div className="text-xs text-red-500">{errors?.category && "El campo es requerido"}</div>
        </div>
      </div>
      <div className="mb-2 flex gap-3">
        <div className="w-full">
          <LocalizationProvider dateAdapter={AdapterDayjs} locale={esES} adapterLocale="es">
            <DatePicker
              value={date}
              disabled
              slotProps={{
                textField: {
                  size: "small",
                  label: "Fecha",
                  fullWidth: true,
                  InputProps: { sx: { fontSize: "14px" } },
                },
              }}
            />
          </LocalizationProvider>
        </div>
        <div className="w-full">
          <FileUploader expense={true} value={document} disabled keyword={"documento"} to={`${window.innerWidth <= 600 ? "" : "Documento"}`} size={"37.16px"} />
        </div>
      </div>
      <div className="w-full mb-2">
        {document?.documento?.name && (
          <div className="h-[px] py-[4.5px] border-[1px] border-[#bdbdbd] text-gray-500 w-full rounded flex justify-between items-center px-3">
            {document?.documento.name.length < 20 ? document?.documento.name : document?.documento.name.slice(0, 20) + "..."}
            <div>
              <TextSnippetIcon sx={{ color: "#3b79bd" }} />
            </div>
          </div>
        )}
        {document?.documento && !document.documento.name && (
          <div className="h-[px] py-[4.5px] border-[1px] border-[#bdbdbd] text-gray-500 w-full rounded flex justify-between items-center px-3">
            {document?.documento.length < 20 ? document?.documento : document?.documento.slice(0, 20) + "..."}
            <div>
              <TextSnippetIcon sx={{ color: "#3b79bd" }} />
            </div>
          </div>
        )}
      </div>
      <div className="w-full mb-2">
        <CustomSelectMultiple
          id="proyectos"
          name="proyectos"
          label={"Proyectos"}
          value={proyects}
          onChange={(e) => setProyects(e)}
          options={facturacionProyectos}
        />
      </div>
      <div className="w-full mb-2 ">
        <TextField
          label="Concepto"
          size="small"
          multiline
          rows={2}
          fullWidth
          disabled
          value={detail}
          // InputProps={{ sx: { fontSize: "14px" } }}
          {...register("detail")}
          onChange={(event) => setDetail(event.target.value)}
          error={Boolean(errors?.detail)}
        />
        <div className="text-xs text-red-500">{errors?.detail && "El campo es requerido"}</div>
      </div>

      <Button
        disabled={
          // item === "" ||
          !proyects.length
        }
        onClick={handleSubmit(submit)}
        fullWidth
        variant="outlined"
      >
        Guardar
      </Button>
      {!data && rut && !companySelected.id && companies.find((company) => company.rut === rut) && (
        <div className="mt-2">
          <span className=" text-sm text-gray-500">
            <strong className="text-red-500">Error</strong>: El RUT se encuentra en uso
          </span>
        </div>
      )}
      {combinationError && (
        <div className="mt-2">
          <span className=" text-sm text-gray-500">
            <strong className="text-red-500">Error</strong>: Combinación existente
          </span>
        </div>
      )}
    </form>
  );
};

export default ProyectForm;
