import { useDispatch, useSelector } from 'react-redux';
import BreadCrumb from '../components/BreadCrumb/BreadCrumb';
import { getAllDocumentsRecived, getProyects, getTipoGastos, uploadDocuemntsreceived } from '../redux/slices/documents';
import FacturasRecibidasTable from '../components/tables/FacturasRecibidasTable/FacturasRecibidasTable';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { Button, MenuItem, TextField } from '@mui/material';
import axiosInstance from '../utils/axiosInstance';
import ControlledDataGridComponent from '../components/tables/controlledDataGrid';
import useDataGrid from '../hooks/useDataGrid';
import thousandSeparator from '../utils/thousandSeparator';

export default function ProyectInform() {
  const { currentUser } = useSelector((state) => state.auth);
  const { documentsRecived, success } = useSelector((state) => state.document);
  const [docFiltered, setDocFiltered] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [selectedProyecto, setSelectedProyecto] = useState(0);
  const { proyects, tipoGastos } = useSelector((state) => state.document);
  const [gastos, setGastos] = useState([]);
  const [totales, setTotales] = useState();

  const handleProyecto = (e) => {
    setSelectedProyecto(e.target.value);
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const handleSumbitProyect = async (e) => {
    const proyect = proyects.find((el) => el.nombre === selectedProyecto);
    const { data } = await axiosInstance.get(`/facturacion/informe-proyectos?proy=${proyect.id}`);
    const format = data.map((el) => ({
      ...el,
      fecha: dayjs(el.fecha).format('DD-MM-YYYY'),
      type: el.rendicion_tipo.nombre,
      title: el.rendicion_gastos.title,
      monto_parse: `$ ${thousandSeparator(el.monto)}`,
    }));
    setGastos(format);
  };

  useEffect(() => {
    dispatch(getProyects());
    dispatch(getTipoGastos());
  }, []);

  const permitedRenderDataColumn = [
    { title: 'ID Rendicion', value: 'rendicion_id', hide: true },
    { title: 'Rendicion', value: 'title' },
    { title: 'N.º Dcto.', value: 'numero_documento' },
    { title: 'Monto', value: 'monto_parse' },
    { title: 'Tipo', value: 'type' },
    { title: 'Categoria', value: 'tipo_gasto' },
    { title: 'Acciones', value: 'informe_proyecto' },
  ];

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  useEffect(() => {
    if (gastos.length) {
      let total = 0;
      gastos.forEach((el) => {
        total += el.monto;
      });
      setTotales(total);
    } else {
      setTotales(0);
    }
  }, [gastos]);

  const handleDelete = (params) => {
    const id = params.id;
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, 'Facturacion Proyectos', false);
  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Informe Proyectos</h1>
          <BreadCrumb origin={'Rendicion de gastos'} current={'Informe Proyectos'} />
        </div>
      </div>
      <form onSubmit={handleSubmit(handleSumbitProyect)} className="flex  my-5">
        <div className="flex justify-between w-full">
          <div className="flex gap-2">
            <div className="w-[600px]">
              <TextField
                size="small"
                className="w-[80%] md:w-[90%]"
                label="Proyecto"
                select
                value={selectedProyecto}
                onChange={handleProyecto}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  },
                }}
              >
                {proyects?.map((pro) => (
                  <MenuItem key={pro.id} value={pro.nombre}>
                    {pro.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <Button onClick={handleSumbitProyect} variant="outlined">
              Buscar
            </Button>
          </div>
          {gastos.length ? <div className="flex items-center font-bold mr-5">Total: $ {totales.toLocaleString()}</div> : <></>}
        </div>
      </form>
      <div>
        <ControlledDataGridComponent
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={columns}
          rows={gastos}
          success={success}
        />
      </div>
    </div>
  );
}
