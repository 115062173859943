import * as React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ProyectForm from "./ProyectForm";

export default function ProyectModal({ data, type = "create" }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: 1,
  };

  return (
    <div>
      {type === "create" && <EditOutlinedIcon onClick={handleOpen} sx={{ cursor: "pointer" }} />}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{ backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.5)", backdropFilter: "blur(5px)" } } }}
      >
        <Box sx={style}>
          <div className="w-[450px] md:w-[550px] lg:w-[750px]">
            <ProyectForm data={data} viewType={type} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
