import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React, { useCallback, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ArticleIcon from "@mui/icons-material/Article";
import dayjs from "dayjs";
import { MenuItem, TextField, Tooltip } from "@mui/material";
import Link from "@mui/material/Link";
import enviroments from "../../utils/enviroments";
import { useSelector } from "react-redux";
import RowModal from "./RowModal";
import MessageIcon from "@mui/icons-material/Message";
import thousandSeparator from "../../utils/thousandSeparator";
import CommentaryDialog from "./CommentaryDialog";
import CommentaryViewDialog from "./CommentaryViewDialog";
import DeleteModal from "../../components/deleteModal/DeleteModal";

const states = [
  { id: 1, name: "BOLETA" },
  { id: 2, name: "FACTURA" },
  { id: 3, name: "COMPROBANTE" },
];

const RowInput = React.memo(({ index, row, onDelete, type, onUpdate, rows, fundState, setDetailState, detailState, loadedData, valForm }) => {
  const { item, date, documentNumber, amount, expenseType, category, paisId } = row;
  const { categories } = useSelector((state) => state.fundAccounting);
  const { paises } = useSelector((state) => state.paises);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const handleDelete = () => {
    onDelete(index);
  };

  return (
    <div className="bg-gray-100 grid grid-cols-8">
      <div className="border-r-[1px] border-b-[1px] w-full border-gray-400 flex items-center justify-center text-sm">
        <Tooltip title={index + 1}>
          <div className="line-clamp-1">{index + 1}</div>
        </Tooltip>
      </div>
      <div className="border-r-[1px] border-b-[1px] w-full border-gray-400 flex items-center justify-center text-sm">
        <Tooltip title={dayjs(row.fecha).format("DD-MM-YYYY")}>
          <div className="line-clamp-1">{dayjs(row.fecha).format("DD-MM-YYYY")}</div>
        </Tooltip>
      </div>
      <div className="border-r-[1px] border-b-[1px] w-full border-gray-400 flex items-center justify-center text-sm">
        <Tooltip title={row.numero_documento}>
          <div className="line-clamp-1">{row.numero_documento}</div>
        </Tooltip>
      </div>
      <div className="border-r-[1px] border-b-[1px] w-full border-gray-400 flex items-center justify-center text-sm">
        <Tooltip title={row.monto}>
          <div className="line-clamp-1">{thousandSeparator(row.monto) || "S/D"}</div>
        </Tooltip>
      </div>
      <div className="border-r-[1px] border-b-[1px] w-full border-gray-400 flex items-center justify-center text-sm">
        <Tooltip title={row.tipo_gasto}>
          <div className="line-clamp-1 capitalize">{row.tipo_gasto || "S/D"}</div>
        </Tooltip>
      </div>
      <div className="border-r-[1px] border-b-[1px] w-full border-gray-400 flex items-center justify-center text-sm">
        <Tooltip title={row.rendicion_tipo_id}>
          <div className="line-clamp-1">{categories?.find((c) => c.id === row.rendicion_tipo_id)?.nombre || "S/D"}</div>
        </Tooltip>
      </div>
      <div className="border-r-[1px] border-b-[1px] w-full border-gray-400 flex items-center justify-center text-sm">
        <Tooltip title={paises.find((c) => c.id === row.paisId)?.name}>
          <div className="line-clamp-1">{paises?.find((c) => c.id === row.paisId)?.name || "S/D"}</div>
        </Tooltip>
      </div>
      {(type === "view" && loadedData.rendicion_detalle?.length) || type === "vb" ? (
        <div className="w-full px-2  border-gray-400 border-b-[1px] flex items-center justify-center">
          {type === "view" && (
            <div className="flex gap-3 mx-2">
              <div className={`w-6 h-6 ${row.state === "APROBAR" ? "bg-green-400" : "bg-neutral-500"} rounded-full`}></div>
              <div
                onClick={() => setOpenViewDialog(true)}
                className={`w-6 h-6 ${row.state === "OBSERVAR" ? "bg-yellow-400" : "bg-neutral-500"} cursor-pointer rounded-full`}
              ></div>
              <div
                onClick={() => setOpenViewDialog(true)}
                className={`w-6 h-6 ${row.state === "RECHAZAR" ? "bg-red-400" : "bg-neutral-500"} cursor-pointer rounded-full`}
              ></div>
            </div>
          )}
          {row.documento && (
            <Link href={`${enviroments.base_url}/uploads/${row.documento}`} target="_blank" rel="noreferrer noopener" className="hover:underline">
              <IconButton color="primary" size="small">
                <ArticleIcon />
              </IconButton>
            </Link>
          )}
          <div className="flex gap-2 w-full py-2">
            {type === "vb" && (
              <>
                <TextField
                  fullWidth
                  InputProps={{ style: { fontSize: 12 } }} // font size of input text
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  label="Selecionar Opción"
                  size="small"
                  select
                  onChange={(e) => {
                    setDetailState((prev) => {
                      const updatedState = { ...prev };

                      if (updatedState[row.id]) {
                        updatedState[row.id].state = e.target.value;
                      } else {
                        updatedState[row.id] = { state: e.target.value };
                      }

                      return updatedState;
                    });
                  }}
                >
                  <MenuItem dense divider value={"APROBAR"}>
                    Aprobar
                  </MenuItem>
                  <MenuItem dense divider value={"OBSERVAR"}>
                    Aprobar con Obs
                  </MenuItem>
                  <MenuItem dense divider value={"RECHAZAR"}>
                    Rechazar
                  </MenuItem>
                </TextField>
                {(detailState[row.id]?.state === "OBSERVAR" || detailState[row.id]?.state === "RECHAZAR") && (
                  <IconButton onClick={() => setOpenDialog(true)} color="primary" size="small">
                    <MessageIcon />
                  </IconButton>
                )}
              </>
            )}

            <IconButton onClick={() => setOpen(true)} color="success" size="small">
              {fundState === "RECHAZADO" ? <EditIcon /> : <VisibilityIcon />}
            </IconButton>
            <RowModal
              rows={rows}
              type={fundState === "RECHAZADO" ? "update" : "view"}
              viewType={type}
              data={row}
              openUpdate={open}
              updateSetter={setOpen}
              onUpdate={onUpdate}
            />
            <CommentaryDialog open={openDialog} setOpen={setOpenDialog} detailState={detailState} setDetailState={setDetailState} id={row.id} />
            <CommentaryViewDialog open={openViewDialog} setOpen={setOpenViewDialog} commentary={row?.commentary || ""} />
          </div>
        </div>
      ) : (
        <div className="w-full px-2  border-gray-400 border-b-[1px] flex items-center justify-center">
          <IconButton
            disabled={(fundState === "APROBADO" || fundState === "SOLICITADO") && !loadedData?.anticipo?.length}
            onClick={() => setOpen(true)}
            color="success"
            size="small"
          >
            <EditIcon />
          </IconButton>
          <RowModal rows={rows} type="update" data={row} loadedData={loadedData} viewType={type} openUpdate={open} updateSetter={setOpen} onUpdate={onUpdate} />
          <DeleteModal handleDelete={onDelete} params={row} name={"GASTO"} custom={"¿Estas seguro que deseas eliminar el siguiente gasto?"} />
          {/* <IconButton color="error" size="small" onClick={handleDelete}>
              <DeleteOutlineOutlinedIcon />
            </IconButton> */}
        </div>
      )}
    </div>
  );
});

export default RowInput;
