import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { useDispatch } from 'react-redux';
import { setNotification } from '../redux/slices/notification';
import { useNavigate } from 'react-router-dom';
import uploadFiles from '../utils/UploadFiles';
import InventoryEntryTransit from '../components/InventoryEntryTransit';
import EquipamentTransitAccordion from '../components/accordion/IncorporatoinTransitAccordion/EquipamentTransitAccordion';
import RepairPartsTransitAccordion from '../components/accordion/IncorporatoinTransitAccordion/RepairPartsTransitAccordion';
import ArticlesTransitAccordion from '../components/accordion/IncorporatoinTransitAccordion/ArticlesTransitAccordion';

const InventoryIncorporationInTransit = () => {
  const [inventoryEntry, setInventoryEntry] = useState({});
  const [equipos, setEquipos] = useState([]);
  const [repuestos, setRepuestos] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    if (inventoryEntry?.file) {
      const { data } = await uploadFiles('docs', inventoryEntry.file);
      inventoryEntry.file = data;
    }
    if (inventoryEntry?.factura) {
      const { data } = await uploadFiles('docs', inventoryEntry.factura);
      inventoryEntry.factura = data;
    }
    const res = await axiosInstance.post('/inventory/incorporation-transito', { inventoryEntry, equipos, repuestos, articulos });
    if (res.status === 201) {
      setLoading(false);
      dispatch(
        setNotification({
          status: 'success',
          message: 'Incorporacion creada exitosamente',
          open: true,
        })
      );
      setInventoryEntry({});
      setEquipos([]);
      setRepuestos([]);
      setArticulos([]);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      setLoading(false);
      dispatch(
        setNotification({
          status: 'Error',
          message: 'Error al crear incorporacion',
          open: true,
        })
      );
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center border-gray p-0 sm:p-5 rounded">
        <div className="w-full">
          <h1 className="font-semibold text-[#1E293B] text-2xl">Incorporacion en Tránisto</h1>
          <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full my-5 flex justify-center rounded">
              <div className="w-full">
                <InventoryEntryTransit setIsDisabled={setIsDisabled} setInventoryEntry={setInventoryEntry} />
              </div>
            </div>
            <div className="w-full flex-col flex justify-center p-5 rounded">
              <div>
                <EquipamentTransitAccordion equipos={equipos} setEquipos={setEquipos} />
              </div>
            </div>
            <div className=" flex-col w-full flex justify-center p-5 rounded">
              <RepairPartsTransitAccordion repuestos={repuestos} setRepuestos={setRepuestos} />
            </div>
            <div className="w-full flex-col flex justify-center p-5 rounded">
              <ArticlesTransitAccordion articulos={articulos} setArticulos={setArticulos} />
            </div>
            <Button disabled={isDisabled || loading} sx={{ mt: 5 }} variant="contained" fullWidth onClick={handleSubmit}>
              Enviar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryIncorporationInTransit;
