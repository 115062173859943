import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import BreadCrumb from '../../BreadCrumb/BreadCrumb';
import { useEffect, useState } from 'react';
import useDataGrid from '../../../hooks/useDataGrid';
import { deleteArticle, getAllArticles, getAllStatusSelect, getAllStorages, setArticles } from '../../../redux/slices/inventory';
import ArticleCreate from '../../modal/ArticleCreate';
import dayjs from 'dayjs';
import DataTransitionComponent from './DataTransitionComponent';
import ArticleTransitionCreate from '../../modal/ArticleTransitionCreate';
import { InputAdornment, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
const createArr = [
  { value: 'codigo_loginteg', title: 'Código Loginteg', minWidth: 170 },
  { value: 'nombre', title: 'Nombre', minWidth: 170 },
  { value: 'cantidad', title: 'Cantidad', minWidth: 100 },
  { value: 'lotes', title: 'Lotes', minWidth: 100 },
  { value: 'fecha_vencimiento', title: 'Fecha de vencimiento', minWidth: 100 },
  { title: 'Valor', value: 'valueCost' },
  { title: 'Estado', value: 'estadoText' },
  { title: 'Eliminar', value: 'deleteTeam' },
];

const editArr = [
  { value: 'nombre', title: 'Nombre', minWidth: 170 },
  { value: 'cantidad', title: 'Cantidad', minWidth: 100 },
  { value: 'lotes', title: 'Lotes', minWidth: 100 },
  { value: 'fecha_vencimiento', title: 'Fecha de vencimiento', minWidth: 100 },
  { title: 'Estado', value: 'estadoText' },
];

export default function ArticlesTransitAccordion({ articulos, setArticulos, edit, setArticulosCheck, articulosCheck }) {
  const [id, setId] = useState(1);

  const permitedRenderDataColumn = edit ? editArr : createArr;
  const dispatch = useDispatch();
  const [articlesToRender, setArticlesToRender] = useState([]);
  const { articles, success } = useSelector((state) => state.inventory);
  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  const handleDelete = (params) => {
    setArticulos(articulos.filter((rep) => rep.id !== params));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, 'Insumos', false, setOpen);

  useEffect(() => {
    dispatch(getAllArticles());
    dispatch(getAllStorages());
    dispatch(getAllStatusSelect());
  }, [dispatch]);

  useEffect(() => {
    const newArticles = articulos.map((articulo) => ({
      ...articulo,
      fecha_vencimiento: dayjs(articulo.fecha_vencimiento).format('DD/MM/YYYY'),
      estadoText: articulo?.estadoText ? articulo.estadoText : articulo.bodegaId ? articulo?.bodega?.name : articulo?.inv_estado?.descripcion,
    }));

    setArticlesToRender(newArticles);
  }, [articulos]);

  return (
    <div>
      <Accordion>
        <AccordionSummary sx={{ bgcolor: '#e4dadab8', borderRadius: 1 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <div className="flex justify-center font-bold uppercase tracking-wider text-sm">Recepción de Insumos</div>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: '#fdf9f9', borderBottomLeftRadius: 1, borderBottomRightRadius: 1 }}>
          <div>
            <div className={`flex justify-end mb-5 ${edit && 'hidden'}`}>
              <div>
                <ArticleTransitionCreate id={id} setId={setId} articulos={articulos} setArticulos={setArticulos} />
              </div>
            </div>
            {!edit ? (
              <DataTransitionComponent columns={columns} rows={articulos} success={success} />
            ) : (
              <>
                <div className="grid grid-cols-7 mb-4">
                  <div className="col-span-2">Código Loginteg</div>
                  <div className="col-span-2">Nombre</div>
                  <div>Lotes</div>
                  <div>Cantidad</div>
                  <div>Completo</div>
                </div>
                {articulos.map((el) => (
                  <div className="grid grid-cols-7">
                    <div className="col-span-2">{el.codigo_loginteg}</div>
                    <div className="col-span-2">{el.nombre}</div>

                    <div>{el.lotes}</div>
                    <div>{el.cantidad}</div>
                    <div>
                      <Checkbox
                        checked={el.isComplete || articulosCheck.some((id) => id === el.id_complete)}
                        disabled={el.isComplete}
                        onChange={(e) => {
                          if (e.target.checked) {
                            if (!articulosCheck.some((id) => id === el.id_complete)) {
                              setArticulosCheck((prevState) => [...prevState, el.id_complete]);
                            }
                          } else {
                            setArticulosCheck((prevState) => prevState.filter((id) => id !== el.id_complete));
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
