import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllInventoryIncorporation, setIncorporation } from "../redux/slices/inventory";
import DataGridComponent from "../components/tables/DataGrid";
import useDataGrid from "../hooks/useDataGrid";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InventoryIncorporationView from "./InventoryIncorporationView";

const permitedRenderDataColumn = [
  { title: "ID Recepción", value: "id_incorporacion" },
  { value: "fecha_ingreso", title: "Fecha", minWidth: 170 },
  { value: "oc_numero", title: "Numero OC", minWidth: 170 },
  { value: "factura_numero", title: "Numero Factura", minWidth: 170 },
  { value: "nombre_proveedor", title: "Proveedor", minWidth: 170 },
  { value: "numero_documento", title: "N documento", minWidth: 100 },
  {
    value: "acciones_incorporation",
    title: "Acciones",
    minWidth: 170,
    align: "center",
  },
];

const InventoryIncorporationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sorted, setSorted] = useState([]);
  const { incorporation } = useSelector((state) => state.inventory);

  const [open, setOpen] = useState({
    status: false,
    data: null,
  });

  useEffect(() => {
    if (incorporation.length) {
      const sortByDate = [...incorporation]
        .sort((a, b) => new Date(b.fecha_ingreso) - new Date(a.fecha_ingreso))
        .map((el, index) => ({ ...el, dateIndex: index }));
      setSorted(sortByDate);
    }
  }, [incorporation]);

  useEffect(() => {
    dispatch(getAllInventoryIncorporation({ type: 1 }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setIncorporation([]));
    };
  }, [dispatch]);

  const [columns] = useDataGrid(permitedRenderDataColumn, null, "Incorporación Inventario", false, setOpen);

  if (open.data) {
    return <InventoryIncorporationView data={open.data} setter={setOpen} />;
  }
  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Incorporación Inventario</h1>
          <BreadCrumb origin={"Inventario"} current={"Incorporación Inventario"} />
        </div>
        <div>
          <Button onClick={() => navigate("/inventory-incorporation/create")} variant="outlined">
            Crear Incorporación
          </Button>
        </div>
      </div>

      <DataGridComponent columns={columns} rows={sorted} success={true} />
    </div>
  );
};

export default InventoryIncorporationList;
