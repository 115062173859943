import HandledTextField from "../../components/HandledTextField";
import { useState, useEffect } from "react";
import RowInput from "./RowInput";
import { Box, Button, Chip } from "@mui/material";
import RowModal from "./RowModal";
import { useForm } from "react-hook-form";
import { getCurrencies } from "../../redux/slices/moneda";
import { getBanks } from "../../redux/slices/banco";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { useDispatch, useSelector } from "react-redux";
import ValidationDialog from "./ValidationDialog";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  createFundAccounting,
  deleteExpense,
  getAllBills,
  getExpenseClaimCategories,
  updateFundAccounting,
  updateFundAccountingFields,
  updatePendingButton,
} from "../../redux/slices/fundAccounting";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import axiosInstance from "../../utils/axiosInstance";
import thousandSeparator from "../../utils/thousandSeparator";
import RejectDialog from "./RejectDialog";
import PDFRendicion from "../../components/PDF/PDFRendicion";
import { getAllProyectsBilling } from "../../redux/slices/facturacion";
import { getOtSelect, setOT } from "../../redux/slices/workOrders";
import enviroments from "../../utils/enviroments";
import uploadFiles from "../../utils/UploadFiles";
import paises, { getAllPaises } from "../../redux/slices/paises";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import CustomSelectMultiple from "../../components/CustomSelectMultiple";
import { values } from "pdf-lib";
import RendicionModal from "../../components/RendicionModal";

const states = {
  1: "SOLICITADO",
  2: "APROBADO",
  3: "FINALIZADO",
  4: "RECHAZADO",
};

const Rendicion = ({ type }) => {
  const { id } = useParams();
  const [fetched, setFetched] = useState(false);
  const [rows, setRows] = useState([]);
  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [state, setState] = useState(null);
  const [disabledByType, setDisabledByType] = useState(false);
  const [observation, setObservation] = useState(null);
  const [loadedData, setLoadedData] = useState(null);
  const [fundState, setFundState] = useState(null);
  const [globalDocument, setGlobalDocument] = useState(null);
  const [detailState, setDetailState] = useState({});
  const [preview, setPreview] = useState(null);
  const [ots, setOts] = useState([]);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const hiddenByType = type === "vb" ? true : false;
  const backUrlByType = location?.state?.from?.pathname || "/rendicion-de-fondos";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currency } = useSelector((state) => state.currency);
  const { banks } = useSelector((state) => state.banks);
  const { currentUser } = useSelector((state) => state.auth);
  const { select } = useSelector((state) => state.workOrders);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    reset,
  } = useForm({ mode: "onChange" });

  const valForm = watch();
  useEffect(() => {
    dispatch(getCurrencies());
    dispatch(getOtSelect());
    dispatch(getBanks());
    dispatch(getAllProyectsBilling());
    dispatch(getExpenseClaimCategories());
    dispatch(getAllPaises());
  }, [dispatch]);

  const uploadImage = async (e) => {
    const uploaded = await uploadFiles("docs", e.target.files[0]);
    setPreview(uploaded.data);
  };

  const fetchEditData = async () => {
    const { data } = await axiosInstance.get(`/fund-accounting/${id}`);
    setGlobalDocument(data.documento);
    setLoadedData(data);
    setFundState(states[data.estado_id]);
    setDisabledByType(
      (!!data?.anticipo && !data?.rendicion_detalle.length) || type === "pending" ? false : type === "create" || data.estado_id === 4 ? false : true
    );
    reset({
      observacion_general: data?.observacion_general,
      fecha_rendicion: dayjs(data.fecha_rendicion),
      monto_rendir: data.monto_rendir,
      proyecto_id: data.proyecto_id,
      devolucion: data.devolucion || "0",
      banco_id: data?.banco_id || -1,
      numero_cuenta: data.numero_cuenta,
      moneda_id: data.moneda_id,
      user: data?.usuario?.nombre,
      title: data?.title,
    });
    setOts(data?.orden_servicio.length ? data.orden_servicio.map((el) => el.id) : [-1]);
    const rows = data.rendicion_detalle.map((row) => {
      return {
        ...row,
        // item: row.item,
        detail: row.detalle,
        date: row?.fecha ? dayjs(row?.fecha) : row.date,
        documentNumber: row.numero_documento,
        amount: row.monto,
        expenseType: row.tipo_gasto,
        category: row.rendicion_tipo_id,
        documento: row.documento,
        initialKm: row.initialKm,
        finalKm: row.finalKm,
        liters: row.liters,
        usage: row.usage,
        rendicion_viaje: row.rendicion_viaje,
      };
    });

    setObservation(data.observacion);
    setState(data.estado_id);
    setRows(rows);
    setFetched(true);
  };

  useEffect(() => {
    setValue("monto_rendir", thousandSeparator(rows.reduce((acc, obj) => acc + obj.monto || 0, 0)));
  }, [rows]);

  useEffect(() => {
    if (rows.length && !!!loadedData?.anticipo.length) {
      setValue(
        "devolucion",
        rows.reduce((acc, obj) => acc + obj.monto || 0, 0)
      );
    }

    if (rows.length && (type === "view" || type === "pending") && !!loadedData?.anticipo.length) {
      const rowsVal = rows.reduce((acc, obj) => acc + obj.monto || 0, 0);
      setValue("devolucion", parseInt(rowsVal - loadedData?.anticipo[0]?.anticipo));
    }
  }, [rows]);

  useEffect(() => {
    if (type === "view" || type === "vb" || type === "pending") {
      fetchEditData();
    } else {
      reset({
        orden_servicio: -1,
        user: currentUser.nombre,
        banco_id: currentUser.bancosId,
        numero_cuenta: currentUser.account,
      });
    }
    return () => setLoadedData(null);
  }, []);

  const deleteRow = async ({ id }) => {
    const deleted = await dispatch(deleteExpense(id));
    setRows((prev) => [...prev.filter((el) => el.id !== deleted)]);
  };

  const submit = async (value) => {
    // return console.log(rows);
    value.ots = ots;
    if (preview) {
      value.documento = preview;
    }

    if (!value?.fecha_rendicion) {
      value.fecha_rendicion = dayjs();
    }
    value.monto_rendir = typeof value.monto_rendir === "string" ? parseInt(value.monto_rendir.replaceAll(".", "")) : value.monto_rendir;
    value.devolucion = typeof value.devolucion === "string" ? parseInt(value.devolucion.replaceAll(".", "")) : value.devolucion;

    if (!fundState) {
      dispatch(updateFundAccountingFields({ ...value, fundId: rows[0]?.rendicion_id, isCreate: true }));
    }
    if (fundState === "RECHAZADO" || (!!loadedData?.anticipo && !loadedData.rendicion_detalle.length) || type === "pending") {
      dispatch(updateFundAccountingFields({ ...value, fundId: parseInt(id) }));
    }
    // return console.log(rows[0], "aca");

    // if (fundState === "RECHAZADO" || (!!loadedData?.anticipo && !loadedData.rendicion_detalle.length)) {
    //   // return console.log(value);
    //   dispatch(updateFundAccounting({ value, rows, id }));
    //   return navigate(backUrlByType);
    // }

    // await dispatch(createFundAccounting({ currentUser, value, rows }));
    navigate(backUrlByType);
  };

  const backPending = async (value) => {
    value.ots = ots;
    value.id = id;
    if (preview) {
      value.documento = preview;
    }

    if (!value?.fecha_rendicion) {
      value.fecha_rendicion = dayjs();
    }
    value.monto_rendir = typeof value.monto_rendir === "string" ? parseInt(value.monto_rendir.replaceAll(".", "")) : value.monto_rendir;
    value.devolucion = typeof value.devolucion === "string" ? parseInt(value.devolucion.replaceAll(".", "")) : value.devolucion;

    const status = await dispatch(updatePendingButton(value));
    if (status === 200) {
      navigate("/rendicion-de-fondos");
    }
  };

  const handleReject = () => {
    setOpenRejectDialog(true);
  };
  const handleAprove = async (mail) => {
    await axiosInstance.put(`/fund-accounting/aprove/${id}`, { detailState, mail, devolucion: loadedData.devolucion });
    navigate("/rendicion-de-fondos/vb");
  };

  if (type === "vb" && state === 4) {
    return (
      <div className="text-red-500 bg-red-100 p-2 rounded">
        <div className="text-center">
          <strong className="bg-text-500 tracking-wider">!Error</strong>
          <div>La rendición que intentas visualizar se encuentra rechazada</div>
        </div>
      </div>
    );
  }

  if ((type !== "create" && fetched) || type === "create" || type === "pending") {
    return (
      <form onSubmit={handleSubmit(submit)}>
        <div className="bg-[#2b2c84] mb-2 w-full h-10 rounded flex justify-between items-center pr-5">
          <div className="flex items-center gap-2">
            <IconButton onClick={() => navigate(backUrlByType)}>
              <ArrowBackIcon sx={{ color: "#fff" }} />
            </IconButton>
            <div className="text-xl font-semibold text-white">Rendición de fondos {type !== "create" ? ` - N° ${loadedData?.id}` : ""}</div>
          </div>
          <div className="flex gap-5">
            {type !== "create" && loadedData !== null && <PDFRendicion data={loadedData} rows={rows} />}
            {type === "create" ||
            (!!loadedData?.anticipo && !loadedData.rendicion_detalle.length) ||
            type === "pending" ||
            (type === "view" && fundState === "RECHAZADO") ? (
              <>
                <Button
                  endIcon={!preview || fundState === "RECHAZADO" ? <FileUploadIcon /> : <FileDownloadDoneIcon />}
                  sx={{ color: "white", fontWeight: "bold" }}
                  component="label"
                >
                  Subir Documento Gastos
                  <input
                    type="file"
                    hidden
                    onChange={uploadImage}
                    accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,image/jpeg"
                  />
                </Button>
                {preview && (
                  <a className="mt-1 -ml-2" target="_blank" download href={`${enviroments.img_endpoint}${preview}`}>
                    <InsertPhotoIcon color="success" />
                  </a>
                )}
              </>
            ) : (
              globalDocument && (
                <Button endIcon={<DownloadIcon />} sx={{ color: "white", fontWeight: "bold" }} component="label">
                  <a href={`${enviroments.img_endpoint}${globalDocument}`} target="_blank" download>
                    Descargar Documento Gastos
                  </a>
                </Button>
              )
            )}
          </div>
          <div className="flex">
            <div className="flex"></div>
          </div>
        </div>
        {!!loadedData?.anticipo?.length && (
          <div className="w-full text-center mb-1 text-neutral-500">
            La presente rendición ha sido documentada con un anticipo de{" "}
            <b className="text-green-500"> ${thousandSeparator(loadedData?.anticipo[0]?.anticipo)} </b>.
          </div>
        )}
        {loadedData?.pagada && (
          <div className="w-full text-center mb-1 text-neutral-500">
            La presente rendición ha sido marcada como <b className="text-green-500">PAGADA</b>
          </div>
        )}
        <div className="flex w-full gap-2 max-md:flex-col">
          <div className="w-full">
            <HandledTextField
              props={{
                field: "title",
                label: "Título",
                error: errors?.title?.type,
                separator: false,
                control,
                disabledByType,
              }}
            />
            <div className="mb-2">
              <CustomSelectMultiple
                isCustom={true}
                id="orden_servicio"
                name="orden_servicio"
                label={"Orden de trabajo"}
                value={ots}
                disabled={disabledByType}
                onChange={(e) => setOts(e)}
                options={[{ id: -1, nombre: "Sin orden de trabajo" }].concat(
                  select.map((el) => ({
                    id: el.id,
                    nombre: `OT ${el.id} - ${el.cliente_ot_clienteTocliente.nombre}`,
                  }))
                )}
              />
            </div>

            <HandledTextField
              props={{
                field: "fecha_rendicion",
                label: "Fecha",
                date: true,
                error: errors?.fecha_rendicion?.type,
                setValue,
                control,
                disabledByType,
              }}
            />
            <HandledTextField
              props={{
                field: "monto_rendir",
                label: "Monto a rendir",
                error: errors?.monto_rendir?.type,
                pattern: /^[0-9]/,
                separator: true,
                control,
                disabledByType,
              }}
            />
            <HandledTextField
              props={{
                field: "moneda_id",
                label: "Moneda",
                select: true,
                list: currency,
                error: errors?.moneda_id?.type,
                control,
                disabledByType,
              }}
            />
          </div>
          <div className="w-full">
            <HandledTextField
              props={{
                field: "devolucion",
                label: "Devolucion",
                error: errors?.devolucion?.type,
                pattern: /^[0-9]/,
                separator: true,
                control,
                disabledByType: type === "create" ? false : true,
              }}
            />
            <HandledTextField
              props={{
                field: "banco_id",
                label: "Banco",
                select: true,
                list: [{ id: -1, nombre: "EFECTIVO" }].concat(banks),
                error: errors?.banco_id?.type,
                control,
                disabledByType,
              }}
            />
            <HandledTextField
              props={{
                field: "numero_cuenta",
                label: "Cuenta",
                error: errors?.numero_cuenta?.type,
                pattern: /^[0-9]/,
                account: true,
                control,
                disabledByType,
              }}
            />
            <HandledTextField
              props={{
                field: "user",
                label: "Solicitante",
                control,
                disabledByType: true,
              }}
            />
          </div>
        </div>
        <div className="bg-[#2b2c84] w-full my-2 rounded h-9 flex justify-end">
          {(type === "create" || fundState === "RECHAZADO" || type === "pending" || (!!loadedData?.anticipo && !loadedData.rendicion_detalle.length)) && (
            <div>
              {valForm.banco_id && valForm.moneda_id && valForm.numero_cuenta && (
                <RowModal ots={ots} valForm={valForm} rows={rows} state={fundState} viewType={type} onCreate={setRows} />
              )}
            </div>
          )}
          <ValidationDialog type={type} open={openValidationDialog} setOpen={setOpenValidationDialog} />
          <RejectDialog id={id} open={openRejectDialog} setOpen={setOpenRejectDialog} detailState={detailState} />
        </div>
        <div>
          <div>
            <div className="w-full grid grid-cols-8 bg-gray-100 rounded text-gray-600 text-sm font-bold">
              <div className="w-full pl-2 border-b-[1px] py-1 text-center border-gray-400">N°</div>
              <div className="w-full pl-2 border-b-[1px] py-1 text-center border-gray-400">Fecha</div>
              <div className="w-full pl-2 border-b-[1px] py-1 text-center border-gray-400">N.º Dcto.</div>
              <div className="w-full pl-2 border-b-[1px] py-1 text-center border-gray-400">Monto ($)</div>
              <div className="w-full pl-2 border-b-[1px] py-1 text-center border-gray-400">Tipo</div>
              <div className="w-full pl-2 border-b-[1px] py-1 text-center border-gray-400">Categoria</div>
              <div className="w-full pl-2 border-b-[1px] py-1 text-center border-gray-400">País</div>
              <div className={`w-full pl-2 border-b-[1px] py-1 text-center border-gray-400`}>Acciones</div>
            </div>
            {/* <div className="w-full min-h-[140px] max-h-[155px] overflow-y-auto text-center"> */}
            <div className="w-full min-h-[140px] text-center">
              {rows.length ? (
                rows.map((row, index) => (
                  <RowInput
                    loadedData={loadedData}
                    detailState={detailState}
                    setDetailState={setDetailState}
                    fundState={fundState}
                    onUpdate={setRows}
                    type={type}
                    key={index}
                    index={index}
                    row={row}
                    rows={rows}
                    onDelete={deleteRow}
                  />
                ))
              ) : (
                <div className="text-xs uppercase border-[1px] border-[#2b2c84] mt-3 h-[125px] flex justify-center items-center text-[#2b2c84] rounded p-2 ">
                  {!valForm.banco_id || !valForm.moneda_id || !valForm.numero_cuenta || !valForm.orden_servicio ? (
                    <>Por favor complete los datos necesarios para la rendición</>
                  ) : (
                    <>No hay datos para visualizar</>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="bg-[#2b2c84] w-full my-2 rounded flex justify-end h-10" />
          <div className="mt-5 w-full flex justify-between gap-3 max-lg:flex-col">
            <div className="w-[70%]">
              <div className="w-full h-full  rounded">
                <div className="bg-gray-100">
                  <div className="w-full text-center uppercase font-bold text-gray-600 bg-gray-300 p-2 rounded text-sm tracking-wider">Resumen</div>
                  <div className="w-full flex justify-center py-2">
                    <span className="ml-2 text-gray-600 text-sm font-bold">
                      Monto en (<span className="text-green-600">{currency?.find((curr) => curr.id === watch("moneda_id"))?.nombre || "$"}</span>)
                    </span>
                  </div>
                </div>
                <div className="bg-gray-100 mt-2">
                  <div className="flex">
                    <div className="w-full">
                      <div className="w-full text-center uppercase font-bold text-gray-600 bg-gray-300 p-2 rounded-l text-sm tracking-wider">Boletas</div>
                      <div className="w-full flex justify-center py-2">
                        <Chip
                          size="small"
                          color="success"
                          variant="outlined"
                          sx={{ minWidth: "20%", ml: 1 }}
                          label={"$ " + thousandSeparator(rows.filter((row) => row.tipo_gasto === "BOLETA").reduce((acc, obj) => acc + obj.monto, 0))}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="w-full text-center uppercase font-bold text-gray-600 bg-gray-300 p-2 rounded-l text-sm tracking-wider">Comprobantes</div>
                      <div className="w-full flex justify-center py-2">
                        <Chip
                          size="small"
                          color="success"
                          variant="outlined"
                          sx={{ minWidth: "20%", ml: 1 }}
                          label={"$ " + thousandSeparator(rows.filter((row) => row.tipo_gasto === "COMPROBANTE").reduce((acc, obj) => acc + obj.monto, 0))}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="w-full text-center uppercase font-bold text-gray-600 bg-gray-300 p-2 rounded-r text-sm tracking-wider">Factura</div>
                      <div className="w-full flex justify-center py-2">
                        <Chip
                          size="small"
                          color="success"
                          variant="outlined"
                          sx={{ minWidth: "20%", ml: 1 }}
                          label={"$ " + thousandSeparator(rows.filter((row) => row.tipo_gasto === "FACTURA").reduce((acc, obj) => acc + obj.monto, 0))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-100 mt-2">
                  <div className="w-full text-center uppercase font-bold text-gray-600 bg-gray-300 p-2 rounded text-sm tracking-wider">Total</div>
                  <div className="w-full flex justify-center py-3">
                    <Chip
                      size="small"
                      color="success"
                      variant="outlined"
                      sx={{ minWidth: "10%", ml: 1 }}
                      label={"$ " + thousandSeparator(rows.reduce((acc, obj) => acc + obj.monto, 0))}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-[30%] max-sm:flex-col max-sm:gap-2">
              <div className="w-full max-lg:h-[160px]">
                <div
                  className={`w-full flex justify-center items-center h-full border-[1px] bg-gray-100  rounded relative border-gray-300 border-[1px]"
                  }`}
                >
                  <span className="text-2xl text-neutral-500 uppercase tracking-wider">
                    {type === "create" ? "Pendiente" : type === "pending" ? "GUARDADA" : fundState !== "RECHAZADO" && fundState}
                  </span>
                  {observation && (
                    <div className="h-full bg-red-100 w-full flex flex-col pt-9 px-4 max-h-full overflow-auto">
                      <div className="rounded font-bold text-red-500">Rechazada por el siguiente motivo: </div>
                      <div className="">{observation}</div>
                    </div>
                  )}
                  <div
                    className={`p-2 uppercase text-sm font-bold text-gray-600 absolute top-0 pointer-events-none ${
                      observation && "bg-red-100 left-0 rounded py-2 w-[90%]"
                    }`}
                  >
                    Estado de la rendicion
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-4">
            <HandledTextField
              props={{
                field: "observacion_general",
                label: "Observación general",
                error: errors?.observacion_general?.type,
                control,
                disabledByType,
              }}
            />
          </div>
          {!hiddenByType ? (
            <div className="w-full flex justify-center  items-center gap-10">
              {type === "pending" && (
                <Button onClick={handleSubmit(backPending)} color="warning" disabled={disabledByType} variant="contained" sx={{ mt: 2 }}>
                  GUARDAR
                </Button>
              )}
              <Button disabled={disabledByType} type="submit" variant="contained" sx={{ mt: 2 }}>
                SOLICITAR
              </Button>
            </div>
          ) : (
            <div className="flex gap-5">
              <Button onClick={handleReject} variant="contained" color="error" sx={{ mt: 2 }} fullWidth>
                Rechazar
              </Button>
              <Button onClick={() => setOpen(true)} variant="contained" sx={{ mt: 2 }} fullWidth>
                Aprobar
              </Button>
              {/* <Button onClick={handleAprove} variant="contained" sx={{ mt: 2 }} fullWidth>
                Aprobar
              </Button> */}
              <RendicionModal open={open} setOpen={setOpen} handleSubmit={handleAprove} />
            </div>
          )}
        </div>
      </form>
    );
  }
};

export default Rendicion;
