import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../utils/axiosInstance';
import { setNotification } from '../notification';
import dayjs from 'dayjs';

const initialState = {
  facturacion: [],
  facturaId: {},
  facturacionProyectos: [],
  facturacionTipo: [],
  open: false,
  success: true,
};

const facturacionSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setFacturacion: (state, action) => {
      state.facturacion = action.payload;
      state.success = true;
    },
    removeFacturacion: (state, action) => {
      state.facturacion = state.facturacion.filter((el) => el.id !== action.payload);
    },
    setFacturaId: (state, action) => {
      state.facturaId = action.payload;
      state.success = true;
    },
    setFacturacionProyecto: (state, action) => {
      state.facturacionProyectos = action.payload;
      state.success = true;
    },
    setLoading: (state, action) => {
      state.success = true;
    },
    addFacturacionProyecto: (state, action) => {
      state.facturacionProyectos.push(action.payload);
    },
    setUpdate: (state, action) => {
      const updated = state.facturacionProyectos.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.facturacionProyectos = [...updated, action.payload];
      state.success = true;
    },
    removeFacturacionProyecto: (state, action) => {
      const updated = state.facturacionProyectos.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.facturacionProyectos = updated;
    },
    setFacturacionTipo: (state, action) => {
      state.facturacionTipo = action.payload;
      state.success = true;
    },
    addFacturacionTipo: (state, action) => {
      state.facturacionTipo.push(action.payload);
    },
    updateFacturacionTipo: (state, action) => {
      const updated = state.facturacionTipo.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.facturacionTipo = [...updated, action.payload];
      state.success = true;
    },
    removeFacturacionTipo: (state, action) => {
      const updated = state.facturacionTipo.filter((type) => {
        return type.id !== action.payload.id;
      });
      state.facturacionTipo = updated;
    },
  },
});

export const getAllFacturacionesByState = (estado) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/facturacion?status=${estado}`);
    const newData = res.data.map((res) => {
      let text = '';
      res.cobranza?.forEach((el, indx) => (text += `${el.n_factura} ${indx !== res.cobranza.length - 1 ? '| ' : ''}`));

      return {
        ...res,
        fecha_oc_parsed: dayjs(res.fecha_oc).format('YYYY/MM/DD'),
        cliente: res.cliente_facturacion_clienteTocliente.nombre,
        equipos_length: res.facturacion_equipo.length > 0 ? 'SI' : 'NO',
        articulos_length: res.facturacion_articulos.length > 0 ? 'SI' : 'NO',
        repuestos_length: res.facturacion_repuestos.length > 0 ? 'SI' : 'NO',
        servicios_length: res.facturacion_servicios.length > 0 ? 'SI' : 'NO',
        monto_sin_iva: '$ ' + (res.monto / 1.19).toLocaleString(),
        isMultiple: res.multiple ? 'SI' : 'NO',
        iva: '$ ' + (res.monto - res.monto / 1.19).toLocaleString(),
        monto_total: '$ ' + res.monto.toLocaleString(),
        n_facturas: text,
      };
    });
    dispatch(setFacturacion(newData));
  } catch (error) {
    console.log(error);
  }
};

export const deleteInvoice = (id) => async (dispatch) => {
  try {
    const { status } = await axiosInstance.delete(`/facturacion?id=${id}`);
    if (status === 200) dispatch(removeFacturacion(id));
  } catch (error) {
    console.log(error);
  }
};

export const getFacturacionById = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/facturacion/factura/${id}`);
    dispatch(setFacturaId(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const createFacturacion = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.post('/facturacion/create', values);
    dispatch(
      setNotification({
        status: 'success',
        message: 'Orden de compra creada con éxito',
        open: true,
      })
    );
    return res;
  } catch (error) {
    dispatch(
      setNotification({
        status: 'error',
        message: 'Error al crear Orden de compra',
        open: true,
      })
    );
    return error;
  }
};

export const updateOC = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.put('/facturacion/updateOc', values);
    dispatch(
      setNotification({
        status: 'success',
        message: 'Orden de compra creada con éxito',
        open: true,
      })
    );
    return res;
  } catch (error) {
    dispatch(
      setNotification({
        status: 'error',
        message: 'Error al crear Orden de compra',
        open: true,
      })
    );
    return error;
  }
};

export const updateFacturacion = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.put('/facturacion/update', values);
    dispatch(
      setNotification({
        status: 'success',
        message: 'Orden de compra creada con éxito',
        open: true,
      })
    );
    return res;
  } catch (error) {
    dispatch(
      setNotification({
        status: 'error',
        message: 'Error al crear Orden de compra',
        open: true,
      })
    );
    return error;
  }
};

export const getAllProyectsBilling = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/facturacion/proyectos`);
    const newData = res.data.map((el) => ({
      ...el,
      pais_nombre: el.paises?.name || 'S/D',
      region_nombre: el.gen_region?.descripcion || 'S/D',
      comuna_nombre: el.gen_comuna?.descripcion || 'S/D',
    }));
    dispatch(setFacturacionProyecto(newData));
  } catch (error) {
    console.log(error);
    dispatch(
      setNotification({
        status: 'error',
        message: 'Error al traer Facturacion Proyectos',
        open: true,
      })
    );
    return error;
  }
};

export const createProyectBilling = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`/facturacion/proyectos`, values);
    const newData = {
      ...res.data,
      pais_nombre: res.data.paises?.name || 'S/D',
      region_nombre: res.data.gen_region?.descripcion || 'S/D',
      comuna_nombre: res.data.gen_comuna?.descripcion || 'S/D',
    };
    dispatch(addFacturacionProyecto(newData));
    dispatch(
      setNotification({
        status: 'success',
        message: 'Exito al crear Proyecto',
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: 'error',
        message: 'Error al crear proyecto',
        open: true,
      })
    );
    return error;
  }
};

export const updateProyectBilling = (values, id) => async (dispatch) => {
  try {
    const res = await axiosInstance.put(`/facturacion/proyectos?id=${id}`, values);
    const newData = {
      ...res.data,
      pais_nombre: res.data.paises?.name || 'S/D',
      region_nombre: res.data.gen_region?.descripcion || 'S/D',
      comuna_nombre: res.data.gen_comuna?.descripcion || 'S/D',
    };
    dispatch(setUpdate(newData));
    dispatch(
      setNotification({
        status: 'success',
        message: 'Exito al actualizar proyecto',
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: 'error',
        message: 'Error al actualizar proyecto',
        open: true,
      })
    );
    return error;
  }
};

export const removeProyectBilling = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.put(`/facturacion/proyectos/remove?id=${id}`);
    dispatch(removeFacturacionProyecto(res.data));
    dispatch(
      setNotification({
        status: 'success',
        message: 'Proyecto Eliminado con exito',
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: 'error',
        message: 'Error al eliminar proyecto',
        open: true,
      })
    );
    return error;
  }
};
//
export const getAllTypesExpensesBilling = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.get('/facturacion/tipo-gastos');
    dispatch(setFacturacionTipo(res.data));
  } catch (error) {
    dispatch(
      setNotification({
        status: 'error',
        message: 'Error al traer tipo gastos',
        open: true,
      })
    );
    return error;
  }
};

export const createTypesExpensesBilling = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.post(`/facturacion/tipo-gastos`, values);
    dispatch(addFacturacionTipo(res.data));
    dispatch(
      setNotification({
        status: 'success',
        message: 'Tipo de gasto creado con exito',
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: 'error',
        message: 'Error al crear tipo de gasto',
        open: true,
      })
    );
    return error;
  }
};

export const updateTypesExpensesBilling = (values, id) => async (dispatch) => {
  try {
    const res = await axiosInstance.put(`/facturacion/tipo-gastos?id=${id}`, values);
    dispatch(updateFacturacionTipo(res.data));
    dispatch(
      setNotification({
        status: 'success',
        message: 'Tipo de gasto actualizado con exito',
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: 'error',
        message: 'Error al actualizar tipo de gasto',
        open: true,
      })
    );
    return error;
  }
};

export const removeTypesExpensesBilling = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.put(`/facturacion/tipo-gastos/remove?id=${id}`);
    dispatch(removeFacturacionTipo(res.data));
    dispatch(
      setNotification({
        status: 'success',
        message: 'Tipo de gasto eliminado con exito',
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: 'error',
        message: 'Error al eliminar tipo de gasto',
        open: true,
      })
    );
    return error;
  }
};

export const getFacturacionByDate = (values) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/facturacion/date?month=${values.month}&year=${values.year}`);

    dispatch(setFacturacion(res.data));
  } catch (error) {
    dispatch(setLoading());
  }
};

export const {
  setFacturacion,
  setFacturaId,
  setFacturacionProyecto,
  setLoading,
  addFacturacionProyecto,
  setUpdate,
  removeFacturacionProyecto,
  setFacturacionTipo,
  addFacturacionTipo,
  updateFacturacionTipo,
  removeFacturacionTipo,
  removeFacturacion,
} = facturacionSlice.actions;

export default facturacionSlice.reducer;
