import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Button, MenuItem, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {
  createNewRepairPart,
  getAllBrandsSelect,
  getAllTeamTypesSelect,
  getAllStatusSelect,
  getAllRepairParts,
  getAllRepairPartMold,
} from "../../redux/slices/inventory";
import { useEffect } from "react";
import textFieldSelectFormat from "../../utils/textFieldSelectFormat";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "99%", // 0
    sm: "90%", // 600
    md: "60%", // 900
    lg: "60%", // 1200
    xl: "60%", // 1536
  },
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function RepairPartTransitionIncorporation({ repuestos, setRepuestos, id, setId }) {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const { brandsSelect, teamTypesSelect, statusSelect, repairPartsMold: repairParts, bodegas } = useSelector((state) => state.inventory);

  const dispatch = useDispatch();
  const {
    watch,
    handleSubmit,
    reset,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const handleClose = () => {
    setSelected(null);
    setOpen(false);
    reset({
      codigo_loginteg: "",
      descripcion: "",
      serie: "",
      marca: "",
      modelo: "",
      medidas: "",
      generico: "",
      tipo_equipo: "",
    });
  };

  const generic = watch("generico");
  // const selected = watch("repairPartSelected");

  useEffect(() => {
    if (selected) {
      const repairPartMold = repairParts.find((repairPart) => repairPart.id === parseInt(selected.id));
      if (repairPartMold) {
        reset({
          codigo_loginteg: repairPartMold.codigo_loginteg,
          descripcion: repairPartMold.descripcion,
          serie: repairPartMold.serie,
          marca: repairPartMold.marca,
          modelo: repairPartMold.modelo,
          medidas: repairPartMold?.medidas,
          generico: repairPartMold.generico ? "1" : "0",
          tipo_equipo: repairPartMold.tipo_equipo,
        });
      }
    }
  }, [selected]);

  const createRepairPart = async (values) => {
    const repairPartMold = repairParts.find((repairPart) => repairPart.id === parseInt(selected.id));
    values.equipoText = "S/D";
    values.estado = 6;
    if (generic === "0") {
      values.equipoText = repairPartMold?.inv_tipo_equipo?.descripcion;
    } else {
      delete values.tipo_equipo;
    }
    values.cantidad = parseInt(values.cantidad);
    values.marcaText = repairPartMold.inv_marca.descripcion;

    const existingIndex = repuestos.findIndex((item) => String(item.idMold) === String(selected.id));

    if (existingIndex !== -1) {
      const updatedRepuestos = [...repuestos];
      updatedRepuestos[existingIndex].cantidad += parseInt(values.cantidad);
      setRepuestos(updatedRepuestos);
    } else {
      values.id = id;
      values.idMold = selected.id;
      setRepuestos((prevState) => [...prevState, values]);
      setId((id += 1));
    }

    handleClose();
  };

  useEffect(() => {
    dispatch(getAllBrandsSelect());
    dispatch(getAllRepairPartMold());
    dispatch(getAllTeamTypesSelect());
    dispatch(getAllStatusSelect());
  }, []);

  return (
    <div>
      <Button onClick={() => setOpen(true)} variant="contained" sx={{ textTransform: "none" }}>
        Registrar nuevo repuesto
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit(createRepairPart)}>
              <div className="flex">
                <div className="w-full mr-5">
                  <div className="mb-5">
                    <Autocomplete
                      value={selected || { id: 0, label: "" }}
                      onChange={(option, value) => setSelected(value)}
                      options={textFieldSelectFormat(repairParts)}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Elegir Repuesto"
                          size="small"
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            },
                          }}
                          sx={{
                            "& .MuiFormHelperText-root": {
                              marginLeft: 0,
                            },
                          }}
                        />
                      )}
                    />

                    {/* <TextField
                      sx={{ mr: "20px" }}
                      fullWidth
                      size="small"
                      select
                      label="Elegir Repuesto"
                      onChange={(e) => setRepairPartSelected(e.target.value)}
                      value={repairPartSelected || ""}
                     
                      required
                    >
                      {repairParts?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.descripcion}
                        </MenuItem>
                      ))}
                    </TextField> */}
                  </div>
                  <div className={`flex mb-5 gap-4`}>
                    <Controller
                      id="codigo_loginteg"
                      name="codigo_loginteg"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          size="small"
                          fullWidth
                          id={"codigo_loginteg"}
                          label={"Codigo Loginteg"}
                          disabled
                          value={value || ""}
                          onChange={onChange}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#707070",
                            },
                          }}
                          type={"text"}
                          error={Boolean(errors["codigo_loginteg"])}
                          helperText={Boolean(errors["codigo_loginteg"]) && "El campo es requerido"}
                        />
                      )}
                    />
                    <Controller
                      id="descripcion"
                      name="descripcion"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          disabled
                          size="small"
                          value={value || ""}
                          onChange={onChange}
                          id={"nombre"}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#707070",
                            },
                          }}
                          label={"Nombre del Repuesto"}
                          type={"text"}
                          error={Boolean(errors["descripcion"])}
                          helperText={Boolean(errors["descripcion"]) && "El campo es requerido"}
                        />
                      )}
                    />
                  </div>
                  <div className="flex gap-4">
                    <Controller
                      id="serie"
                      name="serie"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          disabled
                          id={"serie"}
                          value={value || ""}
                          onChange={onChange}
                          label={"Código Proveedor"}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#707070",
                            },
                          }}
                          type={"text"}
                          error={Boolean(errors["serie"])}
                          helperText={Boolean(errors["serie"]) && "El campo es requerido"}
                        />
                      )}
                    />
                    <Controller
                      id="marca"
                      name="marca"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          disabled
                          id={"marca"}
                          value={value || ""}
                          onChange={onChange}
                          label={"Marca"}
                          type={"text"}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#707070",
                            },
                          }}
                          error={Boolean(errors["marca"])}
                          helperText={Boolean(errors["marca"]) ? "El campo es requerido" : " "}
                          select
                        >
                          {brandsSelect.length &&
                            brandsSelect
                              ?.filter((brand) => brand.repuesto)
                              .map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.descripcion}
                                </MenuItem>
                              ))}
                        </TextField>
                      )}
                    />
                  </div>
                  <div className={`flex  gap-4`}>
                    <Controller
                      id="modelo"
                      name="modelo"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          disabled
                          size="small"
                          id={"modelo"}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#707070",
                            },
                          }}
                          value={value || ""}
                          onChange={onChange}
                          label={"Modelo del repuesto"}
                          type={"text"}
                          helperText={Boolean(errors["modelo"]) ? "El campo es requerido" : " "}
                          error={Boolean(errors["modelo"])}
                        />
                      )}
                    />
                    <Controller
                      id="medidas"
                      name="medidas"
                      // rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          disabled
                          size="small"
                          id={"medidas"}
                          label={"Medidas"}
                          type={"text"}
                          value={value || ""}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#707070",
                            },
                          }}
                          onChange={onChange}
                          defaultValue={""}
                        />
                      )}
                    />
                  </div>
                  <div className={`flex ${generic === "0" ? "gap-4" : undefined}`}>
                    <Controller
                      id="generico"
                      name="generico"
                      rules={{ required: true }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          disabled
                          id={"generico"}
                          size="small"
                          label={"Es Generico"}
                          type={"text"}
                          helperText={Boolean(errors["generico"]) ? "El campo es requerido" : " "}
                          error={Boolean(errors["generico"])}
                          value={value || ""}
                          onChange={onChange}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#707070",
                            },
                          }}
                          select
                        >
                          <MenuItem key={"1"} value={"1"}>
                            {"SI"}
                          </MenuItem>
                          <MenuItem key={"2"} value={"0"}>
                            {"NO"}
                          </MenuItem>
                        </TextField>
                      )}
                    />
                    {generic === "0" && (
                      <Controller
                        id="tipo_equipo"
                        name="tipo_equipo"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            id={"tipo_equipo"}
                            label={"Compatibilidad"}
                            disabled
                            size="small"
                            type={"text"}
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#707070",
                              },
                            }}
                            value={value || ""}
                            onChange={onChange}
                            select
                          >
                            {teamTypesSelect.length &&
                              teamTypesSelect.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.descripcion}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      />
                    )}
                  </div>
                  <div className={`flex mb-5`}>
                    <Controller
                      id="cantidad"
                      name="cantidad"
                      rules={{ required: true, pattern: /^[0-9]*$/ }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          id={"cantidad"}
                          label={"Cantidad"}
                          sx={{ mr: 2 }}
                          type={"text"}
                          error={Boolean(errors["cantidad"])}
                          size="small"
                          value={value || ""}
                          onChange={onChange}
                          helperText={
                            (Boolean(errors?.cantidad?.type === "required") && "El campo es requerido") ||
                            (Boolean(errors?.cantidad?.type === "pattern") && "El campo solo admite numeros")
                          }
                        />
                      )}
                    />
                    <Controller
                      id="valueCost"
                      name="valueCost"
                      rules={{ required: true, pattern: /^[0-9]*$/ }}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          id={"valueCost"}
                          label={"Valor"}
                          type={"text"}
                          error={Boolean(errors["valueCost"])}
                          size="small"
                          value={value || ""}
                          onChange={onChange}
                          helperText={
                            (Boolean(errors?.valueCost?.type === "required") && "El campo es requerido") ||
                            (Boolean(errors?.valueCost?.type === "pattern") && "El campo solo admite numeros")
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center w-full pr-5  gap-5 mt-4 justify-between">
                <Button fullWidth type="submit" variant="contained">
                  Enviar formulario
                </Button>
                {/* <div className="w-80">
                  <TextField
                    label="Cantidad de repuestos"
                    {...register("cantidad_rep", { pattern: /^[0-9]*$/ })}
                    fullWidth
                    size="small"
                  />
                </div> */}
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
