import BreadCrumb from '../components/BreadCrumb/BreadCrumb';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import useDataGrid from '../hooks/useDataGrid';
import Teams from './Teams';
import ControlledDataGridComponent from '../components/tables/controlledDataGrid';
import { getAllProyectsBilling, removeProyectBilling } from '../redux/slices/facturacion';
import ProyectBilling from '../components/modal/ProyectBilling';
import { getAllPaises } from '../redux/slices/paises';
import { getAllRegions } from '../redux/slices/regions';
import { getExpenseClaimCategories, getFoundAccountProyects } from '../redux/slices/fundAccounting';
import FundProyectEdit from '../components/modal/FundProyectEdit';

const permitedRenderDataColumn = [
  { title: 'ID Rendicion', value: 'rendicion_id', hide: true },
  { title: 'Rendicion', value: 'title' },
  { title: 'N.º Dcto.', value: 'numero_documento' },
  { title: 'Monto', value: 'monto_parse' },
  { title: 'Tipo', value: 'type' },
  { title: 'Categoria', value: 'tipo_gasto' },
  { title: 'Acciones', value: 'rendicion_proyecto' },
];

export const RendicionProyecto = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const { anticipo, success } = useSelector((state) => state.fundAccounting);
  const { data } = useSelector((state) => state.regions);
  const dispatch = useDispatch();
  const [handleElementClick, setHandleElementClick] = useState({ status: false });

  const handleDelete = (params) => {
    const id = params.id;
    dispatch(removeProyectBilling(id));
  };

  const [columns] = useDataGrid(permitedRenderDataColumn, handleDelete, 'Facturacion Proyectos', false, setHandleElementClick);

  useEffect(() => {
    dispatch(getFoundAccountProyects());
    dispatch(getAllPaises());
    dispatch(getAllProyectsBilling());
    dispatch(getExpenseClaimCategories());
  }, [dispatch]);

  return (
    <div>
      <div className="flex justify-between my-2 items-center bg-[#2b2c84]/20 px-5 py-2 rounded">
        <div>
          <h1 className="font-semibold text-[#1E293B] text-2xl">Rendicion Proyectos</h1>
          <BreadCrumb origin={'Rendicion Gastos'} current={'Rendicion Proyectos'} />
        </div>
        <div>
          <FundProyectEdit type={'edit'} open={handleElementClick.status} data={handleElementClick.data} setOpen={setHandleElementClick} />
        </div>
      </div>
      <ControlledDataGridComponent
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        columns={columns}
        rows={anticipo}
        success={success}
      />
    </div>
  );
};

export default RendicionProyecto;
