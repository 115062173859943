import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AccordionOtActualSit from "../components/accordionOtDetail/AccordionOtActualSit";
import AccordionOtDetail from "../components/accordionOtDetail/AccordionOtDetail";
import AccordionOtObs from "../components/accordionOtDetail/AccordionOtObs";
import AccordionOtArticle from "../components/accordionOtDetail/AccordionOtArticle";
import AccordionOtRepairParts from "../components/accordionOtDetail/AccordionOtRepairParts";
import AccordionServices from "../components/accordionOtDetail/AccordionServices";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import AccordionOtProd from "../components/accordionOtDetail/AccordionOtProd";
import AccordionOtSit from "../components/accordionOtDetail/AccordionOtSit";
import AccordionAdicional from "../components/accordionOtDetail/AccordionAdicional";
import Signature from "../components/Signature/Signature";
import { Button } from "@mui/material";
import { getOTByID, updateSignature } from "../redux/slices/workOrders";
import { setClientSignature, setTechnicalSignature } from "../redux/slices/workOrders";
import FacebookCircularProgress from "../components/FacebookLoading/FacebookLoading";
import enviroments from "../utils/enviroments";
import { validateRUT } from "validar-rut";
import { useForm } from "react-hook-form";
import { getAllClients } from "../redux/slices/clients";
import CapacitacionAccordion from "../components/accordionOtDetail/capacitacionAccordion";
import dayjs from "dayjs";

const validarRut = (value, isRutValidationRequired) => {
  if (isRutValidationRequired) {
    return validateRUT(value);
  } else {
    return true;
  }
};

export default function DetailOT() {
  const dispatch = useDispatch();
  const { otId } = useParams();
  const [capacitacion, setCapacitacion] = useState(false);
  const [length, setLength] = useState(0);
  const [currentClient, setCurrentClient] = useState("");
  const data = useSelector((state) => state.auth.currentUser);
  const [isRutValidationRequired, setIsRutValidationRequired] = useState(true);
  const [rutError, setRutError] = useState(false);
  const { firma_cliente, firma_tecnico, ot: otCurrent, success } = useSelector((state) => state.workOrders);

  const [disabled, setDisabled] = useState({
    technical: false,
    client: false,
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm();

  const nameWatcher = watch("resp_cliente");
  const rutWatcher = watch("rut_cliente");

  const handleSignatureTechnical = async (e) => {
    e.preventDefault();
    setDisabled({ ...disabled, technical: true });
    await dispatch(
      updateSignature({
        id: otId,
        firma_tecnico: firma_tecnico.file,
        firma_cliente: "",
        img_tecnico: data.nombre,
        ot_current: otCurrent,
        current_user_mail: data.mail_corporativo,
        current_name: data.nombre,
        current_rut: data.rut,
      })
    );
  };
  const handleSignatureClient = async (e) => {
    const values = getValues();
    e.preventDefault();
    if (!validarRut(values.rut_cliente, true)) {
      setRutError(true);
      return;
    } else {
      setRutError(false);
    }
    setDisabled({ ...disabled, client: true });

    await dispatch(
      updateSignature({
        id: otId,
        firma_cliente: firma_cliente.file,
        firma_tecnico: "",
        img_cliente: otCurrent.resp_cliente,
        ot_current: otCurrent,
        current_user_mail: data.mail_corporativo,
        current_name: data.nombre,
        current_rut: data.rut,
        client_data: getValues(),
      })
    );
  };

  useEffect(() => {
    dispatch(getOTByID(otId));
    dispatch(getAllClients());
  }, [dispatch, otId]);

  useEffect(() => {
    if (otCurrent) {
      if (otCurrent.skilledPerson?.length) {
        setCapacitacion(true);
      }
      setValue("resp_cliente", otCurrent?.contacto?.nombre || "");
    }
  }, [otCurrent]);

  return otCurrent && otCurrent?.id && success ? (
    <div className="p-2 rounded border-container h-full overflow-y-auto">
      <div className="p-5 bg-slate-200 rounded font-medium flex justify-between">
        <div>ORDEN DE TRABAJO: {otId}</div>
        <div className="flex items-center">
          <Checkbox disabled={otCurrent.cerrada} checked={capacitacion} onChange={(e) => setCapacitacion(e.target.checked)} />
          <label>Capacitación</label>
        </div>
      </div>
      <div>
        {otCurrent?.motivo_apertura && (
          <div className="mx-2 my-2">
            <div className="text-neutral-900 font-bold">Motivo de reapertura de OT: </div>
            {otCurrent?.fecha_reapertura && <div className="text-sm text-neutral-500">{dayjs(otCurrent?.fecha_reapertura).format("DD/MM/YYYY")}</div>}
            <div className="text-neutral-500">{otCurrent?.motivo_apertura}</div>
          </div>
        )}
      </div>
      <div className="mt-3">
        <AccordionOtDetail props={otCurrent} otId={otId} setCurrentClient={setCurrentClient} />
      </div>
      {otCurrent.ot_equipo_ot_equipo_otToot.length ? (
        <div className="mt-3">
          <AccordionOtProd props={otCurrent} />
        </div>
      ) : null}
      {otCurrent.otFacturacionRepuesto.length || otCurrent.ot_repuesto_ot_repuesto_otToot.length ? (
        <div className="mt-3">
          <AccordionOtRepairParts otRepuesto={otCurrent.ot_repuesto_ot_repuesto_otToot} otFacturacionRepuesto={otCurrent.otFacturacionRepuesto} />
        </div>
      ) : null}
      {otCurrent.otFacturacionArticulo.length || otCurrent.ot_articulo.length ? (
        <div className="mt-3">
          <AccordionOtArticle otFacturacionArticulo={otCurrent.otFacturacionArticulo} otArticulo={otCurrent.ot_articulo} />
        </div>
      ) : null}
      {otCurrent.otFacturacionServicio.length || otCurrent.ot_servicios.length ? (
        <div className="mt-3">
          <AccordionServices servicios={otCurrent.otFacturacionServicio} otServicios={otCurrent.ot_servicios} />
        </div>
      ) : null}
      <div className="mt-3">
        <AccordionOtObs props={otCurrent} responsible={data.id} otId={otId} />
      </div>
      <div className="mt-3">
        <AccordionOtActualSit closed={otCurrent.cerrada} otId={otId} responsible={data.id} imgs={otCurrent.ot_imagen_ot_imagen_otToot} />
      </div>
      <div className="mt-3">
        <AccordionOtSit closed={otCurrent.cerrada} otId={otId} responsible={data.id} imgs={otCurrent.ot_imagen_ot_imagen_otToot} />
      </div>
      <div className="mt-3">
        <AccordionAdicional closed={otCurrent.cerrada} otId={otId} responsible={data.id} imgs={otCurrent.ot_imagen_ot_imagen_otToot} />
      </div>
      {capacitacion && (
        <div className="mt-3">
          <CapacitacionAccordion ot={otCurrent} setLength={setLength} />
        </div>
      )}
      <div className="mt-2 p-5 w-full flex flex-col sm:flex-row justify-around items-center">
        <div className="w-full sm:w-64 mb-10 md:mb-0">
          <TextField sx={{ mb: 1 }} value={data.nombre} label="Nombre Tecnico" fullWidth size="small" disabled />
          <TextField disabled sx={{ mb: 1 }} value={data.rut} label="Rut Tecnico" fullWidth size="small" />
          <div className="text center border-gray p-3 rounded">
            <div className="flex justify-between items-center bg-slate-200 py-1 px-3 rounded">
              <span className="w-full uppercase font-medium text-sm">Firma</span>
              {!otCurrent.cerrada && <Signature type={"tecnico"} disabled={disabled} stateCb={setTechnicalSignature} />}
            </div>
            <div className="p-2 max-w-52 mt-5 max-h-52 h-52 w-52 mb-5">
              {firma_tecnico?.base64 ? (
                <img className="max-w-40 max-h-40 object-contain" src={firma_tecnico.base64} alt="firma" />
              ) : (
                otCurrent.firma_tecnico && (
                  <div>
                    <img className="max-w-40 max-h-40 object-contain" src={`${enviroments.img_endpoint}${otCurrent?.firma_tecnico}`} alt="firma" />
                    <div></div>
                  </div>
                )
              )}
            </div>
            <div>
              {otCurrent.firma_tecnico?.length > 0 ? (
                <div className="flex justify-center">{otCurrent.img_tecnico.toUpperCase()}</div>
              ) : (
                <Button
                  id="technical"
                  disabled={disabled.technical || otCurrent.firma_tecnico?.length > 0 || (capacitacion && length <= 0)}
                  onClick={handleSignatureTechnical}
                  variant="contained"
                  fullWidth
                >
                  Firmar
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-64">
          <TextField
            sx={{ mb: 1 }}
            defaultValue={currentClient === "" ? otCurrent.img_cliente : currentClient}
            label="Nombre Cliente"
            {...register("resp_cliente")}
            fullWidth
            size="small"
            disabled={disabled.client || otCurrent.firma_cliente?.length > 0}
          />
          <TextField
            sx={{ mb: 1 }}
            defaultValue={currentClient === "" ? otCurrent.rut_cliente : currentClient}
            label="Rut Cliente"
            error={rutError}
            helperText={rutError && "El rut es invalido"}
            {...register("rut_cliente", {
              required: true,
              validate: {
                validarRut: (val) => {
                  return validarRut(val, isRutValidationRequired);
                },
              },
            })}
            fullWidth
            size="small"
            required
            disabled={disabled.client || otCurrent.firma_cliente?.length > 0}
          />
          <div className="text center border-gray p-3 rounded">
            <div className="flex justify-between items-center bg-slate-200 py-1 px-3 rounded">
              <span className=" w-full uppercase font-medium text-sm">Firma</span>
              {!otCurrent.cerrada && <Signature type={"cliente"} disabled={disabled} stateCb={setClientSignature} />}
            </div>
            <div className="p-2 max-w-52 mt-5 max-h-52 h-52 w-52 mb-5">
              {firma_cliente?.base64 ? (
                <img className="max-w-30 max-h-40 object-contain" src={firma_cliente.base64} alt="firma" />
              ) : (
                otCurrent.firma_cliente && (
                  <img className="max-w-40 max-h-40 object-contain" src={`${enviroments.img_endpoint}${otCurrent?.firma_cliente}`} alt="firma" />
                )
              )}
            </div>
            <div>
              {otCurrent.firma_cliente?.length > 0 ? (
                <div className="flex justify-center">{otCurrent.img_cliente.toUpperCase()}</div>
              ) : (
                <Button
                  id="client"
                  disabled={disabled.client || otCurrent.firma_cliente?.length > 0 || (capacitacion && length <= 0) || !rutWatcher || !nameWatcher}
                  onClick={handleSignatureClient}
                  variant="contained"
                  fullWidth
                >
                  Firmar
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (!otCurrent?.id && !success) || (otCurrent.id && !success) ? (
    <div className="w-full h-skeleton-datagrid flex justify-center items-center">
      <FacebookCircularProgress />
    </div>
  ) : (
    !otCurrent.id &&
    success && (
      <div className="w-full h-skeleton-datagrid flex justify-center items-center">
        <div className="uppercase tracking-wider text-sm text-red-400 bg-red-200 p-4 rounded font-bold">NO TIENES ASIGNADA ESTA OT</div>
      </div>
    )
  );
}
